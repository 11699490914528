// import * as React from "react";
// import { PieChart } from "@mui/x-charts/PieChart";
// import { useDrawingArea } from "@mui/x-charts/hooks";
// import { styled } from "@mui/material/styles";
// import { useEffect,useState } from "react";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import {  useNavigate } from "react-router-dom";

// const size = {
//   width: 400,
//   height: 200,
// };

// const StyledText = styled("text")(({ theme }) => ({
//   fill: theme.palette.text.primary,
//   textAnchor: "middle",
//   dominantBaseline: "central",
//   fontSize: 20,
// }));

// function PieCenterLabel({ children }) {
//   const { width, height, left, top } = useDrawingArea();
//   return (
//     <StyledText x={left + width / 4} y={top + height / 4}>
//       {children}
//     </StyledText>
//   );
// }

// export default function AdminPannelPieChart() {
//   const navigate = useNavigate();
// const [datas,setDatas]=useState([]);
// const [sellingProduct,setSellingProduct]=useState({})

//   // const data = [
//   //   { value: 5, label: "A" },
//   //   { value: 10, label: "B" },
//   //   { value: 15, label: "C" },
//   //   { value: 15, label: "C" },
//   //   { value: 15, label: "C" },
//   //   { value: 15, label: "C" },
//   //   { value: 15, label: "C" },
//   // ];

//   const data=datas?.map((val,index)=>{
//     return {"label":val.values,"value":val.key}
//   })

//   useEffect(() => {
//     try {
//       const adminId = localStorage.getItem("adminId");

//       if (!adminId) {
//         navigate("/login");
//         return;
//       }

//       const headers = {
//         Authorization: `Bearer ${adminId}`,
//         "Content-Type": "application/json",
//       };
//       const fetch = axios
//         .get(`${API_BASE_URL}//admin/product/Piechart`, { headers: headers })
//         .then((response) => {
//           console.log("response come in piechart api", response.data);
//           setDatas(response?.data?.categoryPercentages)
//         })
//         .catch((e) => console.log("error in api catch bloack", e));
//     } catch {
//       console.log("error in api fetchin ");
//     }
//   }, []);

//   return (
//     <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
//       <PieCenterLabel></PieCenterLabel>
//     </PieChart>
//   );
// }




// import * as React from "react";
// import { PieChart } from "@mui/x-charts/PieChart";
// import { useDrawingArea } from "@mui/x-charts/hooks";
// import { styled } from "@mui/material/styles";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import { useNavigate } from "react-router-dom";

// const size = {
//   width: 600,
//   height: 200,
// };

// const StyledText = styled("text")(({ theme }) => ({
//   fill: theme.palette.text.primary,
//   textAnchor: "middle",
//   dominantBaseline: "central",
//   fontSize: 20,
// }));

// function PieCenterLabel({ children }) {
//   const { width, height, left, top } = useDrawingArea();
//   return (
//     <StyledText x={left + width / 2} y={top + height / 2}>
//       {children}
//     </StyledText>
//   );
// }

// export default function AdminPannelPieChart() {
//   const navigate = useNavigate();
//   const [datas, setDatas] = useState([]);
//   const [sellingProduct, setSellingProduct] = useState({});

//   const data = datas?.map((val) => {
//     return { label: val.key, value: val.values };
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const adminId = localStorage.getItem("adminId");

//         if (!adminId) {
//           navigate("/login");
//           return;
//         }

//         const headers = {
//           Authorization: `Bearer ${adminId}`,
//           "Content-Type": "application/json",
//         };
//         const response = await axios.get(`${API_BASE_URL}/admin/product/Piechart`, { headers });
//         console.log("response come in piechart api", response.data);
//         setDatas(response?.data?.Percentages || []);
//       } catch (e) {
//         console.log("error in api fetchin ", e);
//       }
//     };

//     fetchData();
//   }, [navigate]);

//   if (datas.length === 0) {
//     return <div>Loading...</div>; // Or some other loading indicator
//   }

//   return (
//     <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
//       <PieCenterLabel>{sellingProduct.label}</PieCenterLabel>
//     </PieChart>
//   );
// }
























import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Chart.css"
const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function AdminPannelPieChart() {
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [sellingProduct, setSellingProduct] = useState({});

  const data = datas?.map((val) => {
    return { label: val.key, value: val.values };
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminId = localStorage.getItem("adminId");

        if (!adminId) {
          navigate("/login");
          return;
        }

        const headers = {
          Authorization: `Bearer ${adminId}`,
          "Content-Type": "application/json",
        };
        const response = await axios.get(`${API_BASE_URL}/admin/product/Piechart`, { headers:headers });
        console.log("response come in piechart api", response.data);
        setDatas(response?.data?.Percentages || []);
      } catch (e) {
        console.log("error in api fetchin ", e);
      }
    };

    fetchData();
  }, [navigate]);

  if (datas.length === 0) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  return (
    <div className="container">
      
      <h1 className="row" style={{textAlign:"center"}}> Product</h1>
      <div className="row justify-content-center">
      
        <div className="col-12 col-md-8 col-lg-6">
          <div className="p-3 border rounded">
            <PieChart
              series={[{ data, innerRadius: 80 }]}
              width={700} // Adjust width for mobile view
              height={300} // Adjust height as needed
              style={{ width: "100%", height: "auto" }} // Make the chart responsive
            >
              {/* <PieCenterLabel>{sellingProduct.label}</PieCenterLabel> */}
            </PieChart>
          </div>
        </div>
      </div>
    </div>
  );
}
