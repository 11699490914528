import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

function Counter({ endValue }) {
  const [count, setCount] = useState(0);

  const counterAnimation = useSpring({
    from: { count: 0 },
    to: { count: endValue },
    config: { duration: 2000 }, // Adjust the duration as needed
    onFrame: ({ count }) => setCount(Math.floor(count)),
  });

  return (
    <animated.span>
      {counterAnimation.count.to((value) => Math.floor(value))}
    </animated.span>
  );
}

export default Counter;