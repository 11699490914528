import axios from "axios";
import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../Token";

function Method({ method, setMethod, setSubmitMethod, submitMethod }) {
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/shippingmethod/admingetshippingmethod`, {
        headers,
      })
      .then((res) => {
        setMethod(res.data?.shippingMethod);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Method Name:
      </label>
      <br />

      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={submitMethod}
        required
        onChange={(e) => setSubmitMethod(e.target?.value)}
      >
        <option value="">Select a Zone</option>
        {Array.isArray(method) ? (
          method.map((method) => (
            <option key={method?._id} value={method?.Methodname}>
              {method?.Methodname}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}

export default Method;
