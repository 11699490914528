import React, { useEffect, useState } from "react";
import "../ProductCategory/productCategory.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DescriptionTypeSubCategorys from "../../../components/DescriptionType/DescriptionTypeSubCategorys";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DescriptionTypeCategorys from "../../../components/DescriptionType/DescriptionTypeCategorys";
import { useAuthHeaders } from "../../../components/Token";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function CreateDescriptionType() {
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [submitCategory, setSubmitCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [descriptionName, setDescriptionName] = useState("");
  const navigate = useNavigate();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
      .then((response) => {
        // Update the state with the fetched subcategory data
        setCategory(response.data?.categories);
        // console.log(response.data.categories, "getcategory")
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const handleCategoryCreation = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/description/admin/descriptionname`,
        {
          descriptionName: descriptionName,
          subcategoryname: selectedSubCategory,
          categoryname: submitCategory,
        },
        {
          headers,
        }
      )
      .then((result) => {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: "Description Name has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/descriptionType");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3" >
      <h2>Create Description Name</h2>
      <form onSubmit={handleCategoryCreation} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <DescriptionTypeCategorys
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            category={category}
            setSubCategory={setSubCategory}
            setSubmitCategory={setSubmitCategory}
            submitCategory={submitCategory}
          />

          <DescriptionTypeSubCategorys
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            subCategory={subCategory}
          />

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              description Name:
            </label>
            <br />
            <input
              type="text"
              id="descriptionName"
              onChange={(e) => setDescriptionName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="buttons">
          <button  type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/descriptionType" />
        </div>
      </form>
      {/*  */}
    </div>
  );
}

export default CreateDescriptionType;
