import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../components/MinorComponents/CancelButton";

function UpdateCoupen() {
  const { _id } = useParams();
  const headers = useAuthHeaders();
  const [singleData, setSingleData] = useState([]);
  const [coupenCode, setCoupenCode] = useState([]);
  const [coupenAmount, setCoupenAmount] = useState([]);
  const [coupenDate, setCoupenDate] = useState("");
  const navicate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getsinglecoupon/${_id}`, { headers })
      .then((response) => {
        console.log("getsinglecoupon", response.data.Coupon);
        setSingleData(response?.data?.Coupon);
        setCoupenCode(response?.data?.Coupon?.Couponcode);
        setCoupenAmount(response?.data?.Coupon?.Couponamount);
        // const formattedDate = expiresAt ? new Date(expiresAt).toISOString().split('T')[0] : '';
        // setCoupenDate(formattedDate);

        const expiresAt = response?.data?.Coupon?.expiresAt;

        if (expiresAt) {
          const formattedDate = new Date(expiresAt).toISOString().split("T")[0];
          setCoupenDate(formattedDate);
        } else {
          console.error("ExpiresAt is not defined in the response data.");
        }
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleUpdateCoupen = (e) => {
    e.preventDefault();

    axios
      .put(
        `${API_BASE_URL}/admin/updatecoupon/${_id}`,
        {
          couponcode: coupenCode,
          coupontype: singleData.Coupontype,
          couponamount: coupenAmount,
          expiresAt: coupenDate,
        },
        {
          headers,
        }
      )
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: "Edit Coupen has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navicate("/editCoupen");
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Coupen</h2>
      <form onSubmit={handleUpdateCoupen} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <h6>Coupontype</h6>
          <p>{singleData.Coupontype}</p>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Coupen Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={coupenCode}
              onChange={(e) => setCoupenCode(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Coupen Amount:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={coupenAmount}
              onChange={(e) => setCoupenAmount(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Coupen Date:
            </label>
            <br />
            <input
              type="date"
              id="name"
              value={coupenDate}
              onChange={(e) => setCoupenDate(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <button type="submit">Save</button>
          <CancelButton path="/editCoupen" />
        </div>
      </form>
    </div>
  );
}

export default UpdateCoupen;
