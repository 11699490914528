import React, { useState } from "react";
import "../ProductCategory/productCategory.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";

function ProductCategory() {
  const [name, setName] = useState();
  const navigate = useNavigate();
  const headers = useAuthHeaders();

  const handleCategoryCreation = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/category/admin/newcategory`,
        {
          name: name,
        },
        {
          headers,
        }
      )
      .then((result) => {
        console.log("create category", result?.data);
        Swal.fire({
          icon: "success",
          title: "Category Create has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/category");
      })
      .catch((error) => {
        console.log("error in category", error.response.data.error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/category");
  };

  return (
    <div className="p-3">
      <h2>Create Category</h2>
      <form
        action=""
        className="category"
        onSubmit={handleCategoryCreation}
        style={{
          boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset",
        }}
      >
        <div>
          <label htmlFor="name" className="fs-6">
            Create Category Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter the Category Name"
          />
        </div>
        <div className="buttons">
          <button
            type="submit"
            value="send"
            style={{ backgroundColor: "#0443C0", color: "white" }}
          >
            Save
          </button>
          <button
            onClick={handleCancel}
            className="cancel-btn"
            style={{ backgroundColor: "#F04B25", color: "white" }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProductCategory;
