import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils";
import { useAuthHeaders } from "../../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { WithContext as ReactTags } from "react-tag-input";
import CancelButton from "../../../components/MinorComponents/CancelButton";
import Form from "react-bootstrap/Form";

function UpdateZoneRegion() {
  const { _id } = useParams();
  const [zoneName, setZoneName] = useState("");
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [rate, setRate] = useState("");

  const navigate = useNavigate();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getsingleshipping/${_id}`, { headers })
      .then((response) => {
        setZoneName(response.data.Shipping.zone?.zonename);
        setWeight(response.data.Shipping?.weight?.value);
        setWeightUnit(response.data.Shipping?.weight?.unit);
        setRate(response.data.Shipping?.Rate);
        setTags(
          response.data.Shipping?.zoneArea.map((tag) => ({
            id: tag,
            text: tag,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const handleSave = (e) => {
    e.preventDefault();
    axios
      .put(
        `${API_BASE_URL}/admin/updateshipping/${_id}`,
        {
          zonename: zoneName,
          zoneArea: textValues,
          weight: {
            value: weight,
            unit: weightUnit,
          },
          Rate: rate,
        },
        { headers }
      )
      .then((response) => {
        console.log("response :", response);
        Swal.fire({
          icon: "success",
          title: "Zone Region Updated Success",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/editNewZoneRegion");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Shipping</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="category-field">
          <div className="d-flex">
            <label className="fs-6 fw-bold">Zone Name:</label>
            <br />
            <p className="mx-2">{zoneName}</p>
          </div>

          <div className="row">
            <div className="col-md-4">
              <label htmlFor="name" className="fs-6 py-2">
                Create Region:
              </label>
              <br />
              <div className="border border-dark p-2 rounded-1">
                <ReactTags
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  autocomplete
                />
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="name" className="fs-6">
                Weight :
              </label>
              <br />
              <div className="d-flex align-items-center">
                <input
                  type="number"
                  required
                  min={0}
                  className="w-100 weight-input"
                  value={weight}
                  step="0.00001"
                  placeholder="Zone Weight"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) => setWeight(e.target.value)}
                />
                <Form.Select
                  aria-label="Default select example"
                  className="weight-unit"
                  value={weightUnit}
                  onChange={(e) => setWeightUnit(e.target.value)}
                >
                  <option value="Kg">Kg</option>
                  <option value="Gram">Gram</option>
                </Form.Select>
              </div>
            </div>

            <div className="col-md-4">
              <label htmlFor="name" className="fs-6">
                Rate :
              </label>
              <br />

              <input
                type="number"
                required
                min={0}
                value={rate}
                step="0.00001"
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => setRate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="buttons" style={{ marginTop: "20px" }}>
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/editNewZoneRegion" />
          {/* <button>Cancel</button> */}
        </div>
      </form>
    </div>
  );
}

export default UpdateZoneRegion;