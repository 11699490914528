import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/index";
import { useAuthHeaders } from "../../../../components/Token";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import CancelButton from "../../../../components/MinorComponents/CancelButton";

function EditCategory() {
  const { _id } = useParams();
  const [newCategoryName, setNewCategoryName] = useState("");
  const navigate = useNavigate();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getsinglecategory/${_id}`)
      .then((response) => {
        setNewCategoryName(response.data.category.name);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const data = { name: newCategoryName };
    
    axios
      .put(`${API_BASE_URL}/category/admin/updatecategory/${_id}`, data, {
        headers,
      })
      .then((response) => {
        console.log("response :", response);
        Swal.fire({
          icon: "success",
          title: "Edit Category has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/category");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Category</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div>
          <label htmlFor="name" className="fs-6">
            Change Category Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>

          <CancelButton path="/category" />
        </div>
      </form>
    </div>
  );
}

export default EditCategory;
