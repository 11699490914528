import React from "react";
import { useNavigate } from "react-router-dom";

function CancelButton(path) {

  const navigate = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();

    navigate(path.path);
  };

  return <button onClick={handleCancel}  style={{ backgroundColor: "#F04B25", color: "white" }}>Cancel</button>;
}

export default CancelButton;