import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import DescriptionMethodConditions from "../../../components/DescriptionMethodConditions/DescriptionMethodConditions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCircleRemove } from "react-icons/ci";
import NoteEditor from "../../../components/noteEditor/NoteEditor ";

function NewProductsStaticField({
  setPartNumber,
  onImagesChange,
  ProductsImagesPreview,
  setManuFacturer,
  setWeightPerPiece,
  weightPerPiece,
  setProductWeight,
  productWeight,
  boxOfQuantity,
  setBoxOfQuantity,
  writeNotes,
  setWriteNotes,
  setQuantity,
  setOriginalPrice,
  originalPrice,
  setProducts,
  salePrice,
  setSalelPrice,
  setmetaTitle,
  setDealofDay,
  handleCategorySelect,
  selectedCategory,
  categories,

  handleSubCategorySelect,
  selectedSubCategory,
  subCategory,

  discriptionType,
  handleChange,
  setManualInput,
  manualInput,

  dealofDay,
  selectedDate,
  setSelectedDate,
  setCad,
  removeImage,
  setOldAmount,
}) {
  const onCadChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setCad((oldArray) => [...oldArray, file]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (isNaN(boxOfQuantity) || isNaN(salePrice) || isNaN(weightPerPiece)) {
      return;
    }

    const calculateProductWeight = boxOfQuantity * salePrice;
    setOriginalPrice(calculateProductWeight);

    const calculateWeightPerPiece = boxOfQuantity * weightPerPiece;
    const calculateInKg = calculateWeightPerPiece / 1000;

    console.log("calculateInKg", calculateInKg);

    let formattedWeightPerPiece;

    if (calculateInKg >= 1) {
      formattedWeightPerPiece = `${calculateInKg.toFixed(2)} kg`;
    } else {
      const calculateInGrams = calculateWeightPerPiece;
      formattedWeightPerPiece = `${calculateInGrams} g`;
    }

    setProductWeight(formattedWeightPerPiece);
  }, [salePrice, weightPerPiece, boxOfQuantity]);

  const handleDateChange = (e) => {
    setSelectedDate(e);
    // console.log("e.target.value", e);
  };

  return (
    <div className="row">
      <div className="p-2 col-md-4">
        <label htmlFor="name" className="fs-6">
          Part.No / Model.No :
        </label>
        <br />
        <input
          type="text"
          id="name"
          placeholder="Part Number"
          className="w-100"
          required
          onChange={(e) => setPartNumber(e.target.value)}
        />
      </div>

      <div className="p-2 col-md-4">
        <label htmlFor="productimages" className="fs-6">
          Upload Product Images :
        </label>
        <br />
        <input
          type="file"
          name="productimages"
          className="custom-file-input w-100"
          id="productimages"
          multiple
          required
          onChange={onImagesChange}
        />

        {ProductsImagesPreview.map((productimages, index) => (
          <div key={index} className="mt-3 mr-2">
            <img
              src={productimages}
              alt={`Image Preview`}
              width="55"
              height="50"
            />
            <span className="fs-6" onClick={() => removeImage(index)}>
              <CiCircleRemove />
            </span>
          </div>
        ))}
      </div>

      <div className="col-md-4 p-2">
        <label htmlFor="category" className="fs-6">
          Category Name:
        </label>
        <br />
        <Form.Select
          id="category"
          aria-label="Default select example"
          className="my-2"
          required
          value={selectedCategory}
          onChange={handleCategorySelect}
        >
          <option value="">Select a category</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </Form.Select>
      </div>

      <div className="col-md-4 p-2">
        <label htmlFor="subCategory" className="fs-6">
          Sub Category Name:
        </label>
        <br />
        <Form.Select
          id="subCategory"
          aria-label="Default select example"
          className="my-2"
          value={selectedSubCategory}
          required
          onChange={handleSubCategorySelect}
        >
          <option value="">Select a Sub Category</option>
          {Array.isArray(subCategory) ? (
            subCategory.map((category) => (
              // console.log("sebcategory test :", category)
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))
          ) : (
            <option value="">Loading subcategories...</option>
          )}
        </Form.Select>
      </div>

      {/* dynamic fields */}
      <DescriptionMethodConditions
        dataList={discriptionType}
        handleChange={handleChange}
        setManualInput={setManualInput}
        manualInput={manualInput}
      />
      {/* dynamic fields */}

      {/* Manu Facturer */}
      <div className="p-2 col-md-4">
        <label htmlFor="ManuFacturer" className="fs-6">
          Manufactur :
        </label>
        <br />
        <input
          type="text"
          id="ManuFacturer"
          className="w-100"
          onChange={(e) => setManuFacturer(e.target.value)}
        />
      </div>

      {/* Old Amount */}

      <div className="p-2 col-md-4">
        <label htmlFor="BOQ" className="fs-6">
          Old Price :
        </label>
        <br />
        <input
          type="number"
          id="BOQ"
          placeholder="0"
          className="w-100"
          step="0.00001"
          required
          onChange={(e) => setOldAmount(e.target.value)}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      {/* SalelPrice */}
      <div className="p-2 col-md-4">
        <label htmlFor="SalelPrice" className="fs-6">
          Price For Piece :
        </label>
        <br />
        <input
          type="number"
          placeholder="0"
          id="SalelPrice"
          className="w-100"
          step="0.00001"
          required
          onChange={(e) => setSalelPrice(e.target.value)}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      {/* Weight Per Piece */}
      <div className="p-2 col-md-4">
        <label htmlFor="name" className="fs-6">
          Weight Per Piece (in Grams) :
        </label>
        <br />
        <input
          type="number"
          placeholder="0"
          id="name"
          className="w-100"
          step="0.00001"
          onChange={(e) => setWeightPerPiece(e.target.value)}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      {/* BOQ */}
      <div className="p-2 col-md-4">
        <label htmlFor="BOQ" className="fs-6">
          Box Of Quantity :
        </label>
        <br />
        <input
          type="number"
          id="BOQ"
          placeholder="0"
          className="w-100"
          step="0.00001"
          onChange={(e) => setBoxOfQuantity(e.target.value)}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      {/* OriginalPrice */}
      <div className="p-2 col-md-4">
        <label htmlFor="OriginalPrice" className="fs-6">
          Product Price :
        </label>
        <br />
        <p>₹ {originalPrice || 0} </p>
      </div>

      {/* Product Weight */}
      <div className="p-2 col-md-4">
        <label htmlFor="ProductWeight" className="fs-6">
          Product Weight :
        </label>
        <br />

        <p> {productWeight || 0} </p>
      </div>

      {/* cad */}
      {/* <div className="p-2 col-md-4">
        <label htmlFor="cad" className="fs-6">
          CAD :
        </label>
        <br />
        <input
          type="file"
          name="cad"
          className="custom-file-input w-100"
          id="cad"
          multiple
          onChange={onCadChange}
        />
      </div> */}

      <div className="p-2 col-md-4">
        <label htmlFor="Products" className="fs-6">
          Products :
        </label>
        <br />
        <input
          type="text"
          id="Products"
          className="w-100"
          onChange={(e) => setProducts(e.target.value)}
        />
      </div>

      <div className="p-2 col-md-4">
        <label htmlFor="Quantity" className="fs-6">
          Quantity :
        </label>
        <br />
        <input
          type="number"
          placeholder="0"
          required
          id="Quantity"
          className="w-100"
          step="0.00001"
          onChange={(e) => setQuantity(e.target.value)}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>

      <div className="p-2 col-md-4">
        <label htmlFor="MetaTitle" className="fs-6">
          Meta Title :
        </label>
        <br />
        <input
          type="text"
          id="MetaTitle"
          placeholder="Meta Title"
          className="w-100"
          step="0.00001"
          onChange={(e) => setmetaTitle(e.target.value)}
          onWheel={(event) => event.currentTarget.blur()}
        />
      </div>

      <div className="p-2 col-md-4">
        <label className="fs-6">DEAL OF THE DAY :</label>
        <br />
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            id="delofday"
            onChange={(e) => setDealofDay(e.target.checked)}
            style={{ width: "10%" }}
          />

          <label className="form-check-label" htmlFor="delofday">
            DEAL OF THE DAY
          </label>
        </div>
      </div>

      {dealofDay === true && (
        <div className="col-md-4">
          <label style={{ marginRight: "10px" }}>Till Date : </label>

          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            className="w-100"
            placeholderText="Select Date"
            minDate={new Date()}
          />
        </div>
      )}

      <div className="p-2 col-md-12">
        <label className="fs-6">Write Notes :</label>
        <br />
        <div className="form-group">
          <NoteEditor setWriteNotes={setWriteNotes} writeNotes={writeNotes} />
        </div>
      </div>
    </div>
  );
}

export default NewProductsStaticField;
