import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../utils/index";
// import Lottie from "lottie-react";

// import Preloader from "../../Preloader/Preloader-Animation.json";


export default function DescriptionNameTypeCategorys({
  setSelectedCategory,
  selectedCategory,
  category,
  setSubCategory,
  setSubmitCategory
}) {

  const [loading, setLoading] = useState(true);

  const handleCategorySelect = (e) => {
    const selectedCategory = e.target?.value;
    setSelectedCategory(selectedCategory);
    const categoryNameFind = category.find((categoryId) => categoryId?._id === selectedCategory)
    setSubmitCategory(categoryNameFind?.name)

    setLoading(false)
    axios
      .get(`${API_BASE_URL}/category/admin/${selectedCategory}`)
      .then((response) => {
        setSubCategory(response?.data?.subcategoryNames);
        // console.log("setSubCategory", response.data.subcategoryNames)
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Category Name:
      </label>
      <br />
      {/* {loading ? (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={Preloader}
            />
          ) : ( */}
      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedCategory}
        onChange={handleCategorySelect}
        required
      >
        <option value="">Select a Category </option>
        {Array.isArray(category) ? (
          category?.map((category) => (
            <option key={category?._id} value={category?._id}>
              {category?.name}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
          {/* )} */}
    </div>
  );
}
