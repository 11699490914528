import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Form from "react-bootstrap/Form";
import Zone from "../../../components/ShippingComponents/Zone";
import Region from "../../../components/ShippingComponents/Region";
import { WithContext as ReactTags } from "react-tag-input";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function ZoneRegion() {
  const [zone, setZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [submitZone, setSubmitZone] = useState([]);
  const [region, setRegion] = useState([]);
  const [submitRegion, setSubmitRegion] = useState([]);
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);
  const [weight, setWeight] = useState("");
  const [rate, setRate] = useState("");
  const [weightUnit, setWeightUnit] = useState("Kg");

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const headers = useAuthHeaders();
  const navicate = useNavigate();

  const handleCreateShipping = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/admin/shipping/createshipping`,
        {
          zonename: selectedZone,
          zoneArea: textValues,
          weight: {
            value: weight,
            unit: weightUnit,
          },
          Rate: rate,
        },
        { headers }
      )
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: "Shipping has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navicate("/editNewZoneRegion");
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallzones`, { headers })
      .then((res) => {
        // console.log("res", res);
        setZone(res.data.Zones);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Shipping</h2>
      <form
        onSubmit={handleCreateShipping}
        className="category position-relative"
        style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}
      >
        <div className="row category-field">
          <Zone
            zone={zone}
            setSelectedZone={setSelectedZone}
            selectedZone={selectedZone}
            setSubmitZone={setSubmitZone}
            // setRegion={setRegion}
          />

          {/* <Region
            region={region}
            setSubmitRegion={setSubmitRegion}
            submitRegion={submitRegion}
          /> */}

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Create Region:
            </label>
            <br />
            <div className="border border-dark p-2 rounded-1">
              <ReactTags
                tags={tags}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inputFieldPosition="bottom"
                autocomplete
              />
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Weight :
            </label>
            <br />
            <div className="d-flex align-items-center">
              <input
                type="number"
                required
                min={0}
                className="w-100 weight-input"
                step="0.00001"
                onWheel={(event) => event.currentTarget.blur()}
                placeholder="Zone Weight"
                onChange={(e) => setWeight(e.target.value)}
              />
              <Form.Select
                aria-label="Default select example"
                className="weight-unit"
                onChange={(e) => setWeightUnit(e.target.value)}
              >
                <option value="Kg">Kg</option>
                <option value="Gram">Gram</option>
              </Form.Select>
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Rate :
            </label>
            <br />

            <input
              type="number"
              required
              min={0}
              step="0.00001"
              onWheel={(event) => event.currentTarget.blur()}
              onChange={(e) => setRate(e.target.value)}
            />
          </div>
        </div>
        <div className="buttons" style={{ marginTop: "30px" }}>
          <button style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          {/* <button>Cancel</button> */}
          <CancelButton path="/editNewZoneRegion" />
        </div>
      </form>
    </div>
  );
}

export default ZoneRegion;
