import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";

function Region({ region, setSubmitRegion, submitRegion }) {
  return (
    <div className="col-md-4">
    <label htmlFor="name" className="fs-6">
      Region Name:
    </label>
    <br />

    <Form.Select
      aria-label="Default select example"
      className="my-2"
      value={submitRegion}
      required
      onChange={(e) => setSubmitRegion(e.target.value)}
    >
      <option value="">Select a Region</option>
      {Array.isArray(region) ? (
        region.map((item) => (
          <option key={item?._id} value={item?._id}>
            {item?.name}
          </option>
        ))
      ) : (
        <option value="">Loading...</option>
      )}
    </Form.Select>
  </div>
  );
}

export default Region;
