import React, { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../../components/MinorComponents/CancelButton";

function FiltersTypes() {
  const [filter, setFilter] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);
  const [categories, setCategories] = useState([]);
  const [submitCategories, setSubmitCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [submitCategory, setSubmitCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const headers = useAuthHeaders();

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
      .then((response) => {
        setCategories(response.data?.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleFilterSelect = (e) => {
    const selectedSubCategories = e.target?.value;

    const findCateoryName = subCategories.find(
      (catName) => catName?._id === selectedSubCategories
    );

    setSelectedSubCategory(findCateoryName.name);
    console.log("selectedSubCategory state:", selectedSubCategory);

    axios
      .get(
        `${API_BASE_URL}/admin/getfilterbysubcategory/${selectedSubCategories}`
      )
      .then((response) => {
        setFilter(response.data?.Filter);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/admin/newfiltertype`,
        {
          category: submitCategory,
          subcategory: selectedSubCategory,
          filter: selectedFilter,
          filter_Types: textValues,
        },
        {
          headers,
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Filter Type work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/editFiltersTypes");
        // Clear the form and reset the state
        console.log("response", response);
        setTags("");
        setSelectedFilter("");
      })
      .catch((error) => {
        console.log("error in newfiltertype", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  const handleSelectSubCategory = (e) => {
    const categoryID = e.target.value;

    const findSubCategory = categories.find((item) => item._id === categoryID);

    setSelectedCategory(categoryID);
    setSubmitCategory(findSubCategory?.name);

    axios
      .get(`${API_BASE_URL}/category/admin/${categoryID}`)
      .then((response) => {
        setSubCategories(response?.data?.subcategoryNames);
      })
      .catch((error) => {
        console.log("select sub category", error);
      });
  };

  return (
    <div className="p-4">
      <h2>Filters Types</h2>
      <form onSubmit={handleFormSubmit} className="category"  style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <div className="col-lg-4">
            <label htmlFor="name" className="fs-6">
              Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategory}
              onChange={handleSelectSubCategory}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category?._id} value={category?._id}>
                  {category?.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-lg-4">
            <label htmlFor="name" className="fs-6">
              Sub Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              defaultValue={selectedSubCategory}
              onChange={handleFilterSelect}
              required
            >
              <option value="">Select a Sub Category</option>
              {subCategories.map((subCategories) => (
                <option key={subCategories?._id} value={subCategories?._id}>
                  {subCategories?.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-lg-4">
            <label htmlFor="name" className="fs-6">
              Select Filter:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <option value="">Select a Filter</option>
              {filter.map((filter) => (
                // console.log("filter", filter)
                <option key={filter?._id} value={filter?.name}>
                  {filter?.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-lg-4">
            <label htmlFor="name" className="fs-6">
              Filter Types:
            </label>
            <br />
            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
            />
          </div>
        </div>
        <div className="buttons">
          <button type="submit"  style={{ backgroundColor: "#0443C0", color: "white" }} >Save</button>
          <CancelButton path="/editFiltersTypes" />
        </div>
      </form>
    </div>
  );
}

export default FiltersTypes;
