import React, { useContext, useEffect } from "react";
import Header from "./components/Headers/Header";
import Footer from "./components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import userContext from "./components/UseContext/UseContext";

function Layout() {
  const adminId = localStorage.getItem("adminId");
  const navicate = useNavigate();
  const logOutContext = useContext(userContext);

  useEffect(() => {
    if (!adminId) {
      navicate("/login");
    }
  }, [logOutContext.logOut]);

  return (
    <>
      <Header />
      {/* <Footer /> */}
    </>
  );
}

export default Layout;
