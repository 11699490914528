// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../../components/Token";
// import Table from 'react-bootstrap/Table';
// import "../orderProducts/orderProducts.scss"

// function OrderProducts() {
//   const [orderData, setOrderData] = useState([]);
//   // const [totalData, setTotalData] = useState([]);
//   const headers = useAuthHeaders();

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/admin/order/getOrders`, {
//         headers,
//       })
//       .then((res) => {
//         setOrderData(res?.data?.orders);
//         // setTotalData(res.data)
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   }, []);

//   return (
//     <div className="p-4">
//       <Table className="text-center order-table">
//         <thead className="table-header" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
//           <tr>
//             <th>S.R</th>
//             <th>Order Status</th>
//             <th>Order Total</th>
//           </tr>
//         </thead>

//         <tbody>
//           {orderData.map((orders, index) => {
//             console.log("orders", orders)
//             return (
//               <tr>
//                 <td>{index + 1}</td>
//                 {/* <td>{totalData.shippedCount}</td> */}
//                 <td>{orders?.orderStatus}</td>
//                 <td>{orders?.orderTotal}</td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </Table>
//     </div>
//   );
// }

// export default OrderProducts;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaCircle, FaSearch } from "react-icons/fa";
// import OrderUpdate from "./OrderUpdate";
// import { Button } from "react-bootstrap";
// import './orderProducts.scss'; // Import custom CSS
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../../components/Token";

// const ITEMS_PER_PAGE = 5;

// const OrderProducts = () => {
//   const [orders, setOrders] = useState([]);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [selectedOrderId, setSelectedOrderId] = useState(null);
//   const [showUpdateModal, setShowUpdateModal] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [search, setSearch] = useState(""); // New state for search input
//   const [totalOrders, setTotalOrders] = useState(0);

//   const headers = useAuthHeaders();

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/admin/order/getOrders`, { headers });
//         const ordersData = response?.data?.orders || [];
//         setOrders(ordersData);
//         setFilteredOrders(ordersData); // Set filtered orders initially
//         setTotalOrders(ordersData.length);
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//       }
//     };

//     fetchOrders();
//   }, [headers]);

//   useEffect(() => {
//     // Filter orders based on search input
//     const results = orders.filter(order => {
//       const searchLower = search.toLowerCase();
//       return (
//         (order?.shippingInfo?.firstname?.toLowerCase().includes(searchLower) ||
//         order?.shippingInfo?.lastname?.toLowerCase().includes(searchLower) ||
//         order?.orderItems[0]?.partNumber?.toLowerCase().includes(searchLower) ||
//         (order?.orderItems[0]?.quantity?.toString().includes(searchLower)) ||
//         (order?.orderTotal?.toString().includes(searchLower)) ||
//         (order?.orderStatus?.toLowerCase().includes(searchLower)))
//         || (order?.orderItems[0]?.color?.toLowerCase().includes(searchLower)) // Example for color
//       );
//     });
//     setFilteredOrders(results);
//     setCurrentPage(1); // Reset to first page when search changes
//   }, [search, orders]);

//   const getStatusStyle = (status) => {
//     switch (status.toLowerCase()) {
//       case "pending":
//         return { color: "#F3A638" };
//       case "success":
//         return { color: "#4CB64C" };
//       case "processing":
//         return { color: "#54B7D3" };
//       case "shipped":
//         return { color: "#1E91CF" };
//       case "delivered":
//         return { color: "#4CB64C" };
//       case "cancel":
//         return { color: "#E3503E" };
//       default:
//         return {};
//     }
//   };

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
//   };

//   const handleUpdateClick = (orderId) => {
//     setSelectedOrderId(orderId);
//     setShowUpdateModal(true);
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
//       setCurrentPage(newPage);
//     }
//   };

//   const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
//   const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
//   const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
//   const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

//   return (
//     <div className="container-fluid">
//       <div className="row">
//         <div className="col">
//           <div className="d-flex  mt-4 mb-4">

//           <h5 className="text-start mt-4">Order History</h5>
//           <div className="ms-auto d-flex h-25 rounded-2" style={{boxShadow:" rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>

//           <input
//               type="text"
//               className="form-control w-100 text-start border border-0"
//               placeholder="Search..."
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//               /><FaSearch className="fs-3 mt-2 m-2"  />
//           </div>
//               </div>
//           <div className="table-responsive">
//             <table className="table table-striped">
//               <thead style={{border:"1px solid"}}>
//                 <tr className="border border-1">
//                   <th scope="col" className="border border-1">S.No</th>
//                   <th scope="col"className="border border-1">Part No</th>
//                   <th scope="col"className="border border-1">Name</th>
//                   <th scope="col"className="border border-1">Email</th>
//                   <th scope="col"className="border border-1">Shipping Address</th>
//                   <th scope="col"className="border border-1">Quantity</th>
//                   <th scope="col"className="border border-1">Amount</th>
//                   <th scope="col"className="border border-1">Date</th>
//                   <th scope="col"className="border border-1">Status</th>
//                   <th scope="col"className="border border-1">Action</th>
//                 </tr>
//               </thead>
//               <tbody >
//                 {currentOrders.length > 0 ? (
//                   currentOrders.map((order, index) => (
//                     <tr key={order?._id} >
//                       <td>{indexOfFirstOrder + index + 1}</td>
//                       <td
//                         style={{ cursor: "pointer", color: "#007BFF",borderBottom:"1px solid black"}}
//                       >
//                         <Link to={`/newProductsDetials/${order?.orderItems[0]?.productId}`}>
//                           {order.orderItems[0]?.partNumber}
//                         </Link>
//                       </td>
//                       <td>{order?.shippingInfo?.firstname} {order?.shippingInfo?.lastname}</td>
//                       <td>{order?.shippingInfo?.email}</td>
//                       <td>{order?.alternateAddress?.alternateStreetAddress || order?.shippingInfo?.streetaddress}</td>
//                       <td>{order?.orderItems[0]?.quantity}</td>
//                       <td>{order?.orderTotal}</td>
//                       <td>{formatDate(order?.orderItems[0]?.createdAt)}</td>
//                       <td>
//                         <FaCircle style={getStatusStyle(order?.orderStatus)} /> {order?.orderStatus}
//                       </td>
//                       <td>
//                         <Button
//                           className="btn btn-primary btn-sm"
//                           onClick={() => handleUpdateClick(order?._id)}
//                         >
//                           Update
//                         </Button>
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="10" className="text-center text-danger">No orders found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//           <div className="d-flex justify-content-center mt-4">
//             <nav>
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button onClick={() => handlePageChange(currentPage - 1)} className="page-link">Previous</button>
//                 </li>
//                 {[...Array(totalPages).keys()].map((number) => (
//                   <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
//                     <button onClick={() => handlePageChange(number + 1)} className="page-link">
//                       {number + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                   <button onClick={() => handlePageChange(currentPage + 1)} className="page-link">Next</button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//           {showUpdateModal && (
//             <OrderUpdate
//               _id={selectedOrderId}
//               onClose={() => setShowUpdateModal(false)}
//             />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OrderProducts

//updated on 07/24/2024

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaCircle, FaSearch } from "react-icons/fa";
// import { Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
// import { useAuthHeaders } from "../../../components/Token";
// import { API_BASE_URL } from "../../../utils";
// import './orderProducts.scss';
// import order from "../../../Assets/Images/order list.png"
// const ITEMS_PER_PAGE = 5;

// const OrderProducts = ({ }) => {
//   const [orders, setOrders] = useState([]);
//   const [filteredOrders, setFilteredOrders] = useState([]);
//   const [selectedOrderId, setSelectedOrderId] = useState(null);
//   const [showUpdateModal, setShowUpdateModal] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [search, setSearch] = useState("");
//   const [totalOrders, setTotalOrders] = useState(0);
//   const [status, setStatus] = useState('');
//   const [reason, setReason] = useState({
//     'Payment Pending': 'Awaiting payment confirmation',
//     'Payment success': 'Payment confirmed',
//     processing: 'Awaiting processing confirmation',
//     cancel: '',
//   });
//   const [currDate, setCurrDate] = useState(() => new Date().toISOString().split('T')[0]);
//   const [intimationMessage, setIntimationMessage] = useState('');
//   const [dueDate, setDueDate] = useState(() => new Date().toISOString().split('T')[0]);
//   const [loading, setLoading] = useState(false);
//   const header = useAuthHeaders();

//   useEffect(() => {
//     fetchOrders();
//   }, [header]);

//   const fetchOrders = async () => {
//     setLoading(true);
//     try {
//       const res = await axios.get(`${API_BASE_URL}/admin/order/getOrders`, { headers: header });
//       setOrders(res.data.orders);
//       setFilteredOrders(res.data.orders);
//       setTotalOrders(res.data.orders.length);
//     } catch (err) {
//       console.error('Error fetching orders:', err);
//       setIntimationMessage('Failed to fetch order details');
//     } finally {
//       setLoading(false);
//     }
//   };
//   const onClose = () => {
//     setShowUpdateModal(false);
//   };
//   useEffect(() => {
//     const results = orders.filter(order => {
//       const searchLower = search.toLowerCase();
//       return (
//         (order?.shippingInfo?.firstname?.toLowerCase().includes(searchLower) ||
//         order?.shippingInfo?.lastname?.toLowerCase().includes(searchLower) ||
//         order?.orderItems[0]?.partNumber?.toLowerCase().includes(searchLower) ||
//         (order?.orderItems[0]?.quantity?.toString().includes(searchLower)) ||
//         (order?.orderTotal?.toString().includes(searchLower)) ||
//         (order?.orderStatus?.toLowerCase().includes(searchLower)))
//         || (order?.orderItems[0]?.color?.toLowerCase().includes(searchLower))
//       );
//     });
//     setFilteredOrders(results);
//     setCurrentPage(1);
//   }, [search, orders]);

//   const handleSubmit = async () => {
//     if (!status) {
//       setIntimationMessage('Please select an order status');
//       return;
//     }

//     if (status === 'Shipped' && new Date(dueDate) < new Date(currDate)) {
//       setIntimationMessage('Delivery date must be after the shipping date');
//       return;
//     }

//     const payload = {
//       orderStatus: status,
//       shippingDate: status === 'Shipped' ? currDate : undefined,
//       deliveryDate: status === 'Shipped' || status === 'Delivered' ? currDate : undefined,
//       deliveredAt: status === 'Delivered' ? dueDate : undefined,
//       reason: reason[status] || reason['cancel'],
//     };

//     Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);

//     if (!selectedOrderId) {
//       setIntimationMessage('No order selected');
//       return;
//     }

//     setLoading(true);
//     try {
//       await axios.put(`${API_BASE_URL}/admin/order/${selectedOrderId}`, payload, { headers: header });
//       setIntimationMessage('Order status updated successfully');
//       fetchOrders(); // Refetch orders after updating
//       setShowUpdateModal(false); // Close modal on success
//     } catch (err) {
//       console.error('Error updating order:', err.response?.data || err.message);
//       setIntimationMessage('Failed to update order');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDateChange = (e) => {
//     const { value } = e.target;
//     if (new Date(value) < new Date(currDate)) {
//       setIntimationMessage('Delivery date must be after the shipping date');
//     } else {
//       setDueDate(value);
//       setIntimationMessage('');
//     }
//   };

//   const getStatusStyle = (orderStatus) => {
//     switch (orderStatus) {
//       case "Payment Pending":
//         return { color: "#F3A638" };
//       case "Payment success":
//         return { color: "#4CB64C" };
//       case "processing":
//         return { color: "#54B7D3" };
//       case "shipped":
//         return { color: "#1E91CF" };
//       case "delivered":
//         return { color: "#4CB64C" };
//       case "cancel":
//         return { color: "#E3503E" };
//       default:
//         return {};
//     }
//   };

//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
//   };

//   const formatOrderCode = (orderId) => {
//     return `ORD-${orderId}`;
//   };

//   const handleUpdateClick = (orderId) => {
//     setSelectedOrderId(orderId);
//     setShowUpdateModal(true);
//   };

//   const handlePageChange = (newPage) => {
//     if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
//       setCurrentPage(newPage);
//     }
//   };

//   const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
//   const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
//   const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
//   const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

//   return (
//     <div className="container">
//       <div className="row">
//         <div className="col">
//         {intimationMessage && <Alert variant="danger" className="mt-3">{intimationMessage}</Alert>}
//           <div className="d-flex mt-4 mb-4">
//           <h4 className="mt-4"> Order List <img src={order} alt="" width={'40px'}/> </h4>
//             <div className="ms-auto d-flex h-25 rounded-2" style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
//               <input
//                 type="text"
//                 className="form-control w-100 text-start border border-0"
//                 placeholder="Search..."
//                 value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//               />
//               <FaSearch className="fs-3 mt-2 m-2" />
//             </div>
//           </div>
//           {loading ? (
//             <div className="d-flex justify-content-center mt-5">
//               <Spinner animation="border" />
//             </div>
//           ) : (
//             <div className="table-responsive">
//               <table className="table table-striped" style={{ border: "1px solid #60656b" }}>
//                 <thead style={{ border: "1px " }}>
//                   <tr style={{ fontSize: "smaller" }} className="text-center">
//                     <th scope="col">S.No</th>
//                     <th scope="col">Part No</th>
//                     <th scope="col">Order Id</th>
//                     <th scope="col">Name</th>
//                     <th scope="col">Email</th>
//                     <th scope="col">Shipping Address</th>
//                     <th scope="col">Quantity</th>
//                     <th scope="col">Amount</th>
//                     <th scope="col">Order Status</th>
//                     <th scope="col">Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentOrders.length === 0 ? (
//                     <tr>
//                       <td colSpan="10" className="text-center">No Orders Found</td>
//                     </tr>
//                   ) : (
//                     currentOrders.map((order, index) => (
//                       <tr key={order._id}>
//                         <td className="text-center">{indexOfFirstOrder + index + 1}</td>
//                         <td className="text-center">{order.orderItems[0]?.partNumber}</td>
//                         <td className="text-center">{formatOrderCode(order._id)}</td>
//                         <td className="text-center">{order.shippingInfo.firstname} {order.shippingInfo.lastname}</td>
//                         <td className="text-center">{order.shippingInfo.email}</td>
//                         <td className="text-center">{order.shippingInfo.address}, {order.shippingInfo.city}, {order.shippingInfo.state}</td>
//                         <td className="text-center">{order.orderItems[0]?.quantity}</td>
//                         <td className="text-center">{order.orderTotal}</td>
//                         <td className="text-center">
//                         <FaCircle style={getStatusStyle(order.orderStatus)} className="me-2"/>
//                         {order.orderStatus}</td>
//                         <td className="text-center">
//                           <Button variant="primary" onClick={() => handleUpdateClick(order._id)}>Update</Button>
//                         </td>
//                       </tr>
//                     ))
//                   )}
//                 </tbody>
//               </table>
//               <div className="d-flex justify-content-between mt-3">
//                 <Button
//                   variant="secondary"
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                 >
//                   Previous
//                 </Button>
//                 <span>
//                   Page {currentPage} of {totalPages}
//                 </span>
//                 <Button
//                   variant="secondary"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                 >
//                   Next
//                 </Button>
//               </div>
//             </div>
//           )}

//         </div>
//       </div>

//       {/* Update Modal */}
//       <Modal show={showUpdateModal} onHide={onClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Update Order Status </Modal.Title>
//       </Modal.Header>

//       <Modal.Body>
//         {orders.length > 0 && (
//           <h5>Product ID: {formatOrderCode(orders[0].orderId)} </h5> // Display formatted product ID
//         )}
//         {intimationMessage && <Alert variant="info">{intimationMessage}</Alert>}
//         <Form.Group controlId="orderStatus">
//           <Form.Label>Update Order Status</Form.Label>
//           <Form.Control as="select" value={status} onChange={(e) => setStatus(e.target.value)}>
//             <option value="">Select status</option>
//             <option value="Delivered">Delivered</option>
//             <option value="Payment success">Payment success</option>
//             <option value="Shipped">Shipped</option>
//             <option value="Payment Pending">Payment Pending</option>
//             <option value="processing">Processing</option>
//             <option value="cancel">Cancel</option>
//           </Form.Control>
//         </Form.Group>
//         {status === 'cancel' && (
//           <Form.Group controlId="cancelReason">
//             <Form.Label>Reason for Cancellation</Form.Label>
//             <Form.Control
//               as="textarea"
//               rows={3}
//               value={reason.cancel}
//               onChange={(e) => setReason(prev => ({ ...prev, cancel: e.target.value }))}
//             />
//           </Form.Group>
//         )}
//         {status === 'Payment success' && (
//           <Form.Group controlId="paymentSuccessReason">
//             <Form.Label>Reason for Payment Success</Form.Label>
//             <Form.Control
//               as="textarea"
//               rows={3}
//               value={reason['Payment success']}
//               onChange={(e) => setReason(prev => ({ ...prev, 'Payment success': e.target.value }))}
//             />
//           </Form.Group>
//         )}
//         {status === 'processing' && (
//           <Form.Group controlId="processingReason">
//             <Form.Label>Reason for Processing</Form.Label>
//             <Form.Control
//               as="textarea"
//               rows={3}
//               value={reason.processing}
//               onChange={(e) => setReason(prev => ({ ...prev, processing: e.target.value }))}
//             />
//           </Form.Group>
//         )}
//         {status === 'Payment Pending' && (
//           <Form.Group controlId="paymentPendingReason">
//             <Form.Label>Reason for Payment Pending</Form.Label>
//             <Form.Control
//               as="textarea"
//               rows={3}
//               value={reason['Payment Pending']}
//               onChange={(e) => setReason(prev => ({ ...prev, 'Payment Pending': e.target.value }))}
//             />
//           </Form.Group>
//         )}
//         {status === 'Delivered' && (
//           <Form.Group controlId="deliveryDate">
//             <Form.Label>Delivery Date</Form.Label>
//             <Form.Control
//               type="date"
//               value={dueDate}
//               onChange={handleDateChange}
//               className='w-100'
//             />
//           </Form.Group>
//         )}
//         {status === 'Shipped' && (
//           <Form.Group controlId="shippingDates">
//             <div className='row mt-3'>
//               <div className='col-md-6'>
//                 <Form.Label>Shipping Date</Form.Label>
//                 <Form.Control
//                   type="date"
//                   value={currDate}
//                   onChange={(e) => setCurrDate(e.target.value)}
//                   className='w-100'
//                 />
//               </div>
//               <div className="col-md-6">
//                 <Form.Label>Delivery Date</Form.Label>
//                 <Form.Control
//                   type="date"
//                   value={dueDate}
//                   onChange={handleDateChange}
//                   className='w-100'
//                 />
//               </div>
//             </div>
//           </Form.Group>
//           )}

//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={onClose}>
//           Close
//         </Button>
//         <Button variant="primary" onClick={handleSubmit} disabled={loading}>
//           {loading ? 'Sending...' : 'Send to Mail'}
//         </Button>  {loading && (
//             <div className="text-center">
//               <Spinner animation="border" role="status">
//                 <span className="visually-hidden">Loading...</span>
//               </Spinner>
//             </div>
//           )}
//       </Modal.Footer>
//     </Modal>
//     </div>
//   );
// };

// export default OrderProducts;
import Preloader from "../../../Preloader/Preloader-Animation.json";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCircle, FaSearch } from "react-icons/fa";
import { Button, Modal, Form, Alert, Spinner } from "react-bootstrap";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils";
import "./orderProducts.scss";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import order from "../../../Assets/Images/order list.png";
import Lottie from "lottie-react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

const ITEMS_PER_PAGE = 5;

const OrderProducts = ({}) => {
  const [show, setShow] = useState(true);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalOrders, setTotalOrders] = useState(0);
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState({
    "Payment Pending": "Awaiting payment confirmation",
    "Payment success": "Payment confirmed",
    processing: "Awaiting processing confirmation",
    cancel: "",
  });
  const [currDate, setCurrDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const [intimationMessage, setIntimationMessage] = useState("");
  const [dueDate, setDueDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const header = useAuthHeaders();
  const adminId = localStorage.getItem("adminId");
  useEffect(() => {
    fetchOrders();
  }, [header]);

  const headers = {
    Authorization: `Bearer ${adminId}`,
    "Content-Type": "application/json",
  };
  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${API_BASE_URL}/admin/order/getOrders`, {
        headers: headers,
      });
      setOrders(res.data.orders);
      setFilteredOrders(res.data.orders);
      setTotalOrders(res.data.orders.length);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setIntimationMessage("Failed to fetch order details");
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };
  const onClose = () => {
    setShowUpdateModal(false);
  };
  useEffect(() => {
    const results = orders.filter((order) => {
      const searchLower = search.toLowerCase();
      return (
        order?.shippingInfo?.firstname?.toLowerCase().includes(searchLower) ||
        order?.shippingInfo?.lastname?.toLowerCase().includes(searchLower) ||
        order?.orderItems[0]?.partNumber?.toLowerCase().includes(searchLower) ||
        order?.orderItems[0]?.quantity?.toString().includes(searchLower) ||
        order?.orderTotal?.toString().includes(searchLower) ||
        order?.orderStatus?.toLowerCase().includes(searchLower) ||
        order?.orderItems[0]?.color?.toLowerCase().includes(searchLower)
      );
    });
    setFilteredOrders(results);
    setCurrentPage(1);
  }, [search, orders]);

  const handleSubmit = async () => {
    if (!status) {
      setIntimationMessage("Please select an order status");
      setShowAlert(true);
      return;
    }

    if (status === "Shipped" && new Date(dueDate) < new Date(currDate)) {
      setIntimationMessage("Delivery date must be after the shipping date");
      setShowAlert(true);
      return;
    }

    const payload = {
      orderStatus: status,
      shippingDate: status === "Shipped" ? currDate : undefined,
      deliveryDate:
        status === "Shipped" || status === "Delivered" ? dueDate : undefined,
      deliveredAt: status === "Delivered" ? dueDate : undefined,
      reason: reason[status] || reason["cancel"],
    };

    Object.keys(payload).forEach(
      (key) => payload[key] === undefined && delete payload[key]
    );

    if (!selectedOrderId) {
      setIntimationMessage("No order selected");
      setShowAlert(true);
      return;
    }

    setLoading(true);
    try {
      await axios
        .put(`${API_BASE_URL}/admin/order/${selectedOrderId}`, payload, {
          headers,
        })
        .then((response) => {
          toast.success(`${response?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          });
        });
      // setIntimationMessage("Order status updated successfully");

      setShowAlert(true);
      fetchOrders(); // Refetch orders after updating
      setShowUpdateModal(false); // Close modal on success
    } catch (err) {
      console.error("Error updating order:", err.response?.data || err.message);
      setIntimationMessage(err?.response?.data?.message);

      toast.error(`${err?.response?.data?.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    if (new Date(value) < new Date(currDate)) {
    
      toast.info("Delivery date must be after the shipping date", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    } else {
      setDueDate(value);
      setIntimationMessage("");
      setShowAlert(false);
    }
  };

  const handleDeleteClick = async (orderId) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        await axios.delete(`${API_BASE_URL}/admin/order/${orderId}`, {
          headers,
        });
        setOrders(orders.filter((order) => order._id !== orderId));
        setFilteredOrders(
          filteredOrders.filter((order) => order._id !== orderId)
        );

        // Show success toast
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Order deleted successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.error(
          "Error deleting order:",
          err.response?.data || err.message
        );

        // Show error toast
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Failed to delete order",
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getStatusStyle = (orderStatus) => {
    switch (orderStatus) {
      case "Payment Pending":
        return { color: "#F3A638" };
      case "Payment success":
        return { color: "#91f891" };
      case "processing":
        return { color: "#54B7D3" };
      case "Shipped":
        return { color: "#1E91CF" };
      case "Delivered":
        return { color: "#067e06" };
      case "cancel":
        return { color: "#E3503E" };
      default:
        return {};
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const formatOrderCode = (orderId) => {
    return `ORD-${orderId}`;
  };

  const handleUpdateClick = (orderId) => {
    setSelectedOrderId(orderId);
    setShowUpdateModal(true);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalOrders / ITEMS_PER_PAGE)) {
      setCurrentPage(newPage);
    }
  };

  const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );
  const totalPages = Math.ceil(filteredOrders.length / ITEMS_PER_PAGE);

  return (
    <div className="container">
      <div className="mt-5">
        <div className="row">
          <div className="col">
            <div className="d-flex mt-3 ">
              <h4 className="mt-4">
                {" "}
                Order List
                {/* <img src={order} alt="" width={'40px'}/> */}
              </h4>

              <div
                className="ms-auto d-flex h-25 rounded-2"
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <input
                  type="text"
                  className="form-control w-100 text-start border border-0"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <FaSearch className="fs-3 mt-2 m-2" />
              </div>
            </div>
            {loading ? (
              <div className="d-flex justify-content-center mt-5">
                {/* <Spinner animation="border" /> */}
                <Lottie
                  style={{ width: "100vw", height: "50vh" }}
                  animationData={Preloader}
                />
              </div>
            ) : (
              <div className="">
                <table
                  className="table table-striped"
                  style={{ border: "1px solid #60656b" }}
                >
                  <thead style={{ border: "1px " }}>
                    <tr style={{ fontSize: "smaller" }} className="text-center">
                      <th scope="col">S.No</th>
                      <th scope="col">Part No</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Shipping Address</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Order Status</th>
                      <th scope="col">Action</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentOrders.length === 0 ? (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Orders Found
                        </td>
                      </tr>
                    ) : (
                      currentOrders.map((order, index) => (
                        <tr key={order._id}>
                          <td className="text-center">
                            {indexOfFirstOrder + index + 1}
                          </td>
                          <td className="text-center">
                            {order.orderItems[0]?.partNumber}
                          </td>
                          <td className="text-center">
                            {formatOrderCode(order.orderId)}
                          </td>
                          <td className="text-center">
                            {order.shippingInfo.firstname}{" "}
                            {order.shippingInfo.lastname}
                          </td>
                          <td className="text-center">
                            {order.shippingInfo.email}
                          </td>
                          <td className="text-center">
                            {order.shippingInfo.address}{" "}
                            {order.shippingInfo.city}
                            {order.shippingInfo.state}
                          </td>
                          <td className="text-center">
                            {order.orderItems[0]?.quantity}
                          </td>
                          <td className="text-center">{order.orderTotal}</td>
                          <td className="text-center">
                            <FaCircle
                              style={getStatusStyle(order.orderStatus)}
                              className="me-2"
                            />
                            {order.orderStatus}
                          </td>
                          <td className=" ">
                            <div className="d-flex ">
                              <Button
                                variant="primary"
                                onClick={() => handleUpdateClick(order._id)}
                              >
                                Update
                              </Button>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <RiDeleteBin6Line
                              className="  fs-3 mt-1 ms-2 text-danger"
                              onClick={() => handleDeleteClick(order._id)}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div className="d-flex justify-content-between mt-3">
                  <Button
                    variant="secondary"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <span>{/* Page {currentPage} of {totalPages} */}</span>
                  <Button
                    variant="secondary"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Update Modal */}
      <Modal show={showUpdateModal} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Status </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {orders.length > 0 && (
            <h5>Product ID: {formatOrderCode(orders[0].orderId)} </h5> // Display formatted product ID
          )}
          {/* {intimationMessage && (
            <Alert variant="info">{intimationMessage}</Alert>
          )} */}
          <Form.Group controlId="orderStatus">
            <Form.Label>Update Order Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Select status</option>
              <option value="Delivered">Delivered</option>
              <option value="Payment success">Payment success</option>
              <option value="Shipped">Shipped</option>
              <option value="Payment Pending">Payment Pending</option>
              <option value="processing">Processing</option>
              <option value="cancel">Cancel</option>
            </Form.Control>
          </Form.Group>
          {status === "cancel" && (
            <Form.Group controlId="cancelReason">
              <Form.Label>Reason for Cancellation</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reason.cancel}
                onChange={(e) =>
                  setReason((prev) => ({ ...prev, cancel: e.target.value }))
                }
              />
            </Form.Group>
          )}
          {status === "Payment success" && (
            <Form.Group controlId="paymentSuccessReason">
              <Form.Label>Reason for Payment Success</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reason["Payment success"]}
                onChange={(e) =>
                  setReason((prev) => ({
                    ...prev,
                    "Payment success": e.target.value,
                  }))
                }
              />
            </Form.Group>
          )}
          {status === "processing" && (
            <Form.Group controlId="processingReason">
              <Form.Label>Reason for Processing</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reason.processing}
                onChange={(e) =>
                  setReason((prev) => ({ ...prev, processing: e.target.value }))
                }
              />
            </Form.Group>
          )}
          {status === "Payment Pending" && (
            <Form.Group controlId="paymentPendingReason">
              <Form.Label>Reason for Payment Pending</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reason["Payment Pending"]}
                onChange={(e) =>
                  setReason((prev) => ({
                    ...prev,
                    "Payment Pending": e.target.value,
                  }))
                }
              />
            </Form.Group>
          )}
          {status === "Delivered" && (
            <Form.Group controlId="deliveryDate">
              <Form.Label>Delivery Date</Form.Label>
              <Form.Control
                type="date"
                value={dueDate}
                onChange={handleDateChange}
                className="w-100"
              />
            </Form.Group>
          )}
          {status === "Shipped" && (
            <Form.Group controlId="shippingDates">
              <div className="row mt-3">
                <div className="col-md-6">
                  <Form.Label>Shipping Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={currDate}
                    onChange={(e) => setCurrDate(e.target.value)}
                    className="w-100"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>Delivery Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={dueDate}
                    onChange={handleDateChange}
                    className="w-100"
                  />
                </div>
              </div>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? "Sending..." : "Send to Mail"}
          </Button>{" "}
          {loading && (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default OrderProducts;
