import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils";
import { useAuthHeaders } from "../../../components/Token";
import './ViewEnquiryDetails.css'; 

function ViewEnquiryDetails() {
  const [orderDetails, setOrderDetails] = useState({});
  const headers = useAuthHeaders();
  const { _id } = useParams();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/enquiry/${_id}`, { headers })
      .then((res) => setOrderDetails(res?.data?.data))
      .catch((error) => console.log("error", error));
  }, [_id, headers]);

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  const formatdates = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  return (
    <div className="order-details mt-5">
      <h2>Enquiry Details</h2>
      <div className="detail-item">
        <strong>Order ID:</strong>
        <span>{orderDetails._id}</span>
      </div>
      <div className="detail-item">
        <strong>Company Name:</strong>
        <span>{orderDetails.companyName}</span>
      </div>
      <div className="detail-item">
        <strong>Name:</strong>
        <span>{orderDetails.name}</span>
      </div>
      <div className="detail-item">
        <strong>Phone:</strong>
        <span>{orderDetails.mobile}</span>
      </div>
      <div className="detail-item">
        <strong>Email:</strong>
        <span>{orderDetails.email}</span>
      </div>
      <div className="detail-item">
        <strong>Subject:</strong>
        <span>{orderDetails.subject}</span>
      </div>
      <div className="detail-item">
        <strong>Status:</strong>
        <span>{orderDetails.Status}</span>
      </div>
      <div className="detail-item">
        <strong>Preferred Method To Contact:</strong>
        <span>{orderDetails.preferredMethodToContact}</span>
      </div>
      <div className="detail-item">
        <strong>Message:</strong>
        <span>{orderDetails.message}</span>
      </div>
      <div className="detail-item">
        <strong>Created Date:</strong>
        <span>{formatdates(orderDetails.createdAt)}</span>
      </div>
      <div className="detail-item">
        <strong>Updated Date:</strong>
        <span>{formatdates(orderDetails.updatedAt)}</span>
      </div>
    </div>
  );
}

export default ViewEnquiryDetails;
