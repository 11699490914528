import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../Commom/Commom.scss";
import axios from "axios";
import NewProductsStaticField from "./NewProductsStaticField";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../components/MinorComponents/CancelButton";
import { Spinner } from "react-bootstrap";

function NewProducts() {
  const [partNumber, setPartNumber] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sumbitCategory, setSumbitCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [sumbitSubCategory, setSumbitSubCategory] = useState("");
  const [productsImages, setProductsImages] = useState([]);
  const [ProductsImagesPreview, setProductsImagesPreview] = useState([]);
  const [manuFacturer, setManuFacturer] = useState();
  const [weightPerPiece, setWeightPerPiece] = useState();
  const [productWeight, setProductWeight] = useState(0);
  const [boxOfQuantity, setBoxOfQuantity] = useState();
  const [dealofDay, setDealofDay] = useState(false);
  const [writeNotes, setWriteNotes] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [oldAmount, setOldAmount] = useState();
  const [products, setProducts] = useState();
  const [salePrice, setSalelPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [metaTitle, setmetaTitle] = useState();
  const [cad, setCad] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const navigate = useNavigate();
  const [discriptionType, setDiscriptionType] = useState("");
  const [selectDiscriptionType, setSelectDiscriptionType] = useState([]);
  const defaultData = [{}];
  const [manualInput, setManualInput] = useState(defaultData);
  const [loading, setLoading] = useState(false);

  const handleChange = (ele) => {
    const { name, value } = ele.target;
    setSelectDiscriptionType((prev) => ({ ...prev, [name]: value }));
  };

  const headers = useAuthHeaders();

  console.log("manualInput", manualInput)
  console.log("selectDiscriptionType", selectDiscriptionType)


  const handleproductCreation = (e) => {
    e.preventDefault();
    const formData = new FormData();
    console.log("new product data file is comming")
    productsImages.forEach((productsImage) => {
      formData.append("productimages", productsImage);
    });

    cad.forEach((cadFile) => {
      formData.append("cadFile", cadFile);
    });

    const FormattedProductWeight = productWeight;

    const kgIndex = FormattedProductWeight.indexOf(" kg");
    const gramsIndex = FormattedProductWeight.indexOf(" g");

    let weightWithoutUnit;

    if (kgIndex !== -1) {
      weightWithoutUnit = FormattedProductWeight.substring(0, kgIndex);
    } else if (gramsIndex !== -1) {
      weightWithoutUnit = FormattedProductWeight.substring(0, gramsIndex);
    } else {
      weightWithoutUnit = FormattedProductWeight;
    }

    formData.append("productweight", weightWithoutUnit.trim());

    formData.append("partNumber", partNumber);
    formData.append("categoryname", sumbitCategory);
    formData.append("subcategoryname", sumbitSubCategory);
    formData.append("manufacturer", manuFacturer);
    formData.append("weightperpiece", weightPerPiece);
    formData.append("boq", boxOfQuantity);
    formData.append("dealofday", dealofDay || false);
    if (selectedDate) {
      formData.append("dealExpiryDate", selectedDate);
    }
    formData.append("writenotes", writeNotes);
    formData.append("product", products);
    formData.append("Productprice", originalPrice);
    formData.append("saleprice", salePrice);
    formData.append("quantity", quantity);
    formData.append("metatitle", metaTitle);
    formData.append("oldprice", oldAmount);

    const formattedDescriptions = Object.entries(selectDiscriptionType).map(
      ([name, value]) => ({
        name: name ?? "Default Name",
        value: value ?? "Default Value",
      })
    );

    formattedDescriptions.forEach((description, index) => {
      formData.append(
        `descriptions[${index}][name]`,
        description.name || "loki"
      );

      formData.append(
        `descriptions[${index}][value]`,
        description.value || "loki"
      );
    });

    const validManualInputs = manualInput.filter((input) => {
      return (
        input &&
        typeof input === "object" &&
        Object.values(input).some((value) => value.trim() !== "")
      );
    });

    let manualInputIndex = formattedDescriptions?.length;

    const formattedDescriptionsManualInput = validManualInputs.map(
      (item, index) => {
        if (item && typeof item === "object") {
          const keys = Object.keys(item);
          const values = Object.values(item);

          return {
            name: keys.length > 0 ? keys[0] : "Default Name",
            value: values.length > 0 ? values[0] : "Default Value",
          };
        }

        return {
          name: "Default Name",
          value: "Default Value",
        };
      }
    );

    formattedDescriptionsManualInput.forEach((value, index) => {
      const nameToAppend = value?.name || "";
      const valueToAppend = value?.value || "";

      if (nameToAppend.trim() !== "") {
        formData.append(
          `descriptions[${manualInputIndex + index}][name]`,
          nameToAppend
        );
        formData.append(
          `descriptions[${manualInputIndex + index}][value]`,
          valueToAppend
        );
      }
    });

    if (formData.get("Empty")) {
      formData.delete("Empty");
    }

    setLoading(true);

    axios
      .post(`${API_BASE_URL}/admin/newproducts`, formData, {
        headers,
      })
      .then((result) => {
        Swal.fire({
          icon: "success",
          title: "Product Add SuccessFully",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/newproductsViewPage");
      })
      .catch((error) => {
        console.log("error", error.response);
        setLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data?.message,
        });
      });
  };

  const onImagesChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState == 2) {
          setProductsImagesPreview((oldArray) => [...oldArray, reader.result]);
          setProductsImages((oldArray) => [...oldArray, file]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index) => {
    setProductsImagesPreview((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
    setProductsImages((prevImages) => prevImages.filter((_, i) => i !== index));

    const inputElement = document.getElementById("productimages");

    if (inputElement) {
      inputElement.value = null;
    }
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
      .then((response) => {
        setCategories(response?.data?.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);

    const findCategoryName = categories.find(
      (findCat) => findCat._id === selectedCategory
    );

    setSumbitCategory(findCategoryName?.name);

    axios
      .get(`${API_BASE_URL}/category/admin/${selectedCategory}`)
      .then((response) => {
        setSubCategory(response.data?.subcategoryNames);
        // console.log("New subcategory :" , response.data.subcategories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  const handleSubCategorySelect = (e) => {
    const selectedSubCategory = e.target.value;
    console.log("Selected sub category is$$$$$$$$ ----->",selectedSubCategory)
    setSelectedSubCategory(selectedSubCategory);

    const findSubCategory = subCategory.find(
      (finSub) => finSub._id === selectedSubCategory
    );
    setSumbitSubCategory(findSubCategory.name);
    console.log("FindSub category present or not sub category is$$$$$$$$ ----->",findSubCategory)

    axios
      .get(
        `${API_BASE_URL}/admin/getdescriptionnametypesbysubcategory/${selectedSubCategory}`
      )
      .then((response) => {
        setDiscriptionType(response.data.descriptionnames);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });

      console.log("Selected sub category based on description type is $$$$$$$$ ----->",discriptionType)

  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Create New Products</h2>
      </div>

      <form
        action="submit"
        className="category"
        onSubmit={handleproductCreation}
        style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}
      >
        <NewProductsStaticField
          setPartNumber={setPartNumber}
          onImagesChange={onImagesChange}
          removeImage={removeImage}
          ProductsImagesPreview={ProductsImagesPreview}
          setManuFacturer={setManuFacturer}
          setWeightPerPiece={setWeightPerPiece}
          weightPerPiece={weightPerPiece}
          setProductWeight={setProductWeight}
          productWeight={productWeight}
          boxOfQuantity={boxOfQuantity}
          setBoxOfQuantity={setBoxOfQuantity}
          dealofDay={dealofDay}
          setDealofDay={setDealofDay}
          writeNotes={writeNotes}
          setWriteNotes={setWriteNotes}
          originalPrice={originalPrice}
          setOriginalPrice={setOriginalPrice}
          setProducts={setProducts}
          salePrice={salePrice}
          setSalelPrice={setSalelPrice}
          setQuantity={setQuantity}
          setmetaTitle={setmetaTitle}
          setCad={setCad}
          /////////////////
          categories={categories}
          selectedCategory={selectedCategory}
          handleCategorySelect={handleCategorySelect}
          ////////////////////////
          subCategory={subCategory}
          selectedSubCategory={selectedSubCategory}
          handleSubCategorySelect={handleSubCategorySelect}
          //////////////////////////
          discriptionType={discriptionType}
          handleChange={handleChange}
          setManualInput={setManualInput}
          manualInput={manualInput}

          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          setOldAmount={setOldAmount}
        />

        <div className="buttons">
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <button type="submit" value="send"  style={{ backgroundColor: "#0443C0", color: "white" }}>
              Save
            </button>
          )}

          <CancelButton path="/newproductsViewPage" />
        </div>
      </form>
    </div>
  );
}

export default NewProducts;


