// import React, { useContext, useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
// import { CSVLink } from "react-csv";
// import userContext from "../../../components/UseContext/UseContext";
// import NoDataFound from "./No data found.json";
// import Lottie from "lottie-react";

// const Export = () => {
//   const [returnCondition, setReturnCondition] = useState(false);
//   const ExportData = useContext(userContext);
//   const val = ExportData.exportProducts;

//   console.log(val);

//   let object = [];

//   val.map((val1, index) => {
//     let key1 = "a";
//     let key2 = "b";
//     let value1 = val1.category.name;
//     let value2 = val1.subcategoryname.name;

//     const simplyJson = function (val1) {
//       const simpleObj = {};
//       for (let key in val1) {
//         const value = val1[key];
//         const type = typeof value;

//         if (
//           [`string`, `boolean`].includes(type) ||
//           (type === `number` && !isNaN(value))
//         ) {
//           simpleObj[key] = value;
//         } else if (type == "object") {
//           Object.assign(simpleObj, simplyJson(value));
//         }
//       }

//       simpleObj[key1] = value1;
//       simpleObj[key2] = value2;
//       return simpleObj;
//     };
//     Object.keys(val1).forEach((val2) => {
//       if (val2 === "category") {
//         key1 = val2;
//       }

//       if (val2 === "subcategoryname") {
//         key2 = val2;
//       }
//     });
//     object.push(simplyJson(val1));
//   });
//   console.log("exported modified ", object);

//   useEffect(() => {
//     if (object.length == 0) {
//       setReturnCondition(false);
//       // console.log(object.length)
//     } else {
//       setReturnCondition(true);
//     }
//   });

//   const replaceFunction = (data) => {
//     if (typeof data === "string") {
//       let val = data.replace(/(<([^>]+)>)/gi, "");
//       return val;
//     } else {
//       return data;
//     }
//   };



//   let sample=[
//     {
//       partNumber: "1234",
//       category: "",
//       subcategoryname: "",
//       writenotes: "Description of product 1",
//       quantity: 10,
//       manufacturer: "Manufacturer 1",
//       weightperpiece: "500g",
//       productweight: "5kg",
//       boq: "Box of 10",
//       oldprice: 200,
//       Productprice: 150,
//       product: "Product 1",
//       saleprice: 140,
//       metatitle: "Meta title 1"
//     }]


//   if (returnCondition) {
//     return (
//       <div  >
//         <h1>Export All Data</h1>
//         <div style={{ width: "80%",border:"2px solid", overflow: "auto" }}>
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 {Object.keys(object[0]).map((d1) => (
//                   <th key={d1}>{d1}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {object.map((data, _id) => (
//                 <tr key={data._id}>
//                   {Object.keys(data).map((d1) => (
//                     <td
//                       style={{ textAlign: "center", textWrap: "nowrap" }}
//                       key={d1}
//                     >
//                       {replaceFunction(data[d1])}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </div>
//         <div className="csv-button">
//           <CSVLink data={object} filename={"my-file"}>
//             Download
//           </CSVLink>
//         </div>
//       </div>
//     );
//   } else {
//     return (
//       <div>
//         <Lottie
//           style={{ width: "100%", height: "60vh" }}
//           animationData={NoDataFound}
//         />
//       </div>
//     );
//   }
// };

// export default Export;
























// import React, { useContext, useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
// import { CSVLink } from "react-csv";
// import Button from "@mui/material/Button";
// import Swal from "sweetalert2";
// import Lottie from "lottie-react";
// import { useNavigate } from "react-router-dom";
// import userContext from "../../../components/UseContext/UseContext";
// import NoDataFound from "./No data found.json";

// const Export = () => {
//   const [returnCondition, setReturnCondition] = useState(false);
//   const ExportData = useContext(userContext);
//   const val = ExportData.exportProducts;
//   const navigate = useNavigate();

//   let object = [];

//   val.map((val1) => {
//     let key1 = "a";
//     let key2 = "b";
//     let value1 = val1.category.name;
//     let value2 = val1.subcategoryname.name;

//     const simplyJson = function (val1) {
//       const simpleObj = {};
//       for (let key in val1) {
//         const value = val1[key];
//         const type = typeof value;

//         if (
//           [`string`, `boolean`].includes(type) ||
//           (type === `number` && !isNaN(value))
//         ) {
//           simpleObj[key] = value;
//         } else if (type === "object") {
//           Object.assign(simpleObj, simplyJson(value));
//         }
//       }

//       simpleObj[key1] = value1;
//       simpleObj[key2] = value2;
//       return simpleObj;
//     };
//     Object.keys(val1).forEach((val2) => {
//       if (val2 === "category") {
//         key1 = val2;
//       }

//       if (val2 === "subcategoryname") {
//         key2 = val2;
//       }
//     });
//     object.push(simplyJson(val1));
//   });

//   useEffect(() => {
//     if (object.length === 0) {
//       setReturnCondition(false);
//     } else {
//       setReturnCondition(true);
//     }
//   }, [object]);

//   const replaceFunction = (data) => {
//     if (typeof data === "string") {
//       return data.replace(/(<([^>]+)>)/gi, "");
//     } else {
//       return data;
//     }
//   };

//   const handleDownload = () => {
//     Swal.fire({
//       icon: "success",
//       title: "Product Successfully Exported",
//       showConfirmButton: true,
//       timer: 32000,
//       text: "The products have been successfully exported.",
//     });
//   };

//   const handleCancel = () => {
//     navigate("/newproductsViewPage");
//   };

//   return (
//     <div>
//       {returnCondition ? (
//         <div>
//           <h1>Export All Data</h1>
//           <div style={{ width: "100%", overflow: "auto" }}>
//             <Table striped bordered hover>
//               <thead>
//                 <tr>
//                   {Object.keys(object[0]).map((d1) => (
//                     <th key={d1}>{d1}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {object.map((data) => (
//                   <tr key={data._id}>
//                     {Object.keys(data).map((d1) => (
//                       <td
//                         style={{ textAlign: "center", textWrap: "nowrap" }}
//                         key={d1}
//                       >
//                         {replaceFunction(data[d1])}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//           <div className="csv-button" style={{ marginTop: "20px" }}>
//             <CSVLink
//               data={object}
//               filename={"my-file.csv"}
//               onClick={handleDownload}
//               style={{ textDecoration: "none" }}
//             >
//               <Button
//                 variant="contained"
//                 color="success"
//                 onClick={handleDownload}
//               >
//                 Download
//               </Button>
//             </CSVLink>
//             <Button
//               variant="contained"
//               color="error"
//               onClick={handleCancel}
//               style={{ marginLeft: "10px" }}
//             >
//               Cancel
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <div>
//           <Lottie
//             style={{ width: "100%", height: "60vh" }}
//             animationData={NoDataFound}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default Export;



// 


// import React, { useContext, useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
// import { CSVLink } from "react-csv";
// import Button from "@mui/material/Button";
// import Swal from "sweetalert2";
// import Lottie from "lottie-react";
// import { useNavigate } from "react-router-dom";
// import userContext from "../../../components/UseContext/UseContext";
// import NoDataFound from "./No data found.json";

// const Export = () => {
//   const [returnCondition, setReturnCondition] = useState(false);
//   const ExportData = useContext(userContext);
//   const val = ExportData.exportProducts;
//   const navigate = useNavigate();

//   const excludeFields = [
//     "_id",
//     "numOfReviews",
//     "cadFile",
//     "productimage",
//     "value",
//     "__v",
//     "createdAt",
//     "isActive",
//     "category.admin",
//     "subcategoryname.category", 
//     "subcategoryname.admin",
//     "admin",
//     "slug",
//     "descriptions",
//     "productimages",
//     "reviews",
//     "dealofday",
//     "dealExpiryDate",
//     "Productprice",
//     "productweight"
//   ];


//   const fieldMapping = {
//     "category.name": "category",
//     "subcategoryname.name": "subcategoryname"
//   };

//   const flattenObject = (obj, parentKey = '', res = {}) => {
//     for (let key in obj) {
//       if (!excludeFields.includes(key)) {
//         let propName = parentKey ? `${parentKey}.${key}` : key;
//         if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
//           flattenObject(obj[key], propName, res);
//         } else {
//           res[propName] = obj[key];
//         }
//       }
//     }
//     return res;
//   };

//   const replaceKeys = (obj, mapping) => {
//     const newObj = {};
//     for (let key in obj) {
//       const newKey = mapping[key] || key;
//       newObj[newKey] = obj[key];
//     }
//     return newObj;
//   };

//   const object = val.map((val1) => replaceKeys(flattenObject(val1), fieldMapping));
//   object.map((val)=>{
//       // let v=subcategoryname.category;
//       // delete val["Productprice"]
//       // delete val["productweight"]
//     delete val["subcategoryname.category"]
//     return val;
//   })



//   useEffect(() => {
//     setReturnCondition(object.length > 0);
//   }, [object]);

//   const replaceFunction = (data) => {
//     if (typeof data === "string") {
//       return data.replace(/(<([^>]+)>)/gi, "");
//     } else {
//       return data;
//     }
//   };

//   const handleDownload = () => {
//     Swal.fire({
//       icon: "success",
//       title: "Product Successfully Exported",
//       showConfirmButton: true,
//       timer: 32000,
//       text: "The products have been successfully exported.",
//     });
//   };

//   const handleCancel = () => {
//     navigate("/newproductsViewPage");
//   };

//   console.log("check objectexcel data is came or not ",object)

//   let obj1=[{name:"ajin",job:"backedn developer"},{name:"jeeva",job:"frontend developer"}]

//   let obj2=obj1.map((val)=>{

//     delete val.job
//     return val;
//   })


//   console.log("--------remove the value form the object ------------------",obj2)

//   return (
//     <div>
//       {returnCondition ? (
//         <div>
//           <h1>Export All Data</h1>
//           <div style={{ width: "100%", overflow: "auto" ,padding:"2%"}}>
//             <Table striped bordered hover  style={{ width: "90%", overflow: "auto" ,border:"2px solid blue" }}>
//               <thead>
//                 <tr>
//                   {Object.keys(object[0]).map((key) => (
//                     <th key={key}>{fieldMapping[key] || key}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {object.map((data, index) => (
//                   <tr key={index}>
//                     {Object.keys(data).map((key) => (
//                       <td
//                         key={key}
//                         style={{ textAlign: "center", whiteSpace: "nowrap" }}
//                       >
//                         {replaceFunction(typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key])}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//           <div className="csv-button" style={{ marginTop: "20px" }}>
//             <CSVLink
//               data={object}
//               filename={"my-file.csv"}
//               onClick={handleDownload}
//               style={{ textDecoration: "none" }}
//             >
//               <Button
//                 variant="contained"
//                 color="success"
//                 onClick={handleDownload}
//               >
//                 Download
//               </Button>
//             </CSVLink>
//             <Button
//               variant="contained"
//               color="error"
//               onClick={handleCancel}
//               style={{ marginLeft: "10px" }}
//             >
//               Cancel
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <div>
//           <Lottie
//             style={{ width: "100%", height: "60vh" }}
//             animationData={NoDataFound}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default Export;







































import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import userContext from "../../../components/UseContext/UseContext";
import NoDataFound from "./No data found.json";

const Export = () => {
  const [returnCondition, setReturnCondition] = useState(false);
  const ExportData = useContext(userContext);
  const val = ExportData.exportProducts;
  const navigate = useNavigate();

  const excludeFields = [
    "_id",
    "numOfReviews",
    "cadFile",
    "productimage",
    "value",
    "__v",
    "createdAt",
    "isActive",
    "category.admin",
    "subcategoryname.category",
    "subcategoryname.admin",
    "admin",
    "slug",
    "descriptions",
    "productimages",
    "reviews",
    "dealofday",
    "dealExpiryDate",
    "Productprice",
    "productweight"
  ];

  const fieldMapping = {
    "category.name": "category",
    "subcategoryname.name": "subcategoryname"
  };

  const flattenObject = (obj, parentKey = '', res = {}) => {
    for (let key in obj) {
      if (!excludeFields.includes(key)) {
        let propName = parentKey ? `${parentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObject(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  };

  const replaceKeys = (obj, mapping) => {
    const newObj = {};
    for (let key in obj) {
      const newKey = mapping[key] || key;
      newObj[newKey] = obj[key];
    }
    return newObj;
  };

  const object = val.map((val1) => replaceKeys(flattenObject(val1), fieldMapping));
  object.map((val) => {
    delete val["subcategoryname.category"];
    return val;
  });

  useEffect(() => {
    setReturnCondition(object.length > 0);
  }, [object]);

  const replaceFunction = (data) => {
    if (typeof data === "string") {
      return data.replace(/(<([^>]+)>)/gi, "");
    } else {
      return data;
    }
  };

  const handleDownload = () => {
    Swal.fire({
      icon: "success",
      title: "Product Successfully Exported",
      showConfirmButton: true,
      timer: 32000,
      text: "The products have been successfully exported.",
    });
  };

  const handleCancel = () => {
    navigate("/newproductsViewPage");
  };

  console.log("check objectexcel data is came or not ", object);

  let obj1 = [{ name: "ajin", job: "backend developer" }, { name: "jeeva", job: "frontend developer" }];

  let obj2 = obj1.map((val) => {
    delete val.job;
    return val;
  });

  console.log("--------remove the value form the object ------------------", obj2);

  const fileName = object.length > 0 ? `${object[0]["category"]}.csv` : "export.csv";

  return (
    <div>
      {returnCondition ? (
        <div>
          <h1>Export All Data</h1>
          <div style={{ width: "100%", overflow: "auto", padding: "2%" }}>
            <Table striped bordered hover style={{ width: "90%", overflow: "auto", border: "2px solid blue" }}>
              <thead>
                <tr>
                  {Object.keys(object[0]).map((key) => (
                    <th key={key}>{fieldMapping[key] || key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {object.map((data, index) => (
                  <tr key={index}>
                    {Object.keys(data).map((key) => (
                      <td
                        key={key}
                        style={{ textAlign: "center", whiteSpace: "nowrap" }}
                      >
                        {replaceFunction(typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key])}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="csv-button" style={{ marginTop: "20px" }}>
            <CSVLink
              data={object}
              filename={fileName}
              onClick={handleDownload}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleDownload}
              >
                Download
              </Button>
            </CSVLink>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancel}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <Lottie
            style={{ width: "100%", height: "60vh" }}
            animationData={NoDataFound}
          />
        </div>
      )}
    </div>
  );
};

export default Export;















































// import React, { useContext, useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
// import { CSVLink } from "react-csv";
// import Button from "@mui/material/Button";
// import Swal from "sweetalert2";
// import Lottie from "lottie-react";
// import { useNavigate } from "react-router-dom";
// import userContext from "../../../components/UseContext/UseContext";
// import NoDataFound from "./No data found.json";

// const Export = () => {
//   const [returnCondition, setReturnCondition] = useState(false);
//   const ExportData = useContext(userContext);
//   const val = ExportData.exportProducts;
//   const navigate = useNavigate();

//   const excludeFields = [
//     "_id",
//     "numOfReviews",
//     "cadFile",
//     "productimage",
//     "value",
//     "__v",
//     "createdAt",
//     "isActive",
//     "category.admin",
//     "subcategoryname.category",
//     "subcategoryname.admin",
//     "admin",
//     "slug",
//     "descriptions",
//     "productimages",
//     "reviews",
//     "dealofday",
//     "dealExpiryDate",
//     "Productprice",
//     "productweight"
//   ];

//   const fieldMapping = {
//     "category.name": "category",
//     "subcategoryname.name": "subcategoryname"
//   };

//   const flattenObject = (obj, parentKey = '', res = {}) => {
//     for (let key in obj) {
//       if (!excludeFields.includes(key)) {
//         let propName = parentKey ? `${parentKey}.${key}` : key;
//         if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
//           flattenObject(obj[key], propName, res);
//         } else {
//           res[propName] = obj[key];
//         }
//       }
//     }
//     return res;
//   };

//   const replaceKeys = (obj, mapping) => {
//     const newObj = {};
//     for (let key in obj) {
//       const newKey = mapping[key] || key;
//       newObj[newKey] = obj[key];
//     }
//     return newObj;
//   };

//   let object = val.map((val1, index) => {
//     let newObj = replaceKeys(flattenObject(val1), fieldMapping);
//     newObj["S.No"] = index + 1; // Adding serial number
//     return newObj;
//   });

//   object = object.map((val) => {
//     delete val["subcategoryname.category"];
//     return val;
//   });

//   useEffect(() => {
//     setReturnCondition(object.length > 0);
//   }, [object]);

//   const replaceFunction = (data) => {
//     if (typeof data === "string") {
//       return data.replace(/(<([^>]+)>)/gi, "");
//     } else {
//       return data;
//     }
//   };

//   const handleDownload = () => {
//     Swal.fire({
//       icon: "success",
//       title: "Product Successfully Exported",
//       showConfirmButton: true,
//       timer: 32000,
//       text: "The products have been successfully exported.",
//     });
//   };

//   const handleCancel = () => {
//     navigate("/newproductsViewPage");
//   };

//   const fileName = object.length > 0 ? `${object[0]["category"]}.csv` : "export.csv";

//   return (
//     <div>
//       {returnCondition ? (
//         <div>
//           <h1>Export All Data</h1>
//           <div style={{ width: "100%", overflow: "auto", padding: "2%" }}>
//             <Table striped bordered hover style={{ width: "90%", overflow: "auto", border: "2px solid blue" }}>
//               <thead>
//                 <tr>
//                   {/* <th>S.No</th>
//                   {Object.keys(object[0]).map((key) => (
//                     <th key={key}>{fieldMapping[key] || key}</th>
//                   ))} */}
//                 </tr>
//               </thead>
//               <tbody>
//                 {object.map((data, index) => (
//                   <tr key={index}>
//                     <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
//                     {Object.keys(data).map((key) => (
//                       <td
//                         key={key}
//                         style={{ textAlign: "center", whiteSpace: "nowrap" }}
//                       >
//                         {replaceFunction(typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key])}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//           <div className="csv-button" style={{ marginTop: "20px" }}>
//             <CSVLink
//               data={object}
//               filename={fileName}
//               onClick={handleDownload}
//               style={{ textDecoration: "none" }}
//             >
//               <Button
//                 variant="contained"
//                 color="success"
//                 onClick={handleDownload}
//               >
//                 Download
//               </Button>
//             </CSVLink>
//             <Button
//               variant="contained"
//               color="error"
//               onClick={handleCancel}
//               style={{ marginLeft: "10px" }}
              
//             >
//               Cancel
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <div>
//           <Lottie
//             style={{ width: "100%", height: "60vh" }}
//             animationData={NoDataFound}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default Export;
