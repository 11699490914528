import React from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";

function BackArrow({ path }) {
  return (
    <Link to={path}>
      <FaArrowCircleLeft className="fs-4 my-2 text-dark" />
    </Link>
  );
}

export default BackArrow;
