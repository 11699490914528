import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/index";
import Form from "react-bootstrap/Form";
import DescriptionTypesField from "../../../components/DescriptionNameType/DescriptionTypesField";
import { useAuthHeaders } from "../../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function EditDescriptionNameTypesDetial() {
  const { _id } = useParams();
  const [newEditDescriptionNameType, setNewEditDescriptionNameType] =
    useState("");
  const navigate = useNavigate();
  // const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  // const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedDescriptionMethod, setSelectedDescriptionMethod] = useState(
    []
  );
  const [discriptionMethod, setDiscriptionMethod] = useState([]);
  const [conditionMethod, setConditionMethod] = useState([]);
  const [tags, setTags] = useState([]);
  const textValues = tags.map((item) => item.text);
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singledescriptiontype/${_id}`)
      .then((response) => {
        console.log(
          "response singledescriptiontype",
          response.data.descriptiontype
        );
        setNewEditDescriptionNameType(response?.data?.descriptiontype);
        setConditionMethod(response?.data?.descriptiontype.type);
        setTags(
          response?.data?.descriptiontype?.descriptionnametypes.map((tag) => ({
            id: tag,
            text: tag,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
    // axios
    //   .get(`${API_BASE_URL}/category/admin/getcategory`)
    //   .then((response) => {
    //     setCategories(response.data.categories);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching category data:", error);
    //   });
    axios
      .get(`${API_BASE_URL}/type/admin/gettypes`, { headers })
      .then((response) => {
        setDiscriptionMethod(response.data?.types);
        // console.log("response Discription Method :",response.data.types)
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    axios
      .put(
        `${API_BASE_URL}/admin/updatedescriptiontype/${_id}`,
        {
          // descriptionname: newEditDescriptionNameType,
          // category: selectedCategory,
          // subcategory: selectedSubCategory,
          // descriptionname: newEditDescriptionNameType,
          // type: selectedDescriptionMethod,
          descriptionnametypes: textValues,
        },
        {
          headers,
        }
      )
      .then((response) => {
        navigate("/viewDescriptionNameTypes");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  // const handleCategorySelect = (e) => {
  //   const selectedCategory = e.target.value;
  //   setSelectedCategory(selectedCategory);

  //   axios
  //     .get(`${API_BASE_URL}/category/subcategories/${selectedCategory}`)
  //     .then((response) => {
  //       setSubCategory(response.data.subcategories);
  //       // console.log("New subcategory :" , response.data.subcategories);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching subcategory data:", error);
  //     });
  // };

  return (
    <div className="p-3">
      <h2>Edit Description Name Type</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <div className="d-flex align-items-center my-2">
            <h6 className="m-0">Category :</h6>
            <p className="m-0 px-2">
              {newEditDescriptionNameType?.category?.name}
            </p>
          </div>

          <div className="d-flex align-items-center my-2">
            <h6 className="m-0">Sub Category :</h6>
            <p className="m-0 px-2">
              {newEditDescriptionNameType?.subcategory?.name}
            </p>
          </div>

          <div className="d-flex align-items-center my-2">
            <h6 className="m-0">Description Name Type :</h6>
            <p className="m-0 px-2">
              {newEditDescriptionNameType?.descriptionname?.descriptionName}
            </p>
          </div>

          <div className="d-flex align-items-center my-2">
            <h6 className="m-0">Type :</h6>
            <p className="m-0 px-2">{newEditDescriptionNameType?.type}</p>
          </div>

          {/* <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Category Name:
            </label>
            <br />
            <Form.Select
              id="category"
              aria-label="Default select example"
              className="my-2"
              required
              value={selectedCategory}
              onChange={handleCategorySelect}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div> */}

          {/* <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Sub Category Name:
            </label>
            <br />
            <Form.Select
              id="category"
              aria-label="Default select example"
              className="my-2"
              value={selectedSubCategory}
              required
              onChange={(e) => setSelectedSubCategory(e.target.value)}
            >
              <option value="">Select a Sub Category</option>
              {Array.isArray(subCategory) ? (
                subCategory.map((category) => (
                  // console.log("sebcategory test :", category)
                  <option key={category._id} value={category.name}>
                    {category.name}
                  </option>
                ))
              ) : (
                <option value="">Loading subcategories...</option>
              )}
            </Form.Select>
          </div> */}

          {/* <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Description Type Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={newEditDescriptionNameType}
              onChange={(e) => setNewEditDescriptionNameType(e.target.value)}
            />
          </div> */}

          {(conditionMethod === "Drop Down" ||
            conditionMethod === "Check Box") && (
            <>
              {/* <div className="col-md-4">
                <label htmlFor="name" className="fs-6">
                  Type:
                </label>
                <br />
                <Form.Select
                  id="subCategory"
                  aria-label="Default select example"
                  className="my-2"
                  value={selectedDescriptionMethod}
                  onChange={(e) => setSelectedDescriptionMethod(e.target.value)}
                >
                  <option value="">Select Description Method</option>
                  {Array.isArray(discriptionMethod) &&
                    discriptionMethod.map((method) => (
                      <option key={method._id} value={method.name}>
                        {method.name}
                      </option>
                    ))}
                </Form.Select>
              </div> */}

              <div className="col-md-4 my-4">
                <DescriptionTypesField setTags={setTags} tags={tags} />
              </div>
            </>
          )}
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/viewDescriptionNameTypes" />
        </div>
      </form>
    </div>
  );
}

export default EditDescriptionNameTypesDetial;
