import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Lottie from "lottie-react";
import Preloader from "../../Preloader/Preloader-Animation.json";


function EditCoupen() {
  const [data, setData] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   try{setLoading(true)
  //   axios
  //     .get(`${API_BASE_URL}/admin/getallcoupon`, { headers })
  //     .then((res) => {
  //       setData(res.data.Coupon);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //   }finally{setLoading(false)}
  // }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${API_BASE_URL}/admin/getallcoupon`, { headers });
        setData(res.data.Coupon);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const deleteCoupen = (coupenId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Coupen?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/admin/deletcoupon/${coupenId}`, { headers })
          .then(() => {
            setData((prevData) =>
              prevData.filter((item) => item?._id !== coupenId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });

        Swal.fire({
          title: "Deleted!",
          text: "Your Coupen has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleCoupenStatus = (coupenId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/admin/coupon/status/${coupenId}`,
        {
          isActive: !isActive,
        },
        {
          headers,
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item?._id === coupenId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="m-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Coupens</h2>
        <Link to="/addCoupen" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <thead>
          <tr>
            <th>Coupen Code</th>
            <th>Coupon Type</th>
            <th>Coupon Amount</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (<>
          
          {data.map((item, index) => (
            // console.log("item", item)
            <tr key={index}>
              <td>{item?.Couponcode}</td>
              <td>{item?.Coupontype?.coupontype}</td>
              <td className="text-center">{item?.Couponamount}</td>
              <td>{item?.expiresAt}</td>
              <td>
                <Link to={`/detialCoupen/${item?._id}`}>
                  <button className="mx-1">
                    <AiFillEye className="m-2 fs-6" />
                  </button>
                </Link>

                <button className="mx-1" onClick={() => deleteCoupen(item?._id)}>
                  <AiFillDelete className="m-2 fs-6" />
                </button>

                <Link to={`/updateCoupen/${item?._id}`}>
                  <button className="mx-1">
                    <FiEdit className="m-2 fs-6" />
                  </button>
                </Link>

                <button
                  className="mx-1"
                  onClick={() => toggleCoupenStatus(item?._id, item?.isActive)}
                >
                  {item.isActive ? (
                    <BsToggleOn className="m-2 fs-6" />
                  ) : (
                    <BsToggleOff className="m-2 fs-6" />
                  )}
                </button>
              </td>
            </tr>
          ))}
          </>)}
        </tbody>
      </table>
    </div>
  );
}

export default EditCoupen;
