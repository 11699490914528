import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Lottie from "lottie-react";
import Preloader from "../../../../Preloader/Preloader-Animation.json";


function EditFiltersTypes() {
  const [filterTypes, setFilterTypes] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/admin/getallfiltertypes`);
        setFilterTypes(response?.data?.filterstypes);
      } catch (error) {
        console.error("Error fetching subcategory data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Filters Types",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/admin/deletefiltertype/${categoryId}`, {
            headers,
          })
          .then(() => {
            setFilterTypes((prevData) =>
              prevData.filter((item) => item?._id !== categoryId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
        Swal.fire({
          title: "Deleted!",
          text: "Filters Types file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/admin/filtertypestatus/${categoryId}`,
        {
          isActive: !isActive, // Toggle the active status
        },
        {
          headers,
        }
      )
      .then(() => {
        // Update the local state to reflect the change
        setFilterTypes((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Filters Types</h2>
        <Link to="/filtersTypes" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <thead>
          <tr>
            <th>Filters Types</th>
            <th>Filters</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
        {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (<>
          {filterTypes && filterTypes.length > 0 ? (
            filterTypes.map((item, index) => (
              // console.log("item in filters sub", item)
              <tr key={index}>
                <td>
                  {item?.filter_Types.map((filterType, i) => (
                    <td key={i}>{filterType}</td>
                  ))}
                </td>

                <td>{item?.filter?.name}</td>

                <td>{item?.category?.name}</td>

                <td>
                  <Link to={`/viewFiltersTypes/${item?._id}`}>
                    <button className="mx-1">
                      <AiFillEye className="m-2 fs-6" />
                    </button>
                  </Link>
                  <button
                    className="mx-1"
                    onClick={() => deleteCategory(item?._id)}
                  >
                    <AiFillDelete className="m-2 fs-6" />
                  </button>
                  <Link to={`/updateFiltersTypes/${item?._id}`}>
                    <button className="mx-1">
                      <FiEdit className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() =>
                      toggleCategoryStatus(item._id, item.isActive)
                    }
                  >
                    {item.isActive ? (
                      <BsToggleOn className="m-2 fs-6" />
                    ) : (
                      <BsToggleOff className="m-2 fs-6" />
                    )}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Filter Types Found</td>
            </tr>
          )}
          </>)}
        </tbody>
      </table>
    </div>
  );
}

export default EditFiltersTypes;
