import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../utils";
import { useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";

function DetialShipping() {
  const { _id } = useParams();
  const headers = useAuthHeaders();
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/shipping/getsingleshipping/${_id}`, {
        headers,
      })
      .then((response) => {
        // console.log("response", response.data.Shipping);
        setData(response?.data?.Shipping);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Shipping Details</h2>
      <h6>Zone:</h6>
      <p>{data?.zone?.zonename}</p>
      <h6>Zone Region</h6>
      <p>{data?.zoneregion?.regionname}</p>
      <h6>District Name</h6>
      <p>{data?.districtname}</p>
      <h6>Pin Code</h6>
      <p>{data?.pincode}</p>
      <h6>Method Name</h6>
      <p>{data?.shippingmethod?.Methodname}</p>
      {data?.shippingmethod?.Methodname === "Flat rate" && (
        <>
          <h6>Method Types</h6>
          <p>Weight : {data?.weight || null}</p>
          <p>Cost : {data?.cost || null}</p>
        </>
      )}
    </div>
  );
}

export default DetialShipping;
