import React, { createContext, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const adminId = localStorage.getItem("adminId");
  
  console.log(
    "admin Id is the ",adminId
  )
  const headers = {
    Authorization: `Bearer ${adminId}`,
  };
  
  return (
    <AuthContext.Provider value={headers}>{children}</AuthContext.Provider>
  );
};

export const useAuthHeaders = () => {
  return useContext(AuthContext);
};