import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../utils";
import axios from "axios";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function EditShipping() {
  const headers = useAuthHeaders();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/shipping/getallshipping`, { headers })
      .then((response) => {
        setData(response?.data?.Shipping);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const deleteShipping = (shippingId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Shipping Name Type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${API_BASE_URL}/admin/shipping/deleteshipping/${shippingId}`,
            { headers }
          )
          .then(() => {
            setData((prevData) =>
              prevData.filter((item) => item?._id !== shippingId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
        Swal.fire({
          title: "Deleted!",
          text: "Shipping has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleShippingStatus = (shippingId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/admin/shipping/status/${shippingId}`,
        {
          isActive: !isActive,
        },
        {
          headers,
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item._id === shippingId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Edit Shipping</h2>
        <Link to="/addShipping" className="btn btn-primary">
          Add New
        </Link>
      </div>

      <table className="categorys-table" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <thead>
          <tr>
            <th>Zone</th>
            <th>Zone Region</th>
            <th>District Name</th>
            <th>Pin Code </th>
            <th>Method Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index} style={{ overflowX: "auto" }}>
                <td>{item?.zone?.zonename}</td>
                <td>{item?.zoneregion?.regionname}</td>
                <td>{item?.districtname}</td>
                <td>{item?.pincode}</td>
                <td className="d-flex align-items-center">
                  {item?.shippingmethod?.Methodname}
                  {item?.shippingmethod?.Methodname === "Flat rate" && (
                    <>
                      <DropdownButton
                        id="dropdown-item-button"
                        title=""
                        className="mx-2"
                      >
                        <Dropdown.Item as="button">
                          <p className="m-0">Weigth : {item?.weight || null}</p>
                          <p className="m-0">
                            Pincode : {item?.pincode || null}
                          </p>
                        </Dropdown.Item>
                      </DropdownButton>
                    </>
                  )}
                </td>

                <td>
                  <Link to={`/detialShipping/${item._id}`}>
                    <button className="mx-1">
                      <AiFillEye className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() => deleteShipping(item?._id)}
                  >
                    <AiFillDelete className="m-2 fs-6" />
                  </button>
                  <Link to={`/updatedShipping/${item._id}`}>
                    <button className="mx-1">
                      <FiEdit className="m-2 fs-6" />
                    </button>
                  </Link>

                  <button
                    className="mx-1"
                    onClick={() =>
                      toggleShippingStatus(item._id, item.isActive)
                    }
                  >
                    {item.isActive ? (
                      <BsToggleOn className="m-2 fs-6" />
                    ) : (
                      <BsToggleOff className="m-2 fs-6" />
                    )}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Shipping found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EditShipping;
