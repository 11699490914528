import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import Form from "react-bootstrap/Form";
import { WithContext as ReactTags } from "react-tag-input";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../../components/MinorComponents/CancelButton";

function UpdateFiltersTypes() {
  const { _id } = useParams();
  // const [single, setSingle] = useState("");
  // const [filter, setFilter] = useState([]);
  // const [selectedFilter, setSelectedFilter] = useState("");
  // const [categories, setCategories] = useState([]);
  // const [selectedCategories, setSelectedCategories] = useState("");
  // const [currentFilter, setCurrentFilter] = useState([]);
  const navigate = useNavigate();
  const [filterTypes, setFilterTypes] = useState("");
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefiltertype/${_id}`, {
        headers,
      })
      .then((response) => {
        // setSingle(response.data.filtertype.filter_Types);
        // setSelectedCategories(response.data.filtertype.category);
        // setCurrentFilter(response.data.filtertype.filter);
        setFilterTypes(response?.data?.filtertype);
        setTags(
          response?.data?.filtertype?.filter_Types.map((tag) => ({
            id: tag,
            text: tag,
          }))
        );
        // console.log("singlefilter", response.data.filtertype);
      })
      .catch((error) => {
        console.error(error);
      });

    // axios
    //   .get(`${API_BASE_URL}/category/admin/getcategory`)
    //   .then((response) => {
    //     setCategories(response.data.categories);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching category data:", error);
    //   });
  }, []);

  // useEffect(() => {
  //   if (selectedCategories) {
  //     axios
  //       .get(`${API_BASE_URL}/admin/getfilterbycategory/${selectedCategories}`)
  //       .then((response) => {
  //         setFilter(response.data.Filter);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching filter data:", error);
  //       });
  //   }
  // }, [selectedCategories]);

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      // filter: selectedFilter || currentFilter,
      filter_Types: textValues,
      // category: selectedCategories
    };
    axios
      .put(`${API_BASE_URL}/admin/updatefiltertype/${_id}`, data, {
        headers,
      })
      .then((response) => {
        navigate("/editFiltersTypes");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Filter Types</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          {/* <div className="col-lg-4">
            <label htmlFor="name" className="fs-6">
              Select Categorie:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategories}
              required
              onChange={(e) => setSelectedCategories(e.target.value)}
            >
              <option value="">Select a Filter</option>
              {categories.map((category) => (
                // console.log(category,"category")
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-lg-4">
            <label htmlFor="name" className="fs-6">
              Select Filter:
            </label>
            <br />

            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedFilter}
              required
              onChange={(e) => setSelectedFilter(e.target.value)} 
            >
              <option value="">Select a Filter</option>
              {filter.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div> */}

          <div className="d-flex align-items-center my-2">
            <h6 className="m-0">Categorie :</h6>
            <p className="m-0 px-2">{filterTypes?.category?.name}</p>
          </div>

          <div className="d-flex align-items-center my-2">
            <h6 className="m-0">Categorie :</h6>
            <p className="m-0 px-2">{filterTypes?.filter?.name}</p>
          </div>

          <div className="col-lg-4 border border-dark px-3 py-2 rounded-1">
            <label htmlFor="name" className="fs-6 my-2">
              Change Filter Types:
            </label>
            <br />
            <ReactTags
              tags={tags}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
            />
          </div>
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/editFiltersTypes " />
        </div>
      </form>
    </div>
  );
}

export default UpdateFiltersTypes;
