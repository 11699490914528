// import React, { useEffect, useState, useCallback } from "react";
// import "../ProductCategory/productCategory.scss";
// import Form from "react-bootstrap/Form";
// import axios from "axios";
// import { Toast, ToastContainer } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { API_BASE_URL } from "../../../utils/index";
// import Swal from "sweetalert2";
// import { useDropzone } from "react-dropzone";
// import { FaTimes } from "react-icons/fa";
// import "./OfferPost.scss"; // Ensure you have the necessary styles

// function Blogs() {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [images, setImages] = useState([]);
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState("");
//   const navigate = useNavigate();
//   const adminId = localStorage.getItem("adminId");

//   // Fetch categories on component mount
//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/admin/getcategory`)
//       .then((response) => {
//         setCategories(response.data.categories);
//       })
//       .catch((error) => {
//         console.error("Error fetching category data:", error);
//       });
//   }, []);

//   // Handle file drops
//   const onDrop = useCallback((acceptedFiles) => {
//     setImages((prevImages) => [...prevImages, ...acceptedFiles]);
//   }, []);

//   // Setup dropzone
//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   // Form submission handler
//   const handleFormSubmit = (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append("category", selectedCategory);
//     images.forEach((image) => {
//       formData.append("offerposter", image);
//     });

//     const headers = {
//       Authorization: `Bearer ${adminId}`,
//     };

//     axios
//       .post(`${API_BASE_URL}/blogs`, formData, { headers })
//       .then((response) => {
//         Swal.fire("Success!", "Your Poster has been added successfully", "success");
//         setSelectedCategory("");
//         setImages([]);
//         setShowToast(false);
//         navigate("/underSubProductCategory");
//       })
//       .catch((error) => {
//         const errorMessage = error?.response?.data?.message || "An error occurred. Please try again.";
//         setToastMessage(errorMessage);
//         setShowToast(true);
//       });
//   };

//   // Remove an image from the preview list
//   const removeImage = (index) => {
//     setImages((prevImages) => prevImages.filter((_, i) => i !== index));
//   };

//   return (
//     <div className="p-4">
//       <h2>Blogs</h2>
//       <form
//         className="category"
//         onSubmit={handleFormSubmit}
//         style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
//       >
//         <div className="row category-field">
//           <div className="col-md-6">
//             <label htmlFor="category" className="fs-6">Category Name:</label>
//             <Form.Select
//               id="category"
//               aria-label="Select Category"
//               className="my-2"
//               value={selectedCategory}
//               onChange={(e) => setSelectedCategory(e.target.value)}
//             >
//               <option value="">Select Category</option>
//               {categories.map((category) => (
//                 <option key={category._id} value={category.name}>
//                   {category.name}
//                 </option>
//               ))}
//             </Form.Select>
//           </div>
//           <div className="col-md-6">
//             <label htmlFor="category" className="fs-6">Title:</label>
//             <Form.Select
//               id="category"
//               aria-label="Select Category"
//               className="my-2"
//               value={selectedCategory}
//               onChange={(e) => setSelectedCategory(e.target.value)}
//             >
//               <option value="">Select Category</option>
//               {categories.map((category) => (
//                 <option key={category._id} value={category.name}>
//                   {category.name}
//                 </option>
//               ))}
//             </Form.Select>
//           </div>
//           <div className="col-md-6">
//             <label htmlFor="category" className="fs-6">Content:</label>
//             <Form.Select
//               id="category"
//               aria-label="Select Category"
//               className="my-2"
//               value={selectedCategory}
//               onChange={(e) => setSelectedCategory(e.target.value)}
//             >
//               <option value="">Select Category</option>
//               {categories.map((category) => (
//                 <option key={category._id} value={category.name}>
//                   {category.name}
//                 </option>
//               ))}
//             </Form.Select>
//           </div>
//           <div className="col-md-6">
//             <label htmlFor="category" className="fs-6">Author:</label>
//             <Form.Select
//               id="category"
//               aria-label="Select Category"
//               className="my-2"
//               value={selectedCategory}
//               onChange={(e) => setSelectedCategory(e.target.value)}
//             >
//               <option value="">Select Category</option>
//               {categories.map((category) => (
//                 <option key={category._id} value={category.name}>
//                   {category.name}
//                 </option>
//               ))}
//             </Form.Select>
//           </div>
//           <div className="col-md-6">
//             <label htmlFor="image" className="fs-6">Upload Images:</label>
//             <div
//               {...getRootProps({ className: "dropzone" })}
//               className="dropzone my-2"
//             >
//               <input {...getInputProps()} id="image" />
//               <p>Drag & drop some files here, or click to select files</p>
//             </div>
//             <div className="image-preview">
//               {images.map((image, index) => (
//                 <div key={index} className="image-container">
//                   <img
//                     src={URL.createObjectURL(image)}
//                     alt={`preview-${index}`}
//                     className="preview-image"
//                     onLoad={() => URL.revokeObjectURL(image)} // Clean up object URLs
//                   />
//                   <button
//                     type="button"
//                     className="remove-button"
//                     onClick={() => removeImage(index)}
//                   >
//                     <FaTimes />
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="buttons">
//             <button type="submit" className="btn btn-primary">
//               Save
//             </button>
//             <button
//               type="button"
//               onClick={() => navigate(-1)}
//               className="btn btn-danger"
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       </form>
//       <ToastContainer position="top-end" className="p-3">
//         <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
//           <Toast.Body>{toastMessage}</Toast.Body>
//         </Toast>
//       </ToastContainer>
//     </div>
//   );
// }

// export default Blogs;
import React, { useEffect, useState, useCallback } from "react";
import "../ProductCategory/productCategory.scss";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Toast, ToastContainer } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import "./OfferPost.scss"; // Ensure you have the necessary styles

function Blogs() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("Bestindiakart");
  const [images, setImages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();
  const adminId = localStorage.getItem("adminId");

  // Fetch categories on component mount
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  // Handle file drops
  const onDrop = useCallback((acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);

  // Setup dropzone
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Form submission handler
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("category", selectedCategory);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("author", author);
    images.forEach((image) => {
      formData.append("blogimage", image);
    });

    const headers = {
      Authorization: `Bearer ${adminId}`,
    };

    axios
      .post(`${API_BASE_URL}/blogs`, formData, { headers })
      .then((response) => {
        Swal.fire("Success!", "Your blog post has been added successfully", "success");
        setSelectedCategory("");
        setTitle("");
        setContent("");
        setAuthor("");
        setImages([]);
        setShowToast(false);
        navigate("/editblog");
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message || "An error occurred. Please try again.";
        setToastMessage(errorMessage);
        setShowToast(true);
      });
  };

  // Remove an image from the preview list
  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <div className="p-4">
      <h2>Blogs</h2>
      <form
        className="category"
        onSubmit={handleFormSubmit}
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="category" className="fs-6">Category Name:</label>
            <Form.Select
              id="category"
              aria-label="Select Category"
              className="my-2"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="col-md-6">
            <label htmlFor="title" className="fs-6">Title:</label>
            <Form.Control
              id="title"
              type="text"
              placeholder="Enter Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="my-2"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="content" className="fs-6">Content:</label>
            <Form.Control
              id="content"
              as="textarea"
              rows={3}
              placeholder="Enter Content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="my-2"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="author" className="fs-6">Author:</label>
            <Form.Control
              id="author"
              type="text"
              placeholder="Enter Author"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              className="my-2"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="image" className="fs-6">Upload Images:</label>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="dropzone my-2"
            >
              <input {...getInputProps()} id="image" />
              <p>Drag & drop some files here, or click to select files</p>
            </div>
            <div className="image-preview">
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`preview-${index}`}
                    className="preview-image"
                    onLoad={() => URL.revokeObjectURL(image)} // Clean up object URLs
                  />
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => removeImage(index)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="buttons">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <ToastContainer position="top-end" className="p-3">
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default Blogs;
