import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../components/MinorComponents/CancelButton";
function AddCoupen() {
  const headers = useAuthHeaders();
  const navicate = useNavigate();
  const [coupenName, setCoupenName] = useState("");
  const [coupenType, setCoupenType] = useState([]);
  const [coupenAmount, setCoupenAmount] = useState("");
  const [couponTillDate, setCouponTillDate] = useState("");
  const [selectType, setSelectType] = useState("");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallcoupontypes`, { headers })
      .then((res) => {
        setCoupenType(res?.data?.CouponTypes);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const handleCreateCoupen = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/admin/newcoupon`,
        { 
          couponcode: coupenName,
          coupontype: selectType,
          couponamount: coupenAmount,
          expiresAt: couponTillDate,
        },
        { headers }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Coupon has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navicate("/editCoupen");
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.response?.data?.error,
        });
      });
  };

  return (
    <div className="m-4">
      <h2>Create Coupon</h2>
      <form action="" className="category" onSubmit={handleCreateCoupen} style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Create Coupon Name :
            </label>
            <br />
            <input
              type="text"
              id="name"
              required
              onChange={(e) => setCoupenName(e.target?.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Select a Type :
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectType}
              onChange={(e) => setSelectType(e.target?.value)}
              required
            >
              <option value="">Select a Type</option>
              {Array.isArray(coupenType) ? (
                coupenType.map((coupenType) => (
                  <option key={coupenType?._id} value={coupenType?.coupontype}>
                    {coupenType?.coupontype}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </Form.Select>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Coupon Amount :
            </label>
            <br />
            <input
              type="text"
              id="name"
              onChange={(e) => setCoupenAmount(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Coupon Till Date :
            </label>
            <br />
            <input
              type="date"
              id="name"
              onChange={(e) => setCouponTillDate(e.target.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <button type="submit" value="send" style={{ backgroundColor: "#0443C0", color: "white" }}>
            Save
          </button>
          <CancelButton path="/editCoupen" />
        </div>
      </form>
    </div>
  );
}

export default AddCoupen;
