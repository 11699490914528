import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../utils/index";
import BackArrow from "../../../../components/MinorComponents/BackArrow";

function SubsubCategorysDetial() {
  const { _id } = useParams();
  const [subCategory, setSubCategory] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/subcategory/admin/singlesubcategory/${_id}`)
      .then((response) => {
        setSubCategory(response.data?.subcategory);
        console.log(response.data.subcategory, "singlesubcategory");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <BackArrow path="/subCategory" />
      <h2>subCategory Details</h2>
      <h6>SubCategory Name:</h6>
      <p>{subCategory.name}</p>
      <h6>Category Name:</h6>
      <p>{subCategory.category?.name}</p>
      <h6>createdAt</h6>
      <p>{subCategory?.createdAt}</p>
    </div>
  );
}

export default SubsubCategorysDetial;
