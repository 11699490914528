// import React, { useState, useEffect } from "react";
// import { FaBell, FaTimes, FaCheck } from "react-icons/fa";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../Token";
// import './Notification.css';
// import { Col, Row } from "react-bootstrap";

// function Notification() {
//   const [notifications, setNotifications] = useState({ enquiries: [], orders: [], users: [] });
//   const [unreadCount, setUnreadCount] = useState(0);
//   const [isVisible, setIsVisible] = useState(false);
//   const headers = useAuthHeaders();

//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const logsRes = await axios.get(`${API_BASE_URL}/admin/logs`, { headers });
//         const now = new Date();
//         const newNotifications = { enquiries: [], orders: [], users: [] };

//         if (logsRes.data.logs && Array.isArray(logsRes.data.logs)) {
//           logsRes.data.logs.forEach(notification => {
//             const notificationDate = new Date(notification.timestamp || Date.now());
//             if ((now - notificationDate) < 24 * 60 * 60 * 1000) {
//               const type = notification.entity.toLowerCase();
//               const message = `New ${notification.entity} received with ${notification.metadata.orderId || ''}`;

//               if (type === 'enquiry') {
//                 newNotifications.enquiries.push({
//                   id: notification._id,
//                   message: `New ${notification.entity} received with ${notification.metadata.email}`,
//                   timestamp: notificationDate,
//                   read: notification.notificationRead,
//                 });
//               } else if (type === 'order') {
//                 newNotifications.orders.push({
//                   id: notification._id,
//                   message,
//                   timestamp: notificationDate,
//                   read: notification.notificationRead,
//                 });
//               } else if (type === 'user') {
//                 newNotifications.users.push({
//                   id: notification._id,
//                   message: `New ${notification.entity} login ${notification.metadata.email}`,
//                   timestamp: notificationDate,
//                   read: notification.notificationRead,
//                 });
//               }
//             }
//           });

//           // Sort notifications in descending order by timestamp
//           Object.keys(newNotifications).forEach(type => {
//             newNotifications[type].sort((a, b) => b.timestamp - a.timestamp);
//           });
//         }

//         setNotifications(newNotifications);
//         const totalUnread = Object.values(newNotifications).flat().filter(n => !n.read).length;
//         setUnreadCount(totalUnread);
//       } catch (error) {
//         console.error("Error fetching notifications:", error);
//       }
//     };

//     fetchNotifications();
//   }, [headers]);

//   useEffect(() => {
//     const totalUnread = Object.values(notifications).flat().filter(n => !n.read).length;
//     setUnreadCount(totalUnread);
//   }, [notifications]);

//   const handleMarkAsRead = async (id) => {
//     try {
//       await axios.put(`${API_BASE_URL}/admin/logs/${id}`, {}, { headers });
//       const updatedNotifications = { ...notifications };

//       Object.keys(updatedNotifications).forEach(type => {
//         updatedNotifications[type] = updatedNotifications[type].map(notification =>
//           notification.id === id ? { ...notification, read: true } : notification
//         );
//       });

//       setNotifications(updatedNotifications);
//       const totalUnread = Object.values(updatedNotifications).flat().filter(n => !n.read).length;
//       setUnreadCount(totalUnread);
//     } catch (error) {
//       console.error("Error marking notification as read:", error);
//     }
//   };

//   const handleMarkAllAsRead = async () => {
//     try {
//       await axios.put(`${API_BASE_URL}/admin/logs/markallread`, {}, { headers });
//       const updatedNotifications = {
//         enquiries: notifications.enquiries.map(notification => ({ ...notification, read: true })),
//         orders: notifications.orders.map(notification => ({ ...notification, read: true })),
//         users: notifications.users.map(notification => ({ ...notification, read: true })),
//       };

//       setNotifications(updatedNotifications);
//       setUnreadCount(0);
//       setIsVisible(false);
//     } catch (error) {
//       console.error("Error marking all notifications as read:", error);
//     }
//   };

//   const getTimeAgo = (timestamp) => {
//     const now = new Date();
//     const then = new Date(timestamp);
//     const diffInSeconds = Math.floor((now - then) / 1000);

//     if (diffInSeconds < 60) {
//       return 'just now';
//     }

//     const minutes = Math.floor(diffInSeconds / 60);
//     if (minutes < 60) {
//       return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
//     }

//     const hours = Math.floor(minutes / 60);
//     if (hours < 24) {
//       return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
//     }

//     return 'more than a day ago';
//   };

//   const handleItemClick = (id) => {
//     handleMarkAsRead(id);
//   };

//   return (
//     <div className="notification-container">
//       <div className="bell-icon" onClick={() => setIsVisible(!isVisible)}>
//         <FaBell style={{ fontSize: "20px" }} className="text-black mt-2" />
//         {unreadCount > 0 && (
//           <span className="badge">{unreadCount}</span>
//         )}
//       </div>
//       {isVisible && (
//         <div id="notifications-list" className={`notifications-list ${isVisible ? 'show' : ''}`}>
//           <div className="header">
//             Notifications ({unreadCount})
//             <FaTimes className="clear-icon" onClick={() => setIsVisible(false)} />
//           </div>

//           <button onClick={handleMarkAllAsRead} className="mark-all-read-btn border border-0 bg-white ms-2">
//             Mark all as read
//           </button>

//           <div className="notifications-content">
//             {Object.keys(notifications).map(type => (
//               <div key={type} className="notification-group">
//                 {notifications[type].length > 0 ? (
//                   notifications[type].map(notification => (
//                     <div className="notification-item2" key={notification.id}>
//                       <Row
//                         className={`notification-item ${notification.read ? 'read' : 'unread'}`}
//                         onClick={() => handleItemClick(notification.id)}
//                       >
//                         <Col md={8} lg={10}className="notification-icon">{notification.message}</Col>
//                         <Col md={4} lg={2} className="notification-content">
//                           {notification.read && <FaCheck className="read-icon" />}
//                         </Col>
//                         <Col className="timestamp ms-3 ">{getTimeAgo(notification.timestamp)}</Col>
//                       </Row>
//                     </div>
//                   ))
//                 ) : (
//                   <div>No new notifications</div>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Notification;













// import React, { useState, useEffect } from "react";
// import { FaBell, FaTimes, FaCheck } from "react-icons/fa";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";

// import './Notification.css';
// import { Col, Row } from "react-bootstrap";
// import { FaCheckDouble } from "react-icons/fa6";
// import { useAuthHeaders } from "../../Token";
// function Notification() {
//   const [notifications, setNotifications] = useState({ enquiries: [], orders: [], users: [] });
//   const [unreadCount, setUnreadCount] = useState(0);
//   const [isVisible, setIsVisible] = useState(false);
//   const headers = useAuthHeaders();
//   console.log(headers,"SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS")
//   const adminId = localStorage.getItem("adminId");
// console.log(headers,)
//   useEffect(() => {
//     const fetchNotifications = async () => {
//       try {
//         const headers = {
//           Authorization: `Bearer ${adminId}`,
//           "Content-Type": "application/json",
//         };
//         const logsRes = await axios.get(`${API_BASE_URL}/admin/logs`, { headers });
//         const now = new Date();
//         const newNotifications = { enquiries: [], orders: [], users: [] };

//         if (logsRes.data.logs && Array.isArray(logsRes.data.logs)) {
//           logsRes.data.logs.forEach(notification => {
//             const notificationDate = new Date(notification.timestamp || Date.now());
//             if ((now - notificationDate) < 24 * 60 * 60 * 1000) {
//               const type = notification.entity.toLowerCase();
//               const message = `New ${notification.entity} received with ${notification.metadata.orderId || ''}`;

//               if (type === 'enquiry') {
//                 newNotifications.enquiries.push({
//                   id: notification._id,
//                   message: `New ${notification.entity} received with ${notification.metadata.email}`,
//                   timestamp: notificationDate,
//                   read: notification.notificationRead,
//                 });
//               } else if (type === 'order') {
//                 newNotifications.orders.push({
//                   id: notification._id,
//                   message,
//                   timestamp: notificationDate,
//                   read: notification.notificationRead,
//                 });
//               } else if (type === 'user') {
//                 newNotifications.users.push({
//                   id: notification._id,
//                   message: `New ${notification.entity} login ${notification.metadata.email}`,
//                   timestamp: notificationDate,
//                   read: notification.notificationRead,
//                 });
//               }
//             }
//           });

//           // Sort notifications in descending order by timestamp
//           Object.keys(newNotifications).forEach(type => {
//             newNotifications[type].sort((a, b) => b.timestamp - a.timestamp);
//           });
//         }

//         setNotifications(newNotifications);
//         const totalUnread = Object.values(newNotifications).flat().filter(n => !n.read).length;
//         setUnreadCount(totalUnread);
//       } catch (error) {
//         console.error("Error fetching notifications:", error);
//       }
//     };

//     fetchNotifications();
//   }, [headers]);

//   useEffect(() => {
//     const totalUnread = Object.values(notifications).flat().filter(n => !n.read).length;
//     setUnreadCount(totalUnread);
//   }, [notifications]);

//   const handleMarkAsRead = async (id) => {
//     try {
//       await axios.put(`${API_BASE_URL}/admin/logs/${id}`, {}, { headers });
//       const updatedNotifications = { ...notifications };

//       Object.keys(updatedNotifications).forEach(type => {
//         updatedNotifications[type] = updatedNotifications[type].map(notification =>
//           notification.id === id ? { ...notification, read: true } : notification
//         );
//       });

//       setNotifications(updatedNotifications);
//       const totalUnread = Object.values(updatedNotifications).flat().filter(n => !n.read).length;
//       setUnreadCount(totalUnread);
//     } catch (error) {
//       console.error("Error marking notification as read:", error);
//     }
//   };

//   const handleMarkAllAsRead = async () => {
//     try {
//       await axios.put(`${API_BASE_URL}/admin/logs/markallread`, {}, { headers });
//       const updatedNotifications = {
//         enquiries: notifications.enquiries.map(notification => ({ ...notification, read: true })),
//         orders: notifications.orders.map(notification => ({ ...notification, read: true })),
//         users: notifications.users.map(notification => ({ ...notification, read: true })),
//       };

//       setNotifications(updatedNotifications);
//       setUnreadCount(0);
//       setIsVisible(false);
//     } catch (error) {
//       console.error("Error marking all notifications as read:", error);
//     }
//   };

//   const getTimeAgo = (timestamp) => {
//     const now = new Date();
//     const then = new Date(timestamp);
//     const diffInSeconds = Math.floor((now - then) / 1000);

//     if (diffInSeconds < 60) {
//       return 'just now';
//     }

//     const minutes = Math.floor(diffInSeconds / 60);
//     if (minutes < 60) {
//       return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
//     }

//     const hours = Math.floor(minutes / 60);
//     if (hours < 24) {
//       return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
//     }

//     return 'more than a day ago';
//   };

//   const handleItemClick = (id) => {
//     handleMarkAsRead(id);
//   };

//   const hasNotifications = Object.values(notifications).some(type => type.length > 0);

//   return (
//     <div className="notification-container">
//       <div className="bell-icon" onClick={() => setIsVisible(!isVisible)}>
//         <FaBell style={{ fontSize: "20px" }} className="text-black mt-2" />
//         {unreadCount > 0 && (
//           <span className="badge">{unreadCount}</span>
//         )}
//       </div>
//       {isVisible && (
//         <div id="notifications-list" className={`notifications-list ${isVisible ? 'show' : ''}`}>
//           <div className="header">

//             <div className="d-flex  justify-content-between">

//             Notifications ({unreadCount})
//             <div>

//             <FaTimes className="clear-icon " onClick={() => setIsVisible(false)} />
//             </div>
//             </div>
//             <button onClick={handleMarkAllAsRead} className="mark-all-read-btn border border-0 text-primary bg-white fw-bold" style={{fontSize:"12px"}}>
//             Mark all as read <FaCheckDouble />

//           </button>
//           </div>

          

//           <div className="notifications-content">
//             {hasNotifications ? (
//               Object.keys(notifications).map(type => (
//                 <div key={type} className="notification-group">
//                   {notifications[type].map(notification => (
//                     <div className="notification-item2" key={notification.id}>
//                       <Row
//                         className={`notification-item ${notification.read ? 'read' : 'unread'}`}
//                         onClick={() => handleItemClick(notification.id)}
//                       >
//                         <Col sm={10} md={10} lg={10} className="notification-icon">{notification.message}</Col>
//                         <Col sm={2} md={2} lg={2} className="notification-content">
//                           {notification.read && <FaCheck className="read-icon" />}
//                         </Col>
//                         <Col className="timestamp ">{getTimeAgo(notification.timestamp)}</Col>
//                       </Row>
//                     </div>
//                   ))}
//                 </div>
//               ))
//             ) : (
//               <div>No new notifications</div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Notification;
import React, { useState, useEffect, useRef } from "react";
import { FaBell, FaTimes, FaCheck } from "react-icons/fa";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";

import './Notification.css';
import { Col, Row } from "react-bootstrap";
import { FaCheckDouble } from "react-icons/fa6";
import { useAuthHeaders } from "../../Token";

function Notification() {
  const [notifications, setNotifications] = useState({ enquiries: [], orders: [], users: [] });
  const [unreadCount, setUnreadCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);
  const notificationRef = useRef(null);
  const headers = useAuthHeaders();
  const adminId = localStorage.getItem("adminId");

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${adminId}`,
          "Content-Type": "application/json",
        };
        const logsRes = await axios.get(`${API_BASE_URL}/admin/logs`, { headers });
        const now = new Date();
        const newNotifications = { enquiries: [], orders: [], users: [] };

        if (logsRes.data.logs && Array.isArray(logsRes.data.logs)) {
          logsRes.data.logs.forEach(notification => {
            const notificationDate = new Date(notification.timestamp || Date.now());
            if ((now - notificationDate) < 24 * 60 * 60 * 1000) {
              const type = notification.entity.toLowerCase();
              const message = `New ${notification.entity} received with ${notification.metadata.orderId || ''}`;

              if (type === 'enquiry') {
                newNotifications.enquiries.push({
                  id: notification._id,
                  message: `New ${notification.entity} received with ${notification.metadata.email}`,
                  timestamp: notificationDate,
                  read: notification.notificationRead,
                });
              } else if (type === 'order') {
                newNotifications.orders.push({
                  id: notification._id,
                  message,
                  timestamp: notificationDate,
                  read: notification.notificationRead,
                });
              } else if (type === 'user') {
                newNotifications.users.push({
                  id: notification._id,
                  message: `New ${notification.entity} login ${notification.metadata.email}`,
                  timestamp: notificationDate,
                  read: notification.notificationRead,
                });
              }
            }
          });

          // Sort notifications in descending order by timestamp
          Object.keys(newNotifications).forEach(type => {
            newNotifications[type].sort((a, b) => b.timestamp - a.timestamp);
          });
        }

        setNotifications(newNotifications);
        const totalUnread = Object.values(newNotifications).flat().filter(n => !n.read).length;
        setUnreadCount(totalUnread);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [headers]);

  useEffect(() => {
    const totalUnread = Object.values(notifications).flat().filter(n => !n.read).length;
    setUnreadCount(totalUnread);
  }, [notifications]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleMarkAsRead = async (id) => {
    try {
      await axios.put(`${API_BASE_URL}/admin/logs/${id}`, {}, { headers });
      const updatedNotifications = { ...notifications };

      Object.keys(updatedNotifications).forEach(type => {
        updatedNotifications[type] = updatedNotifications[type].map(notification =>
          notification.id === id ? { ...notification, read: true } : notification
        );
      });

      setNotifications(updatedNotifications);
      const totalUnread = Object.values(updatedNotifications).flat().filter(n => !n.read).length;
      setUnreadCount(totalUnread);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await axios.put(`${API_BASE_URL}/admin/logs/markallread`, {}, { headers });
      const updatedNotifications = {
        enquiries: notifications.enquiries.map(notification => ({ ...notification, read: true })),
        orders: notifications.orders.map(notification => ({ ...notification, read: true })),
        users: notifications.users.map(notification => ({ ...notification, read: true })),
      };

      setNotifications(updatedNotifications);
      setUnreadCount(0);
      setIsVisible(false);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const then = new Date(timestamp);
    const diffInSeconds = Math.floor((now - then) / 1000);

    if (diffInSeconds < 60) {
      return 'just now';
    }

    const minutes = Math.floor(diffInSeconds / 60);
    if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    }

    return 'more than a day ago';
  };

  const handleItemClick = (id) => {
    handleMarkAsRead(id);
  };

  const hasNotifications = Object.values(notifications).some(type => type.length > 0);

  return (
    <div className="notification-container">
      <div className="bell-icon" onClick={() => setIsVisible(!isVisible)}>
        <FaBell style={{ fontSize: "20px" }} className="text-black mt-2" />
        {unreadCount > 0 && (
          <span className="badge">{unreadCount}</span>
        )}
      </div>
      {isVisible && (
        <div
          ref={notificationRef}
          id="notifications-list"
          className={`notifications-list ${isVisible ? 'show' : ''}`}
        >
          <div className="header">
            <div className="d-flex justify-content-between">
              Notifications ({unreadCount})
              <div>
                <FaTimes className="clear-icon" onClick={() => setIsVisible(false)} />
              </div>
            </div>
            <button
              onClick={handleMarkAllAsRead}
              className="mark-all-read-btn border border-0 text-primary bg-white fw-bold"
              style={{ fontSize: "12px" }}
            >
              Mark all as read <FaCheckDouble />
            </button>
          </div>
          <div className="notifications-content">
            {hasNotifications ? (
              Object.keys(notifications).map(type => (
                <div key={type} className="notification-group">
                  {notifications[type].map(notification => (
                    <div className="notification-item2" key={notification.id}>
                      <Row
                        className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                        onClick={() => handleItemClick(notification.id)}
                      >
                        <Col sm={10} md={10} lg={10} className="notification-icon">
                          {notification.message}
                        </Col>
                        <Col sm={2} md={2} lg={2} className="notification-content">
                          {notification.read && <FaCheck className="read-icon" />}
                        </Col>
                        <Col className="timestamp">
                          {getTimeAgo(notification.timestamp)}
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div>No new notifications</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Notification;
