import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Lottie from "lottie-react";
import Preloader from "../../Preloader/Preloader-Animation.json";

function EditNewZone() {
  const [zone, setZone] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   try{
  //     setLoading(true)

  //   axios
  //     .get(`${API_BASE_URL}/admin/getallzones`, { headers })
  //     .then((res) => {
  //       setZone(res.data?.Zones);
  //       // console.log("getallzones", res.data.Zones);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  //   }finally{
  //     setLoading(false)
  //   }
  // }, []);
  useEffect(() => {
    const fetchData = async () => { 
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/admin/getallzones`, {
          headers,
        });
        setZone(response.data?.Zones);
        // console.log("getallzones", response.data.Zones);
      } catch (error) {
        console.log("Error fetching zones:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const deleteZone = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Zone ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/admin/zone/deletzone/${id}`, { headers })
          .then(() => {
            setZone((prevData) =>
              prevData.filter((prevData) => prevData?._id !== id)
            );
          })
          .catch((error) => {
            console.log("error", error);

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });

        Swal.fire({
          title: "Deleted!",
          text: "Your Zone has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleZoneStatus = (ZoneId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/admin/zone/status/${ZoneId}`,
        {
          isActive: !isActive,
        },
        { headers }
      )
      .then(() => {
        setZone((prevData) =>
          prevData.map((item) =>
            item._id === ZoneId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Zone Sample</h2>
        <Link to="/addNewZone" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table
        className="categorys-table"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <thead>
          <tr>
            <th>Zone Name</th>
            <th>Weight</th>
            <th>Rate</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={Preloader}
            />
          ) : (
            <>
              {zone.map((item, index) => (
                <tr key={index}>
                  <td>{item?.zonename}</td>
                  <td>
                    {item?.weight?.value} {item?.weight?.unit}
                  </td>
                  <td>{item?.defaultRate}</td>
                  <td>
                    <Link to={`/zoneDetials/${item?._id}`}>
                      <button className="mx-1">
                        <AiFillEye className="m-2 fs-6" />
                      </button>
                    </Link>

                    <button
                      className="mx-1"
                      onClick={() => deleteZone(item?._id)}
                    >
                      <AiFillDelete className="m-2 fs-6" />
                    </button>

                    <Link to={`/updateZone/${item?._id}`}>
                      <button className="mx-1">
                        <FiEdit className="m-2 fs-6" />
                      </button>
                    </Link>

                    <button
                      className="mx-1"
                      onClick={() =>
                        toggleZoneStatus(item?._id, item?.isActive)
                      }
                    >
                      {item.isActive ? (
                        <BsToggleOn className="m-2 fs-6" />
                      ) : (
                        <BsToggleOff className="m-2 fs-6" />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EditNewZone;
