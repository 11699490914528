import React, { useState } from "react";
import "../Headers/Sidebar/Sidebar.scss";
import product_icon from "../../Assets/Images/product-icon.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

function ProductsItems() {
  const [menuOpenProducts, setMenuOpenProducts] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  const [openTools, setOpenTools] = useState(false);

  return (
    <div className="text-decoration-none">
      <li
        onClick={() => setMenuOpenProducts(!menuOpenProducts)}
        aria-controls="example-collapse-text"
        aria-expanded={menuOpenProducts}
      >
        <div className="d-flex">
          <img className="sidebar-icons" src={product_icon} />
          <div className="sidebar-text">Products</div>
        </div>
        {menuOpenProducts === false ? (
          <IoIosArrowForward className="sidebar-menu-icon" />
        ) : (
          <IoIosArrowDown className="sidebar-menu-icon" />
        )}
      </li>

      <Collapse in={menuOpenProducts}>
        <div id="example-collapse-text">
        <div id="example-collapse-text" className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/newProducts"
                >
                  Create Products
                </Link>
              </li>
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/newproductsViewPage"
                >
                  Edit Products
                </Link>
              </li>
            </div>

          {/* <Collapse in={openProducts}>
            <div id="example-collapse-text" className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/newProducts"
                >
                  Create Products
                </Link>
              </li>
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/newproductsViewPage"
                >
                  Edit Products
                </Link>
              </li>
            </div>
          </Collapse> */}
{/* 
          <li
            onClick={() => setOpenTools(!openTools)}
            aria-controls="example-collapse-text"
            aria-expanded={openTools}
          >
            <div className="sidebar-text">New Tools</div>
            {openTools === false ? (
              <IoIosArrowForward className="sidebar-menu-icon" />
            ) : (
              <IoIosArrowDown className="sidebar-menu-icon" />
            )}
          </li>

          <Collapse in={openTools}>
            <div id="example-collapse-text" className="mx-2">
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/createNewTools"
                >
                  Create Tools
                </Link>
              </li>
              <li>
                <Link
                  className={"text-dark text-decoration-none"}
                  style={{ fontSize: "13px" }}
                  to="/viewAllTools"
                >
                  Edit Tools
                </Link>
              </li>
            </div>
          </Collapse> */}
        </div>
      </Collapse>
    </div>
  );
}

export default ProductsItems;
