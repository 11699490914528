import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../utils/index";
import { useAuthHeaders } from "../Token";

function Zone({
  zone,
  selectedZone,
  setSelectedZone,
  setSubmitZone,
  setRegion,
}) {
  const headers = useAuthHeaders();

  const handleCategorySelect = (e) => {
    const selectedZone = e.target.value;
    setSelectedZone(selectedZone);

    const zoneNameFind = zone.find((zone) => zone?._id === selectedZone);

    setSubmitZone(zoneNameFind?.zonename);

    axios
      .get(`${API_BASE_URL}/zoneregionbyzone/${selectedZone}`, { headers })
      .then((response) => {
        // setRegion(response.data.zoneRegionNames);
        // console.log("select region", response.data.zoneRegionNames)
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Zone Name:
      </label>
      <br />

      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedZone}
        required
        onChange={handleCategorySelect}
      >
        <option value="">Select a Zone</option>
        {Array.isArray(zone) ? (
          zone.map((zone) => (
            <option key={zone?._id} value={zone?._id}>
              {zone?.zonename}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}

export default Zone;
