import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import BackArrow from "../../../components/MinorComponents/BackArrow";

function NewProductsDetials() {
  const { _id } = useParams();
  const [productDetial, setProductDetial] = useState([]);

  const productImage = productDetial?.productimages || [];

  console.log("productImage", productImage);

  useEffect(() => {
    axios
      // .get(`${API_BASE_URL}/user/products/singleproduct/${_id}`)
      .get(`${API_BASE_URL}/admin/products/adminsingleproduct/${_id}`)
      .then((response) => {
        setProductDetial(response.data.product); // Update the category state
        console.log("single product success", response.data.product);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <BackArrow path="/newproductsViewPage" />
      <h2>Product Detial</h2>
      <h6>Description Name:</h6>
      <p>{productDetial.partNumber || "Loading..."} </p>
      <div className="d-flex flex-wrap my-4">
        {productImage.map((image, i) => (
          <img
            src={image?.productimage}
            key={i}
            alt="product"
            width={70}
            height={70}
            style={{ objectFit: "contain" }}
          />
        ))}
      </div>
      <h6>Category Name:</h6>
      <p>{productDetial.category?.name || "Loading..."}</p>
      <h6>Sub Category Name:</h6>
      <p>{productDetial.subcategoryname?.name || "Loading..."}</p>
      {productDetial.descriptions && productDetial.descriptions.length > 0 ? (
        productDetial.descriptions.map((description, index) => (
          // console.log("description", description)
          <div key={index}>
            <h6>{description.name || "Loading..."} :</h6>
            <p>{description.value || "Loading..."}</p>
          </div>
        ))
      ) : (
        <p>No descriptions available or loading...</p>
      )}
      <h6>Weith Per Price</h6>
      <p>{productDetial.weightperpiece || "Loading..."}</p>
      <h6>Product Weigth</h6>
      <p>{productDetial.productweight || "Loading..."}</p>
      <h6>BOQ</h6>
      <p>{productDetial.boq || "Loading..."}</p>
      <h6>Original Price</h6>
      <p>{productDetial.oldprice || "Loading..."}</p>
      <h6>Sale Price</h6>
      <p>{productDetial.saleprice || "Loading..."}</p>
      <h6>Product</h6>
      <p>{productDetial.product || "Loading..."}</p>
      <h6>Quantity</h6>
      <p>{productDetial.quantity || "Loading..."}</p>
      <h6>Productprice</h6>
      <p>{productDetial.Productprice || "Loading..."}</p>
      <h6>Meta Tile</h6>
      <p>{productDetial.metatitle || "Loading..."}</p>

      <h6>Description</h6>
      {/* <p>{productDetial.writenotes || "Loading..."}</p> */}
      <div dangerouslySetInnerHTML={{ __html: productDetial.writenotes }} />

      <h6>Deal Of Day</h6>
      {/* <p>{productDetial.dealofday || "Loading..."}</p> */}
      <p>
        {productDetial.dealofday ? "Is on Deal Of Day" : "Not on Deal Of Day"}
      </p>
      <h6>Created At</h6>
      <p>{productDetial.createdAt || "Loading..."}</p>
    </div>
  );
}

export default NewProductsDetials;
