import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Lottie from "lottie-react";
import Preloader from "../../../Preloader/Preloader-Animation.json";


function FiltersDetials() {
  const [data, setData] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);




  console.log("headers", headers);

  useEffect(() => {
    try{setLoading(true)
    
    axios
      .get(`${API_BASE_URL}/admin/getallfilter`, { headers })
      .then((response) => {
        setData(response.data?.filters);
        console.log("getallfilter", response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    }finally{setLoading(false)}
  }, []);

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this filter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result?.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/admin/deletefilter/${categoryId}`, {
            headers,
          })
          .then(() => {
            setData((prevData) =>
              prevData.filter((item) => item?._id !== categoryId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/admin/filterstatus/${categoryId}`,
        {
          isActive: !isActive,
        },
        {
          headers,
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item?._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0"> Filters </h2>
        <Link to="/filters" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <thead>
          <tr>
            <th>Name</th>
            <th>sub Category</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
        {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (<>
          {data.map((item, index) => (
            // console.log("item getallfilter", item)
            <tr key={index}>
              <td>{item?.name}</td>
              <td>{item?.subcategory?.name}</td>
              <td>{item?.category?.name}</td>
              <td>
                <Link to={`/filterViewPage/${item._id}`}>
                  <button className="mx-1">
                    <AiFillEye className="m-2 fs-6" />
                  </button>
                </Link>
                <button
                  className="mx-1"
                  onClick={() => deleteCategory(item?._id)}
                >
                  <AiFillDelete className="m-2 fs-6" />
                </button>

                <Link to={`/filterEdit/${item?._id}`}>
                  <button className="mx-1">
                    <FiEdit className="m-2 fs-6" />
                  </button>
                </Link>

                <button
                  className="mx-1"
                  onClick={() => toggleCategoryStatus(item?._id, item?.isActive)}
                >
                  {item?.isActive ? (
                    <BsToggleOn className="m-2 fs-6" />
                  ) : (
                    <BsToggleOff className="m-2 fs-6" />
                  )}
                </button>
              </td>
            </tr>
          ))}
          </>)}
        </tbody>
      </table>
    </div>
  );
}

export default FiltersDetials;
