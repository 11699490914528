import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {API_BASE_URL} from "../../../../utils/index"


function DescriptionMethodDetial() {
  const { _id } = useParams();

  const [descriptionTypeDetial, setDescriptionTypeDetial] = useState({});

  //   console.log(descriptionTypeDetial, "descriptionTypeDetial");

  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/formfieldcategory/admin/singleformfieldcategory/${_id}`
      )
      .then((response) => {
        setDescriptionTypeDetial(response.data?.formfieldcategory); // Update the category state
        // console.log(response.data.formfieldcategory,"response")
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <h2>Description Type Detial</h2>
      <h6>Name:</h6>
      <p>{descriptionTypeDetial?.name}</p>
      <h6>createdAt</h6>
      <p>{descriptionTypeDetial?.createdAt}</p>
    </div>
  );
}

export default DescriptionMethodDetial;
