// import React, { useEffect, useState } from "react";
// import axios from "axios";
// // import { API_BASE_URL } from "../../../utils";
// // import { useAuthHeaders } from "../../../components/Token";
// import Table from "react-bootstrap/Table";
// import { FaCircle, FaEllipsisH } from "react-icons/fa";
// import { Dropdown } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import "../orderProducts/orderProducts.scss";
// import { FaEye } from "react-icons/fa";
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../../components/Token";

// function Enquiry() {
//   const [orderData, setOrderData] = useState([]);
//   const headers = useAuthHeaders();

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/admin/enquiry`, { headers })
//       .then((res) => {
//         console.log(res, "Enquiry details");
//         setOrderData(res?.data?.data);
//       })
//       .catch((error) => {
//         console.log("Error fetching order data:", error);
//       });
//   }, [headers]);

//   const formatdates = (date) => {
//     const options = { year: "numeric", month: "short", day: "numeric" };
//     return new Date(date).toLocaleDateString("en-US", options);
//   };

//   const getStatusStyle = (status) => {
//     if (!status) {
//       return {}; // Return a default style or handle it as needed
//     }

//     switch (status.toLowerCase()) {
//       case "pending":
//         return { color: "#F3A638" };
//       case "resolve":
//         return { color: "#4CB64C" };
//       case "in-processing":
//         return { color: "#54B7D3" };
//       case "rejected":
//         return { color: "#E3503E" };
//       default:
//         return {};
//     }
//   };

//   // const statusDropdownStyle = (status) => {
//   //   const styles = {
//   //     resolved: { color: "#4CB64C", boxShadow: "0 0 5px 0 rgba(76, 182, 76, 0.5)" },
//   //     rejected: { color: "#E3503E", boxShadow: "0 0 5px 0 rgba(227, 80, 62, 0.5)" },
//   //     pending: { color: "#F3A638", boxShadow: "0 0 5px 0 rgba(243, 166, 56, 0.5)" }
//   //   };

//   //   return styles[status.toLowerCase()] || {};
//   // };

//   return (
//     <div className="p-4">
//       <Table
//         className="text-center table table-striped"
//         style={{ border: "1px solid" }}
//       >
//         <thead
//           className="table-header fw-light"
//           style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
//         >
//           <tr>
//             <th>S.No</th>
//             <th>Enquiry Id</th>
//             <th>Company Name</th>
//             <th>Name</th>
//             <th>Phone</th>
//             <th>Email</th>
//             <th>Subject</th>
//             <th>Date</th>
//             <th>Status</th>
//             <th>View</th>
//             <th>Update</th>
//           </tr>
//         </thead>
//         <tbody className="text-center">
//           {orderData.lenght > 0 ?  (
//             <tr>
//               <td colSpan="11" className="text-center text-danger" style={{borderBottomColor:"black"}}>
//                 No Inquiry
//               </td>
//             </tr>
//           ):(
//             orderData.map((Enquiry, index) => (
//               <tr key={Enquiry._id}>
//                 <td>{index + 1}</td>
//                 <td>{Enquiry.enquiryId}</td>
//                 <td>{Enquiry.companyName}</td>
//                 <td>{Enquiry.name}</td>
//                 <td>{Enquiry.mobile}</td>
//                 <td>{Enquiry.email}</td>
//                 <td>{Enquiry.subject}</td>
//                 <td>
//                   {Enquiry.createdAt ? formatdates(Enquiry.createdAt) : "N/A"}
//                 </td>
//                 <td>
//                   <FaCircle style={getStatusStyle(Enquiry?.Status)} />
//                   {Enquiry?.Status}
//                 </td>
//                 <td>
//                   <Dropdown>
//                     <Dropdown.Toggle
//                       className="bg-white border border-0"
//                       id="dropdown-basic"
//                     >
//                       <FaEye style={{ color: "black" }} />
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu
//                       style={{ boxShadow: "0 0 5px rgba(0,0,0,0.3)" }}
//                     >
//                       <Dropdown.Item>
//                         <Link
//                           className="text-decoration-none text-black "
//                           style={{ background: "" }}
//                           to={`/viewenquiryDetails/${Enquiry._id}`}
//                         >
//                           View Order
//                         </Link>
//                       </Dropdown.Item>
//                     </Dropdown.Menu>
//                   </Dropdown>
//                 </td>
//                 <td>
//                   <Dropdown>
//                     <Dropdown.Toggle
//                       className="dropdown-toggle bg-white text-black"
//                       id="dropdown-status"  
//                     >
//                       Update Status
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu
//                       style={{ boxShadow: "0 0 5px rgba(0,0,0,0.3)" }}
//                     >
//                       <Dropdown.Item>
//                         <FaCircle style={getStatusStyle("resolved")} />
//                         Resolved
//                       </Dropdown.Item>
//                       <Dropdown.Item>
//                         <FaCircle style={getStatusStyle("rejected")} />
//                         Rejected
//                       </Dropdown.Item>
//                       <Dropdown.Item>
//                         {" "}
//                         <FaCircle style={getStatusStyle("pending")} />
//                         Pending
//                       </Dropdown.Item>
//                     </Dropdown.Menu>
//                   </Dropdown>
//                 </td>
//               </tr>
//             ))
//           )}
//         </tbody>
//       </Table>
//     </div>
//   );
// }

// export default Enquiry;


//updated on 07/24/2024

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { API_BASE_URL } from "../../../utils";
// import { useAuthHeaders } from "../../../components/Token";
// import Table from "react-bootstrap/Table";
// import { FaCircle, FaEllipsisH, FaSearch, FaEye, FaBell } from "react-icons/fa";
// import { Dropdown, Badge } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import "../orderProducts/orderProducts.scss";
// import Inquiry from "../../../Assets/Images/conversation.png"

// function Enquiry() {
//   const [enquiryData, setEnquiryData] = useState([]);
//   const [filteredEnquiries, setFilteredEnquiries] = useState([]);
//   const [newEnquiries, setNewEnquiries] = useState(0);
//   const headers = useAuthHeaders();
//   const [search, setSearch] = useState("");

//   useEffect(() => {
//     fetchEnquiries();
//     const interval = setInterval(() => {
//       fetchEnquiries();
//     }, 60000); // Check for new enquiries every minute

//     return () => clearInterval(interval); // Cleanup on unmount
//   }, [headers]);

//   const fetchEnquiries = () => {
//     axios
//       .get(`${API_BASE_URL}/admin/enquiry`, { headers })
//       .then((res) => {
//         console.log(res, "Enquiry details");
//         const data = res?.data?.data || [];
//         setEnquiryData(data);
//         setFilteredEnquiries(data);
//         const newEnquiryCount = data.filter((enquiry) => enquiry.Status === "New").length || 0;
//         console.log(newEnquiryCount, "new Enquiries");
//         setNewEnquiries(newEnquiryCount);
//       })
//       .catch((error) => {
//         console.log("Error fetching enquiry data:", error);
//       });
//   };

//   const formatDate = (date) => {
//     const options = { year: "numeric", month: "short", day: "numeric" };
//     return new Date(date).toLocaleDateString("en-US", options);
//   };

//   const getStatusStyle = (status) => {
//     switch (status?.toLowerCase()) {
//       case "pending":
//         return { color: "#F3A638" };
//       case "resolved":
//         return { color: "#4CB64C" };
//       case "in-processing":
//         return { color: "#54B7D3" };
//       case "rejected":
//         return { color: "#E3503E" };
//       default:
//         return {};
//     }
//   };

//   useEffect(() => {
//     const results = enquiryData.filter((enquiry) => {
//       const searchLower = search.toLowerCase();
//       return (
//         enquiry.companyName?.toLowerCase().includes(searchLower) ||
//         enquiry.name?.toLowerCase().includes(searchLower) ||
//         enquiry.subject?.toLowerCase().includes(searchLower) ||
//         enquiry.mobile?.toLowerCase().includes(searchLower) ||
//         enquiry.email?.toLowerCase().includes(searchLower)
//       );
//     });
//     setFilteredEnquiries(results);
//   }, [search, enquiryData]);

//   return (
//     <div className="container">
//       <div className="d-flex mt-4 mb-4">
//       <h4 className="mt-4"> Inquiry List <img src={Inquiry} alt="" width={'40px'}/> </h4>

//         <div className="ms-auto d-flex align-items-center">
//           <div className="position-relative">
//             {/* <FaBell className="fs-3" /> */}
//             {/* {newEnquiries > 0 && (
//               <Badge
//                 pill
//                 bg="danger"
//                 className="position-absolute top-0 start-100 translate-middle"
//               >
//                 {newEnquiries}
//               </Badge>
//             )} */}
//           </div>
//           <div
//             className="d-flex  rounded-2 ms-3"
//             style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}
//           >
//             <input
//               type="text"
//               className="form-control w-100 text-start border border-0"
//               placeholder="Search..."
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//             />
//             <FaSearch className="fs-3 mt-2 m-2" />
//           </div>
//         </div>
//       </div>
//       <Table className="table table-striped" style={{ border: "1px solid #60656b" }}>
//         <thead
//           className="table-header fw-light"
//           style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
//         >
//           <tr className="text-center ">
//             <th>S.No</th>
//             <th>Inquiry Id</th>
//             <th>Company Name</th>
//             <th>Name</th>
//             <th>Phone</th>
//             <th>Email</th>
//             <th>Subject</th>
//             <th>Date</th>
//             <th>Status</th>
//             <th>View</th>
//             <th>Update</th>
//           </tr>
//         </thead>
//         <tbody className="text-center">
//           {filteredEnquiries.length === 0 ? (
//             <tr>
//               <td colSpan="11" className="text-center text-danger" style={{ borderBottomColor: "black" }}>
//                 No Inquiry
//               </td>
//             </tr>
//           ) : (
//             filteredEnquiries.map((enquiry, index) => (
//               <tr key={enquiry._id}>
//                 <td>{index + 1}</td>
//                 <td>{enquiry.enquiryId}</td>
//                 <td>{enquiry.companyName}</td>
//                 <td>{enquiry.name}</td>
//                 <td>{enquiry.mobile}</td>
//                 <td>{enquiry.email}</td>
//                 <td>{enquiry.subject}</td>
//                 <td>{enquiry.createdAt ? formatDate(enquiry.createdAt) : "N/A"}</td>
//                 <td>
//                   <FaCircle style={getStatusStyle(enquiry.Status)} />
//                   {enquiry.Status}
//                 </td>
//                 <td>
//                   <p
//                     className="border border-0 mt-2"
//                     id="dropdown-basic"
//                     style={{ background: "transparent" }}
//                   >
//                     <Link
//                       className="text-decoration-none text-black"
//                       to={`/viewenquiryDetails/${enquiry._id}`}
//                     >
//                       <FaEye style={{ color: "black" }} />
//                     </Link>
//                   </p>
//                 </td>
//                 <td>
//                   <Dropdown>
//                     <Dropdown.Toggle className="dropdown-toggle bg-white text-black" id="dropdown-status">
//                       Update Status
//                     </Dropdown.Toggle>
//                     <Dropdown.Menu style={{ boxShadow: "0 0 5px rgba(0,0,0,0.3)" }}>
//                       <Dropdown.Item onClick={() => updateStatus(enquiry._id, "Resolved")}>
//                         <FaCircle style={getStatusStyle("Resolved")} /> Resolved
//                       </Dropdown.Item>
//                       <Dropdown.Item onClick={() => updateStatus(enquiry._id, "Rejected")}>
//                         <FaCircle style={getStatusStyle("Rejected")} /> Rejected
//                       </Dropdown.Item>
//                       <Dropdown.Item onClick={() => updateStatus(enquiry._id, "Pending")}>
//                         <FaCircle style={getStatusStyle("Pending")} /> Pending
//                       </Dropdown.Item>
//                       <Dropdown.Item onClick={() => updateStatus(enquiry._id, "New")}>
//                         <FaCircle style={getStatusStyle("New")} /> New
//                       </Dropdown.Item>
//                     </Dropdown.Menu>
//                   </Dropdown>
//                 </td>
//               </tr>
//             ))
//           )}
//         </tbody>
//       </Table>
//     </div>
//   );

//   function updateStatus(enquiryId, status) {
//     axios
//       .put(`${API_BASE_URL}/admin/enquiry/status/${enquiryId}`, { status }, { headers })
//       .then((res) => {
//         setEnquiryData((prevData) =>
//           prevData.map((enquiry) =>
//             enquiry._id === enquiryId ? { ...enquiry, Status: status } : enquiry
//           )
//         );
//         setFilteredEnquiries((prevData) =>
//           prevData.map((enquiry) =>
//             enquiry._id === enquiryId ? { ...enquiry, Status: status } : enquiry
//           )
//         );
//       })
//       .catch((error) => {
//         console.log("Error updating status:", error);
//       });
//   }
// }

// export default Enquiry;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../utils";
import { useAuthHeaders } from "../../../components/Token";
import Table from "react-bootstrap/Table";
import { FaCircle, FaEllipsisH, FaSearch, FaEye, FaBell } from "react-icons/fa";
import { Dropdown, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../orderProducts/orderProducts.scss";
import Inquiry from "../../../Assets/Images/conversation.png";
import Lottie from 'lottie-react';
import Preloader from "../../../Preloader/Preloader-Animation.json"; // Ensure the path to the animation file is correct
const ITEMS_PER_PAGE = 5;

function Enquiry() {
  const [enquiryData, setEnquiryData] = useState([]);
  const [filteredEnquiries, setFilteredEnquiries] = useState([]);
  const [newEnquiries, setNewEnquiries] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const headers = useAuthHeaders();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Pagination state

  useEffect(() => {
    fetchEnquiries();
    const interval = setInterval(() => {
      fetchEnquiries();
    }, 60000); // Check for new enquiries every minute

    return () => clearInterval(interval); // Cleanup on unmount
  }, [headers]);

  const fetchEnquiries = () => {
    setLoading(true); // Set loading to true before API call
    axios
      .get(`${API_BASE_URL}/admin/enquiry`, { headers })
      .then((res) => {
        const data = res?.data?.data || [];
        setEnquiryData(data);
        setFilteredEnquiries(data);
        const newEnquiryCount = data.filter((enquiry) => enquiry.Status === "New").length || 0;
        setNewEnquiries(newEnquiryCount);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.log("Error fetching enquiry data:", error);
        setLoading(false); // Set loading to false in case of error
      });
  };

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const getStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return { color: "#F3A638" };
      case "resolved":
        return { color: "#4CB64C" };
      case "in-processing":
        return { color: "#54B7D3" };
      case "rejected":
        return { color: "#E3503E" };
      case "new":
        return { color: "#32b2fc" };
      default:
        return {};
    }
  };

  useEffect(() => {
    const results = enquiryData.filter((enquiry) => {
      const searchLower = search.toLowerCase();
      return (
        enquiry.companyName?.toLowerCase().includes(searchLower) ||
        enquiry.name?.toLowerCase().includes(searchLower) ||
        enquiry.subject?.toLowerCase().includes(searchLower) ||
        enquiry.mobile?.toLowerCase().includes(searchLower) ||
        enquiry.email?.toLowerCase().includes(searchLower)
      );
    });
    setFilteredEnquiries(results);
  }, [search, enquiryData]);

  const updateStatus = (enquiryId, status) => {
    setLoading(true); // Set loading to true before updating status
    axios
      .put(`${API_BASE_URL}/admin/enquiry/status/${enquiryId}`, { status }, { headers })
      .then((res) => {
        setEnquiryData((prevData) =>
          prevData.map((enquiry) =>
            enquiry._id === enquiryId ? { ...enquiry, Status: status } : enquiry
          )
        );
        setFilteredEnquiries((prevData) =>
          prevData.map((enquiry) =>
            enquiry._id === enquiryId ? { ...enquiry, Status: status } : enquiry
          )
        );
        setLoading(false); // Set loading to false after status is updated
      })
      .catch((error) => {
        console.log("Error updating status:", error);
        setLoading(false); // Set loading to false in case of error
      });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(filteredEnquiries.length / ITEMS_PER_PAGE)) {
      setCurrentPage(newPage);
    }
  };

  const indexOfLastOrder = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstOrder = indexOfLastOrder - ITEMS_PER_PAGE;
  const currentOrders = filteredEnquiries.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(filteredEnquiries.length / ITEMS_PER_PAGE);

  return (
    <div className="container">

    
    <div className="mt-5 ms-2">

      <div className="d-flex mt-4 ">
        <h4 className="mt-4">
          Inquiry List 
          {/* <img src={Inquiry} alt="" width={'40px'} /> */}
        </h4>
        <div className="ms-auto d-flex align-items-center m-2">
          <div className="position-relative">
            {/* <FaBell className="fs-3" /> */}
            {/* {newEnquiries > 0 && (
              <Badge
                pill
                bg="danger"
                className="position-absolute top-0 start-100 translate-middle"
              >
                {newEnquiries}
              </Badge>
            )} */}
          </div>
          <div
            className="d-flex rounded-2 ms-3"
            style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}
          >
            <input
              type="text"
              className="form-control w-100 text-start border border-0"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <FaSearch className="fs-3 mt-2 m-2" />
          </div>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <Lottie style={{ width: "100vw", height: "50vh" }} animationData={Preloader} />
        </div>
      ) : (
        <>
          <Table className="table table-striped" style={{ border: "1px solid #60656b" }}>
            <thead
              className="table-header fw-light"
              style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
            >
              <tr className="text-center " style={{ fontSize: "smaller" }}>
                <th>S.No</th>
                <th>Inquiry Id</th>
                <th>Company Name</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Date</th>
                <th>Status</th>
                <th>View</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredEnquiries.length === 0 ? (
                <tr>
                  <td colSpan="11" className="text-center text-danger" style={{ borderBottomColor: "black" }}>
                    No Inquiry
                  </td>
                </tr>
              ) : (
                currentOrders.map((enquiry, index) => (
                  <tr key={enquiry._id} style={{ fontSize: "15px" }}>
                    <td>{index + 1}</td>
                    <td>{enquiry.enquiryId}</td>
                    <td>{enquiry.companyName}</td>
                    <td>{enquiry.name}</td>
                    <td>{enquiry.mobile}</td>
                    <td>{enquiry.email}</td>
                    <td>{enquiry.subject}</td>
                    <td>{enquiry.createdAt ? formatDate(enquiry.createdAt) : "N/A"}</td>
                    <td>
                      <FaCircle style={getStatusStyle(enquiry.Status)} />
                      {enquiry.Status}
                    </td>
                    <td>
                      <p
                        className="border border-0 mt-2"
                        id="dropdown-basic"
                        style={{ background: "transparent" }}
                      >
                        <Link
                          className="text-decoration-none text-black"
                          to={`/viewenquiryDetails/${enquiry._id}`}
                        >
                          <FaEye style={{ fontSize: "25px" }} />
                        </Link>
                      </p>
                    </td>
                    <td>
                    <Dropdown>
                    <Dropdown.Toggle className="dropdown-toggle bg-white text-black" id="dropdown-status">
                      Update Status
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ boxShadow: "0 0 5px rgba(0,0,0,0.3)" }}>
                      <Dropdown.Item onClick={() => updateStatus(enquiry._id, "Resolved")}>
                        <FaCircle style={getStatusStyle("Resolved")} /> Resolved
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => updateStatus(enquiry._id, "Rejected")}>
                        <FaCircle style={getStatusStyle("Rejected")} /> Rejected
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => updateStatus(enquiry._id, "Pending")}>
                        <FaCircle style={getStatusStyle("Pending")} /> Pending
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => updateStatus(enquiry._id, "New")}>
                        <FaCircle style={getStatusStyle("New")} />  New
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
          <div className="d-flex ">
            <Button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              className=""
              variant="secondary"
            >
              Previous
            </Button>
            <Button
            variant="secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className="ms-auto"
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div></div>
  );
}

export default Enquiry;
