import React, { useEffect, useState } from "react";
import "../ProductCategory/productCategory.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DescriptionNameTypeCategorys from "../../../components/DescriptionNameType/DescriptionNameTypeCategorys";
import DescriptionNameTypeSubCategorys from "../../../components/DescriptionNameType/DescriptionNameTypeSubCategorys";
import DescriptionNameTypeNameField from "../../../components/DescriptionNameType/DescriptionNameTypeNameField";
import DescriptionTypeButton from "../../../components/DescriptionTypeButton/DescriptionTypeButton";
import DescriptionTypesField from "../../../components/DescriptionNameType/DescriptionTypesField";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";
import CancelButton from "../../../components/MinorComponents/CancelButton";

export default function CreateDescriptionNameTypes() {
  const [category, setCategory] = useState([]);
  const [submitCategory, setSubmitCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [submitSubCategory, setSubmitSubCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [descriptionNameType, setDescriptionNameType] = useState([]);
  const [createDescriptionNameType, setCreateDescriptionNameType] = useState(
    []
  );
  const [selectedDescriptionNameType, setSelectedDescriptionNameType] =
    useState("");
  const [discriptionMethod, setDiscriptionMethod] = useState([]);
  const [selectedDescriptionMethod, setSelectedDescriptionMethod] =
    useState("");

  //
  const [tags, setTags] = React.useState([]);
  const textValues = tags.map((item) => item.text);
  //

  const navigate = useNavigate();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, {
        headers,
      })
      .then((response) => {
        setCategory(response.data?.categories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
    axios
      .get(`${API_BASE_URL}/type/admin/gettypes`, { headers })
      .then((response) => {
        setDiscriptionMethod(response?.data?.types);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, []);

  const handleCategoryCreation = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      descriptiontypes: textValues,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    axios
      .post(
        `${API_BASE_URL}/admin/selectdescriptiontypes`,
        {
          categoryname: submitCategory,
          subcategoryname: submitSubCategory,
          descriptionname: selectedDescriptionNameType,
          type: selectedDescriptionMethod,
          descriptionnametypes: textValues,
        },
        {
          headers,
        }
      )
      .then((result) => {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: "Description Name Types work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/viewDescriptionNameTypes");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response?.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Create Description Name Types</h2>
      <form
        onSubmit={handleCategoryCreation}
        className="category position-relative"
        style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}
      >
        <div className="row category-field">
          <DescriptionNameTypeCategorys
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            category={category}
            setSubCategory={setSubCategory}
            setSubmitCategory={setSubmitCategory}
          />

          <DescriptionNameTypeSubCategorys
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            subCategory={subCategory}
            setDescriptionNameType={setDescriptionNameType}
            setSubmitSubCategory={setSubmitSubCategory}
          />

          <DescriptionNameTypeNameField
            setCreateDescriptionNameType={setCreateDescriptionNameType}
            createDescriptionNameType={createDescriptionNameType}
            descriptionNameType={descriptionNameType}
            setSelectedDescriptionNameType={setSelectedDescriptionNameType}
            selectedDescriptionNameType={selectedDescriptionNameType}
          />

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Type:
            </label>
            <br />
            <Form.Select
              id="subCategory"
              aria-label="Default select example"
              className="my-2"
              value={selectedDescriptionMethod}
              onChange={(e) => setSelectedDescriptionMethod(e.target.value)}
            >
              <option value="">Select Description Method</option>
              {Array.isArray(discriptionMethod) &&
                discriptionMethod.map((method) => (
                  // console.log("method",method)
                  <option key={method?._id} value={method?.name}>
                    {method?.name}
                  </option>
                ))}
            </Form.Select>
          </div>

          <div className="col-md-4">
            {selectedDescriptionMethod.trim() === "Drop Down" && (
              <div>
                <DescriptionTypesField setTags={setTags} tags={tags} />
              </div>
            )}

            {selectedDescriptionMethod.trim() === "Check Box" && (
              <div>
                <DescriptionTypesField setTags={setTags} tags={tags} />
              </div>
            )}
          </div>

          {/* <DescriptionTypeButton /> */}
        </div>
        <div className="buttons">
          <button style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/viewDescriptionNameTypes" />
        </div>
      </form>
      {/*  */}
    </div>
  );
}
