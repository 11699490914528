import React, { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../utils";
import axios from "axios";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useAuthHeaders } from "../../../components/Token";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Lottie from "lottie-react";
import Preloader from "../../../Preloader/Preloader-Animation.json";

function EditZoneRegion() {
  const [data, setData] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  
  //   axios
  //     .get(`${API_BASE_URL}/admin/getAllShipping`)
  //     .then((response) => {
  //       setData(response.data.Shippping);
  //       // console.log("getAllShipping ::", response.data.Shippping);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching subcategory data:", error);
  //     });
  // }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/admin/getAllShipping`);
        console.log("getAllShipping ::", response.data.Shippping);
        setData(response.data.Shippping);
      } catch (error) {
        console.error("Error fetching shipping data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  




  console.log("data is comming ", data);
  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Zone Region ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/admin/deletshipping/${categoryId}`, {
            headers,
          })
          .then(() => {
            setData((prevData) =>
              prevData.filter((item) => item._id !== categoryId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
        Swal.fire({
          title: "Deleted!",
          text: "Zone Region has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/admin/shipping/status/${categoryId}`,
        {
          isActive: !isActive,
        },
        {
          headers,
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };
  // console.log("data is comming ", data);

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Shipping</h2>
        <Link to="/addNewZoneRegion" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table
        className="categorys-table"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <thead>
          <tr>
            <th>Rate</th>
            <th>Weight</th>
            <th>Region Area</th>
            <th>Zone</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={Preloader}
            />
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.Rate}</td>

                    <td>
                      {item.weight?.value} {item.weight?.unit}{" "}
                    </td>

                    <td>
                      <DropdownButton id="dropdown-item-button" title="i">
                        {item.zoneArea.map((innerItem, i) => {
                          // console.log("item dropdown :", innerItem);
                          return (
                            <Dropdown.Item key={i} as="button">
                              {innerItem}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                    </td>

                    <td>{item?.zone?.zonename}</td>

                    <td>
                      <Link to={`/zoneRegionDetial/${item._id}`}>
                        <button className="mx-1">
                          <AiFillEye className="m-2 fs-6" />
                        </button>
                      </Link>

                      <button
                        className="mx-1"
                        onClick={() => deleteCategory(item._id)}
                      >
                        <AiFillDelete className="m-2 fs-6" />
                      </button>
                      <Link to={`/updateZoneRegion/${item._id}`}>
                        <button className="mx-1">
                          <FiEdit className="m-2 fs-6" />
                        </button>
                      </Link>

                      <button
                        className="mx-1"
                        onClick={() =>
                          toggleCategoryStatus(item._id, item.isActive)
                        }
                      >
                        {item.isActive ? (
                          <BsToggleOn className="m-2 fs-6" />
                        ) : (
                          <BsToggleOff className="m-2 fs-6" />
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No Zone Region found</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default EditZoneRegion;
