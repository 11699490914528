import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils";
import { useAuthHeaders } from "../../../components/Token";
import BackArrow from "../../../components/MinorComponents/BackArrow";

function ZoneRegionDetial() {
  const { _id } = useParams();
  const headers = useAuthHeaders();
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getsingleshipping/${_id}`, { headers })
      .then((res) => {
        setData(res.data.Shipping);
        // console.log("data in getsingleregion", res.data.Shipping);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="m-4" >
      <BackArrow path="/editNewZoneRegion" />
      <h3 className="my-4">Shipping</h3>

      <h6>Rate :</h6>
      <p>{data?.Rate}</p>

      <h6>Weight :</h6>
      <p>
        {data?.weight?.value} {data?.weight?.unit}
      </p>

      <h6>Zone Area :</h6>
      <p>
        {Array.isArray(data?.zoneArea)
          ? data?.zoneArea.map((item, index) => (
              <p className="m-0" key={item}>
                <span>{index + 1}</span> ) {item}
              </p>
            ))
          : "descriptionnametypes is not an array"}
      </p>

      <h6>Zone :</h6>
      <p>{data?.zone?.zonename}</p>
    </div>
  );
}

export default ZoneRegionDetial;
