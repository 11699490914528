import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";
import Lottie from "lottie-react";
import Preloader from "../../../Preloader/Preloader-Animation.json";


function ViewDescriptionNameTypes() {
  const [data, setData] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);



  // useEffect(() => {
  //   try{setLoading(true)
    
  //   axios
  //     .get(`${API_BASE_URL}/admin/getdescriptiontypes`)
  //     .then((response) => {
  //       setData(response.data?.descriptiontypes);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  //   }finally{setLoading(false)}
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/admin/getdescriptiontypes`);
        setData(response.data?.descriptiontypes);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Description Name Type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/admin/deletedescriptiontype/${categoryId}`, {
            headers,
          })
          .then(() => {
            setData((prevData) =>
              prevData.filter((item) => item?._id !== categoryId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    // console.log("categoryId", categoryId)
    axios
      .put(
        `${API_BASE_URL}/admin/status/${categoryId}`,
        {
          isActive: !isActive, // Toggle the active status
        },
        {
          headers,
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData.map((item) =>
            item?._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Description Name Types</h2>
        <Link to="/createDescriptionNameTypes" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table className="categorys-table" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <thead>
          <tr>
            <th>Name</th>
            <th>NameTypes</th>
            <th>Sub Category</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh" }}
          animationData={Preloader}
        />
      ) : (<>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.descriptionname?.descriptionName}</td>
              <td>
                <DropdownButton id="dropdown-item-button" title="i">
                  {item?.descriptionnametypes.map((innerItem, i) => {
                    // console.log("item dropdown :", innerItem);
                    return (
                      <Dropdown.Item key={i} as="button">
                        {innerItem}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </td>
              <td> {item?.subcategory?.name} </td>
              <td> {item?.category?.name} </td>
              <td>
                <Link to={`/descriptionNameTypesDetial/${item?._id}`}>
                  <button className="mx-1">
                    <AiFillEye className="m-2 fs-6" />
                  </button>
                </Link>

                <button
                  className="mx-1"
                  onClick={() => deleteCategory(item?._id)}
                >
                  <AiFillDelete className="m-2 fs-6" />
                </button>

                <Link to={`/editDescriptionNameTypesDetial/${item?._id}`}>
                  <button className="mx-1">
                    <FiEdit className="m-2 fs-6" />
                  </button>
                </Link>

                <button
                  className="mx-1"
                  onClick={() => toggleCategoryStatus(item?._id, item?.isActive)}
                >
                  {item?.isActive ? (
                    <BsToggleOn className="m-2 fs-6" />
                  ) : (
                    <BsToggleOff className="m-2 fs-6" />
                  )}
                </button>
              </td>
            </tr>
          ))}
          </>)}
        </tbody>
      </table>
    </div>
  );
}

export default ViewDescriptionNameTypes;
