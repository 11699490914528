import React from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const NoteEditor = ({ setWriteNotes, writeNotes }) => {

  const handleContentChange = (value) => {
    setWriteNotes(value);
  };

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={writeNotes}
        onChange={handleContentChange}
      />
      <div dangerouslySetInnerHTML={{ __html: writeNotes }} />
    </div>
  );
};

export default NoteEditor;
