import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import Zone from "../../../components/ShippingComponents/Zone";
import { useAuthHeaders } from "../../../components/Token";
import Region from "../../../components/ShippingComponents/Region";
import Method from "../../../components/ShippingComponents/Method";
import { useNavigate } from "react-router-dom";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function AddShipping() {
  const [zone, setZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
  const [submitZone, setSubmitZone] = useState([]);
  const [region, setRegion] = useState([]);
  const [submitRegion, setSubmitRegion] = useState([]);
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [method, setMethod] = useState([]);
  const [submitMethod, setSubmitMethod] = useState("");
  const [wigth, setWigth] = useState("");
  const [cost, setCost] = useState("");

  const headers = useAuthHeaders();
  const navicate = useNavigate();

  const handleCreateShipping = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/admin/shipping/newshipping`,
        {
          zone: submitZone,
          region: submitRegion,
          districtname: district,
          pincode: pinCode,
          methodname: submitMethod,
          weight: wigth,
          cost: cost,
        },
        { headers }
      )
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: "Shipping has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navicate("/editShipping");
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getallzones`, { headers })
      .then((res) => {
        // console.log("res", res);
        setZone(res?.data?.Zones);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="m-4">
      <h2>Create Shipping</h2>
      <form
        onSubmit={handleCreateShipping}
        className="category position-relative"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <div className="row category-field">
          <Zone
            zone={zone}
            setSelectedZone={setSelectedZone}
            selectedZone={selectedZone}
            setSubmitZone={setSubmitZone}
            setRegion={setRegion}
          />

          <Region
            region={region}
            setSubmitRegion={setSubmitRegion}
            submitRegion={submitRegion}
          />

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              District Name :
            </label>
            <br />

            <input
              type="text"
              required
              onChange={(e) => setDistrict(e.target?.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Pin Code :
            </label>
            <br />

            <input
              type="number"
              required
              min={0}
              onChange={(e) => setPinCode(e.target?.value)}
            />
          </div>

          <Method
            setMethod={setMethod}
            method={method}
            setSubmitMethod={setSubmitMethod}
            submitMethod={submitMethod}
          />

          {submitMethod === "Flat rate" && (
            <>
              <div className="col-md-4">
                <label htmlFor="name" className="fs-6">
                  Weight :
                </label>
                <br />

                <input
                  type="number"
                  required
                  min={0}
                  onChange={(e) => setWigth(e.target?.value)}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="name" className="fs-6">
                  Cost :
                </label>
                <br />

                <input
                  type="number"
                  required
                  min={0}
                  onChange={(e) => setCost(e.target?.value)}
                />
              </div>
            </>
          )}
        </div>
        <div className="buttons">
          <button
            type="submit"
            style={{ backgroundColor: "#0443C0", color: "white" }}
          >
            Save
          </button>
          <CancelButton path="/editShipping" />{" "}
        </div>
      </form>
    </div>
  );
}

export default AddShipping;
