import React, { useState } from "react";
import { API_BASE_URL } from "../../utils";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../components/Token";
import Form from "react-bootstrap/Form";
// import CancelButton from "../../../components/MinorComponents/CancelButton";
import CancelButton from "../../components/MinorComponents/CancelButton";


function AddNewZone() {
  const [name, setName] = useState();
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("Kg");
  const [defaultRate, setDefaultRate] = useState("");

  const navigate = useNavigate();
  const headers = useAuthHeaders();

  const handleCategoryCreation = (e) => {
    e.preventDefault();

    axios
      .post(
        `${API_BASE_URL}/admin/Shippingzone/newzone`,
        {
          zonename: name,
          weight: {
            value: weight,
            unit: weightUnit,
          },
          defaultRate: defaultRate,
        },
        { headers }
      )
      .then((result) => {
        console.log(result);
        Swal.fire({
          icon: "success",
          title: "Zone Create has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/editNewZone");
      })
      .catch((error) => {
        console.log(error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Create Zone</h2>
      <form action="" className="category" onSubmit={handleCategoryCreation} style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Create Zone Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              className="w-100"
              required
              placeholder="Zone Name"
              onChange={(e) => setName(e.target?.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Weight :
            </label>
            <br />
            <div className="d-flex align-items-center">
              <input
                type="number"
                required
                min={0}
                className="w-100 weight-input"
                step="0.00001"
                placeholder="Zone Weight"
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => setWeight(e.target?.value)}
              />
              <Form.Select
                aria-label="Default select example"
                className="weight-unit"
                onChange={(e) => setWeightUnit(e.target?.value)}
              >
                <option value="Kg">Kg</option>
                <option value="Gram">Gram</option>
              </Form.Select>
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Zone Default Rate :
            </label>
            <br />

            <input
              type="number"
              required
              min={0}
              className="w-100"
              step="0.00001"
              onWheel={(event) => event.currentTarget.blur()}
              placeholder="Zone Rate"
              onChange={(e) => setDefaultRate(e.target?.value)}
            />
          </div>
        </div>

        <div className="buttons" style={{ marginTop: "30px" }}>
          <button type="submit" value="send" style={{ backgroundColor: "#0443C0", color: "white" }}>
            Save
          </button>
          <CancelButton path="/editNewZone"/>

          
        </div>
      </form>
    </div>
  );
}

export default AddNewZone;
