import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/index";
import Form from "react-bootstrap/Form";
import { useAuthHeaders } from "../../../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../../components/MinorComponents/CancelButton";

function EditDescriptionType() {
  const { _id } = useParams();
  const [newEditDescriptionName, setNewEditDescriptionName] = useState("");
  const [descriptionNameDetials, setDescriptionNameDetials] = useState("");
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  // const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("");
  // const [subCategory, setSubCategory] = useState([]);
  // const [selectedSubCategory, setSelectedSubCategory] = useState("");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/description/admin/singledescription/${_id}`)
      .then((response) => {
        setNewEditDescriptionName(response?.data?.description?.descriptionName);
        console.log("response.data.description", response?.data?.description);
        setDescriptionNameDetials(response?.data?.description);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });

    // axios
    //   .get(`${API_BASE_URL}/category/admin/getcategory`)
    //   .then((response) => {
    //     setCategories(response.data.categories);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching category data:", error);
    //   });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    axios
      .put(
        `${API_BASE_URL}/description/admin/updatedescription/${_id}`,
        {
          descriptionName: newEditDescriptionName,
          // subcategory: selectedSubCategory,
          // category: selectedCategory,
        },
        {
          headers,
        }
      )
      .then((response) => {
        navigate("/descriptionType");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  // const handleCategorySelect = (e) => {
  //   const selectedCategory = e.target.value;
  //   setSelectedCategory(selectedCategory);

  //   axios
  //     .get(`${API_BASE_URL}/category/subcategories/${selectedCategory}`)
  //     .then((response) => {
  //       // setSubCategory(response.data.subcategories);
  //       // console.log("New subcategory :" , response.data.subcategories);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching subcategory data:", error);
  //     });
  // };

  return (
    <div className="p-3">
      <h2>Edit Description Type</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <div className="d-flex align-items-center py-2">
            <h5 className="m-0">Category :</h5>
            <span className="mx-2">
              {descriptionNameDetials.subcategory?.name}
            </span>
          </div>

          <div className="d-flex align-items-center py-2">
            <h5 className="m-0">Category :</h5>
            <span className="mx-2">
              {descriptionNameDetials?.category?.name}
            </span>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Description Type Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={newEditDescriptionName}
              onChange={(e) => setNewEditDescriptionName(e.target.value)}
            />
          </div>

          {/* <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Category Name:
            </label>
            <br />
            <Form.Select
              id="category"
              aria-label="Default select example"
              className="my-2"
              required
              value={selectedCategory}
              onChange={handleCategorySelect}
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Change Sub Category Name:
            </label>
            <br />
            <Form.Select
              id="category"
              aria-label="Default select example"
              className="my-2"
              value={selectedSubCategory}
              required
              onChange={(e) => setSelectedSubCategory(e.target.value)}
            >
              <option value="">Select a Sub Category</option>
              {Array.isArray(subCategory) ? (
                subCategory.map((category) => (
                  // console.log("sebcategory test :", category)
                  <option key={category._id} value={category.name}>
                    {category.name}
                  </option>
                ))
              ) : (
                <option value="">Loading subcategories...</option>
              )}
            </Form.Select>
          </div> */}
        </div>

        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
     
          <CancelButton path="/descriptionType" />
        </div>
      </form>
    </div>
  );
}

export default EditDescriptionType;
