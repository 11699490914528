import React, { useState } from "react";
import "../Headers/Sidebar/Sidebar.scss";
import product_icon from "../../Assets/Images/product-icon.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IoTicketOutline } from "react-icons/io5";

function CouponItems() {
  const [menuOpenCoupen, setMenuOpenCoupen] = useState(false);

  return (
    <div className="text-decoration-none">
      <li
        onClick={() => setMenuOpenCoupen(!menuOpenCoupen)}
        aria-controls="example-collapse-text"
        aria-expanded={menuOpenCoupen}
      >
        <div className="d-flex">
          {/* <img className="sidebar-icons" src={product_icon} /> */}<IoTicketOutline className="me-2" />

          <div className="sidebar-text">Coupon</div>
        </div>
        {menuOpenCoupen === false ? (
          <IoIosArrowForward className="sidebar-menu-icon" />
        ) : (
          <IoIosArrowDown className="sidebar-menu-icon" />
        )}
      </li>

      <Collapse in={menuOpenCoupen}>
        <div id="example-collapse-text">
          <Link
            className="text-dark text-decoration-none"
            style={{ fontSize: "13px" }}
            to="/addCoupen"
          >
            <li className="mx-2">Add New Coupen</li>
          </Link>
          <Link
            className="text-dark text-decoration-none"
            style={{ fontSize: "13px" }}
            to="/editCoupen"
          >
            <li className="mx-2">Edit Coupen</li>
          </Link>
        </div>
      </Collapse>
    </div>
  );
}

export default CouponItems;