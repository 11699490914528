// import React, { useEffect, useState } from "react";
// import { Link, useHistory } from "react-router-dom";
// import "../Categorys/categorys.scss";
// import axios from "axios";
// import { AiFillEye } from "react-icons/ai";
// import { AiFillDelete } from "react-icons/ai";
// import { FiEdit } from "react-icons/fi";
// import { BsToggleOn, BsToggleOff } from "react-icons/bs";
// import {API_BASE_URL} from "../../../utils/index"


// function UnderSubProductCategory() {
//   const [subCategories, setSubCategories] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [underSubProductCategories, setUnderSubProductCategories] = useState(
//     []
//   );

//   useEffect(() => {
//     // Fetch category data
//     axios
//       .get(`${API_BASE_URL}/category/admin/getcategory`)
//       .then((response) => {
//         setCategories(response?.data?.categories);
//       })
//       .catch((error) => {
//         console.error("Error fetching category data:", error);
//       });

//     // Fetch subcategory data
//     axios
//       .get(`${API_BASE_URL}/subcategory/admin/getsubcategory`)
//       .then((response) => {
//         setSubCategories(response?.data?.subcategory);
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategory data:", error);
//       });

//     // Fetch under sub product category data
//     axios
//       .get(`${API_BASE_URL}/undersubcategory/admin/getundersubcategory`)
//       .then((response) => {
//         setUnderSubProductCategories(response?.data?.undersubcategories);
//         // console.log(response.data.undersubcategories,"setUnderSubProductCategories loki")
//       })
//       .catch((error) => {
//         console.error("Error fetching Under Sub Product Category data:", error);
//       });
//   }, []);

//   // Function to delete a category start
//   const deleteCategory = (categoryId) => {
//     const shouldDelete = window.confirm(
//       "Are you sure you want to delete this Under Sub Category?"
//     );

//     if (shouldDelete) {
//       axios
//         .delete(
//           `${API_BASE_URL}/undersubcategory/admin/deleteundersubcategory/${categoryId}`
//         )
//         .then(() => {
//           setUnderSubProductCategories((prevData) =>
//             prevData?.filter((item) => item?._id !== categoryId)
//           );
//         })
//         .catch((error) => {
//           console.error("Error deleting Under Sub Category:", error);

//           if (error.response) {
//             // Log the response data and status code for more information
//             console.error("Response data:", error?.response.data);
//             console.error("Response status:", error?.response.status);
//           }
//         });
//     }
//   };
//   // // Function to delete a category end

//   const toggleCategoryStatus = (categoryId, isActive) => {
//     // Make a PUT request to your API to update the category status
//     axios
//       .put(`${API_BASE_URL}/undersubcategory/admin/status/${categoryId}`, {
//         isActive: !isActive, // Toggle the active status
//       })
//       .then(() => {
//         // Update the local state to reflect the change
//         setUnderSubProductCategories((prevData) =>
//           prevData.map((item) =>
//             item._id === categoryId ? { ...item, isActive: !isActive } : item
//           )
//         );
//       })
//       .catch((error) => {
//         console.error("Error toggling category status:", error);

//         if (error.response) {
//           // Log the response data and status code for more information
//           console.error("Response data:", error.response.data);
//           console.error("Response status:", error.response.status);
//         }
//       });
//   };

//   return (
//     <div className="p-4">
//       <div className="d-flex justify-content-between my-2">
//         <h2 className="m-0">Description</h2>
//         <Link to="/underSubCategory" className="btn btn-primary">
//           Add New
//         </Link>
//       </div>
//       <table className="categorys-table" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
//         <thead>
//           <tr>
//             <th>Description Name</th>
//             <th>Description type</th>
//             <th>Description Method</th>
//             <th>Sub Categories</th>
//             <th>Categories</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {underSubProductCategories && underSubProductCategories?.length > 0 ? (
//             underSubProductCategories.map((underSubCategory, index) => (
//               // console.log(underSubCategory,"underSubCategory test")
//               <tr key={index}>
//                 <td>{underSubCategory?.undersubcategoryname}</td>

//                 <td>{underSubCategory?.discriptiontypes}</td>

//                 <td>{underSubCategory?.discriptionmethod}</td>

//                 <td>
//                   {subCategories.map((subCategory) => {
//                     console.log(subCategory, "subCategory test");
//                     if (subCategory?.name === underSubCategory?.subcategoryname) {
//                       return subCategory?.name;
//                     }
//                     return null;
//                   })}
//                 </td>

//                 <td>
//                   {categories.map((category) => {
//                     if (category?.name === underSubCategory?.category) {
//                       return category?.name;
//                     }
//                     return null;
//                   })}
//                 </td>

//                 <td>
//                   <Link to={`/underSubCategorysDetial/${underSubCategory?._id}`}>
//                     <button className="mx-1">
//                       <AiFillEye className="m-2 fs-6" />
//                     </button>
//                   </Link>

//                   <button
//                     className="mx-1"
//                     onClick={() => deleteCategory(underSubCategory?._id)}
//                   >
//                     <AiFillDelete className="m-2 fs-6" />
//                   </button>

//                   <Link
//                     to={`/editUnderSubProductCategory/${underSubCategory?._id}`}
//                   >
//                     <button className="mx-1">
//                       <FiEdit className="m-2 fs-6" />
//                     </button>
//                   </Link>

//                   <button
//                     className="mx-1"
//                     onClick={() =>
//                       toggleCategoryStatus(
//                         underSubCategory?._id,
//                         underSubCategory?.isActive
//                       )
//                     }
//                   >
//                     {underSubCategory?.isActive ? (
//                       <BsToggleOn className="m-2 fs-6" />
//                     ) : (
//                       <BsToggleOff className="m-2 fs-6" />
//                     )}
//                   </button>
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="3">No Under subcategories found</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default UnderSubProductCategory;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2";

function UnderSubProductCategory() {
  const adminId = localStorage.getItem("adminId");
  const [categories, setCategories] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hovered item


  const headers = {
    Authorization: `Bearer ${adminId}`,
  };


  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/offerposter`, { headers })
      .then((response) => {
        setCategories(response?.data?.posters);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/offerposter/${categoryId}`, { headers })
          .then(() => {
            setCategories((prevData) =>
              prevData.filter((item) => item._id !== categoryId)
            );
            Swal.fire("Deleted!", "Your Category has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting category:", error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.error,
            });
          });
      }
    });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Poster</h2>
        <Link to="/offerpost" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table
        className="categorys-table"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <thead>
          <tr>
            <th>Poster Image</th>
            <th>Category</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {categories.length > 0 ? (
            categories.map((underSubCategory, index) => (
              <tr
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <td>
                  <div className="poster-container">
                    {underSubCategory.offerposters.map((poster, i) => (
                      <img
                        key={i}
                        src={poster.offerposter}
                        alt=""
                        width="100px"
                        className={
                          i === 0 || hoveredIndex === index
                            ? "poster-image"
                            : "poster-image hidden"
                        }
                      />
                    ))}
                  </div>
                </td>
                <td>{underSubCategory?.Category?.name}</td>
                <td>
                  <button
                    className="mx-1 border-0 bg-white"
                    onClick={() => deleteCategory(underSubCategory._id)}
                  >
                    <AiFillDelete className="m-2 fs-3" />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No Under subcategories found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default UnderSubProductCategory;