import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../../utils/index";
import BackArrow from "../../../../components/MinorComponents/BackArrow";

function DescriptionTypeDetial() {
  const { _id } = useParams();

  const [descriptionTypeDetial, setDescriptionTypeDetial] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/description/admin/singledescription/${_id}`)
      .then((response) => {
        setDescriptionTypeDetial(response?.data?.description); // Update the category state
        // console.log(response.data.description, "singledescription 1234");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <BackArrow path="/descriptionType" />
      <h2>Description Type Detial</h2>
      <h6>Description Name:</h6>
      <p>{descriptionTypeDetial?.descriptionName}</p>

      <h6>Sub Category Name:</h6>
      <p>{descriptionTypeDetial?.subcategory?.name}</p>

      <h6>Category Name:</h6>
      <p>{descriptionTypeDetial?.category?.name}</p>
      <h6>Created At</h6>
      <p>{descriptionTypeDetial?.createdAt}</p>
    </div>
  );
}

export default DescriptionTypeDetial;
