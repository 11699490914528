import React, { useState } from "react";
import "../Headers/Sidebar/Sidebar.scss";
import product_icon from "../../Assets/Images/product-icon.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { LiaShippingFastSolid } from "react-icons/lia";

function ShippingItems() {
  const [menuOpenShipping, setMenuOpenShipping] = useState(false);
  const [zone, setZone] = useState(false);
  const [zoneRegion, setZoneRegion] = useState(false);
  const [shipping, setShipping] = useState(false);

  return (
    <div className="text-decoration-none">
      <li onClick={() => setMenuOpenShipping(!menuOpenShipping)}>
        <div className="d-flex">
          {/* <img className="sidebar-icons" src={product_icon} /> */}<LiaShippingFastSolid  className="me-2" />

          <div className="sidebar-text">Shipping</div>
        </div>
        {menuOpenShipping === false ? (
          <IoIosArrowForward className="sidebar-menu-icon" />
        ) : (
          <IoIosArrowDown className="sidebar-menu-icon" />
        )}
      </li>

      <Collapse in={menuOpenShipping}>
        <div id="example-collapse-text" className="transition-container">
          <>
            <li className="mx-2" onClick={() => setZone(!zone)}>
              <div className="sidebar-text">New Zone</div>
              {zone === false ? (
                <IoIosArrowForward className="sidebar-menu-icon" />
              ) : (
                <IoIosArrowDown className="sidebar-menu-icon" />
              )}
            </li>

            {zone && (
              <>
                <li className="mx-2">
                  <Link
                    to="/addNewZone"
                    className={"text-dark text-decoration-none mx-2"}
                    style={{ fontSize: "13px" }}
                  >
                    Add New Zone
                  </Link>
                </li>

                <li className="mx-2">
                  <Link
                    to="/editNewZone"
                    className={"text-dark text-decoration-none mx-2"}
                    style={{ fontSize: "13px" }}
                  >
                    Edit Zone
                  </Link>
                </li>
              </>
            )}
          </>
          <>
            <li className="mx-2" onClick={() => setZoneRegion(!zoneRegion)}>
              <div className="sidebar-text">Shipping</div>
              {zoneRegion === false ? (
                <IoIosArrowForward className="sidebar-menu-icon" />
              ) : (
                <IoIosArrowDown className="sidebar-menu-icon" />
              )}
            </li>

            {zoneRegion && (
              <>
                <li className="mx-2">
                  <Link
                    to="/addNewZoneRegion"
                    className={"text-dark text-decoration-none mx-2"}
                    style={{ fontSize: "13px" }}
                  >
                    Add Shipping
                  </Link>
                </li>

                <li className="mx-2">
                  <Link
                    to="/editNewZoneRegion"
                    className={"text-dark text-decoration-none mx-2"}
                    style={{ fontSize: "13px" }}
                  >
                    Edit Shipping
                  </Link>
                </li>
              </>
            )}
          </>

          {/* <>
            <li className="mx-2" onClick={() => setShipping(!shipping)}>
              <div className="sidebar-text">Shipping</div>
              {shipping === false ? (
                <IoIosArrowForward className="sidebar-menu-icon" />
              ) : (
                <IoIosArrowDown className="sidebar-menu-icon" />
              )}
            </li>

            {shipping && (
              <>
                <li className="mx-2">
                  <Link
                    to="/addShipping"
                    className={"text-dark text-decoration-none mx-2"}
                    style={{ fontSize: "13px" }}
                  >
                    Add Shipping
                  </Link>
                </li>

                <li className="mx-2">
                  <Link
                    to="/editShipping"
                    className={"text-dark text-decoration-none mx-2"}
                    style={{ fontSize: "13px" }}
                  >
                    Edit Shipping
                  </Link>
                </li>
              </>
            )}
          </> */}
        </div>
      </Collapse>
    </div>
  );
}

export default ShippingItems;