import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../components/MinorComponents/CancelButton";


function FilterEdit() {
  const { _id } = useParams();
  const [single, setSingle] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  // const [selectedCategories, setSelectedCategories] = useState("");
  // const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    try{setLoading(true)
    
    axios
      .get(`${API_BASE_URL}/admin/singlefilter/${_id}`, { headers })
      .then((response) => {
        // setSelectedCategories(response.data.filter.category);
        setSingle(response?.data?.filter?.name);
        setCategory(response?.data?.filter?.category);
        setSubCategory(response?.data?.filter?.subcategory);
        console.log("response.data.filter", response?.data?.filter);
      })
      .catch((error) => {
        console.error(error);
      });

    // axios
    //   .get(`${API_BASE_URL}/category/admin/getcategory`)
    //   .then((response) => {
    //     setCategories(response.data.categories);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching category data:", error);
    //   });
  }finally{setLoading(false)}
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const data = { name: single };
    axios
      .put(`${API_BASE_URL}/admin/updatefilter/${_id}`, data, {
        headers,
      })
      .then((response) => {
        // console.log("response :", response);
        navigate("/filtersDetials");
      })
      .catch((error) => {
        Swal.fire({
          title: "The Internet?",
          text: error.response.data.message,
          icon: "question",
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Filter</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          {/* <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Select Categorie:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategories} 
              required
              onChange={(e) => setSelectedCategories(e.target.value)}
            > 
              <option value="">Select a Filter</option>
              {categories.map((category) => (
                // console.log(category,"category")
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div> */}

          <div className="d-flex align-items-center py-2">
            <h6 className="m-0">Category :</h6>
            <p className="m-0 px-2">{category.name}</p>
          </div>

          <div className="d-flex align-items-center py-2">
            <h6 className="m-0">Sub Category :</h6>
            <p className="m-0 px-2">{subCategory.name}</p>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Change Filter:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={single}
              // required
              onChange={(e) => setSingle(e.target.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/filtersDetials" />
        </div>
      </form>
    </div>
  );
}

export default FilterEdit;
