import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import EditNewProductsFields from "./EditNewProductsFields";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CancelButton from "../../../components/MinorComponents/CancelButton";
import { Spinner } from "react-bootstrap";

function EditNewProducts() {
  const { _id } = useParams();

  const [productPartNumberEdit, setProductPartNumberEdit] = useState("");

  // const [ProductsImagesPreview, setProductsImagesPreview] = useState([]);
  // const [productsImages, setProductImage] = useState([]);

  const [existingImages, setExistingImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [newImagesPreview, setNewImagesPreview] = useState([]);

  // const [ProductsImagesPreview, setProductsImagesPreview] = useState([]);//newly added

  const [currentCategories, setCurrentCategories] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sumbitCategory, setSumbitCategory] = useState("");

  const [subCategoryId, setSubCategoryId] = useState([]);
  const [currentSubCategories, setCurrentSubCategories] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [sumbitSubCategory, setSumbitSubCategory] = useState("");

  const [manuFacturerEdit, setManuFacturerEdit] = useState("");

  const [oldAmount, setOldAmount] = useState();

  const [salePrice, setSalelPrice] = useState("");

  const [weightPerPiece, setWeightPerPiece] = useState("");

  const [boxOfQuantity, setBoxOfQuantity] = useState("");

  const [originalPrice, setOriginalPrice] = useState("");

  const [productWeight, setProductWeight] = useState("");

  const [products, setProducts] = useState("");

  const [quantity, setQuantity] = useState("");

  const [metaTitle, setmetaTitle] = useState("");

  const [dealofDay, setDealofDay] = useState(false);

  const [currentApidata, setCurrentApidata] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const [writeNotes, setWriteNotes] = useState("");

  const headers = useAuthHeaders();

  const navigate = useNavigate();

  const [descriptions, setDescriptions] = useState([]);

  const [discriptionType, setDiscriptionType] = useState("");
  const [selectDiscriptionType, setSelectDiscriptionType] = useState([]);
  const [updatedDescriptions, setUpdatedDescriptions] = useState([]);
  const defaultData = [{}];
  const [manualInput, setManualInput] = useState(defaultData);
  // const [updatedDescriptions, setUpdatedDescriptions] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/products/adminsingleproduct/${_id}`)
      .then((response) => {
        const dealExpiryDate = response.data.product?.dealExpiryDate;
        console.log("singleProduct  ---------->", response.data.product[0]);

        if (dealExpiryDate) {
          const dateObject = new Date(dealExpiryDate);
          setSelectedDate(dateObject);
        }
        //  else {
        //   setSelectedDate(null);
        // }
        setProductPartNumberEdit(response.data.product.partNumber);

        // setProductsImagesPreview(response.data.product.productimages);

        setExistingImages(response.data.product.productimages);

        setCurrentCategories(response.data.product.category);
        console.log("cateory finding in the editnewProduct",response.data.product)
        setSelectedCategory(response.data.product.category);

        setSubCategoryId(response.data.product?.subcategoryname?._id);
        // console.log("object--------------------------------->",response.data.product?.subcategoryname?._id)
        setCurrentSubCategories(response.data.product.subcategoryname);
        setSelectedSubCategory(response.data.product.subcategoryname);

        setManuFacturerEdit(response.data.product.manufacturer);

        setOldAmount(response.data.product?.oldprice);

        setSalelPrice(response.data.product.saleprice);

        setWeightPerPiece(response.data.product.weightperpiece);

        setBoxOfQuantity(response.data.product.boq);

        setOriginalPrice(response.data.product?.Productprice);

        setProductWeight(response.data.product?.productweight);

        setProducts(response.data.product.product);

        setQuantity(response.data.product.quantity);

        setmetaTitle(response.data.product.metatitle);

        setDealofDay(response.data.product.dealofday);

        setCurrentApidata(response.data.product.dealExpiryDate);

        setWriteNotes(response.data.product.writenotes);

        const initialDescriptions = response.data.product.descriptions || [];
        setDescriptions(initialDescriptions);
        setUpdatedDescriptions(initialDescriptions.map((desc) => desc.value));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  //*************************************************************** */
  //*************************************************************** */

  // console.log("submit category ",sumbitCategory?sumbitCategory:currentCategories)
  // console.log("submit subcategory ",sumbitSubCategory?sumbitSubCategory:currentSubCategories)

  // console.log("update description is ------------> ",updatedDescriptions)
  // console.log("Update description in the form ok entries --------->",Object.entries(updatedDescriptions))

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  //************************************************************ */
  //************************************************************ */
  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/admin/getdescriptionnametypesbysubcategory/${subCategoryId}`
      )
      .then((response) => {
        // console.log("description", response.data.descriptionnames);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  }, [subCategoryId]);

  // const onImagesChange = (e) => {
  //     const files = Array.from(e.target.files);

  //     files.forEach((file) => {
  //       const reader = new FileReader();

  //       reader.onload = () => {
  //         if (reader.readyState == 2) {

  //           setProductsImagesPreview((oldArray) => [...oldArray, reader.result]);
  //           setProductImage((oldArray) => [...oldArray, file]);
  //         }
  //       };

  //       reader.readAsDataURL(file);
  //     });
  //   };

  // const onImagesChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   files.forEach((file) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setProductsImagesPreview((oldArray) => [...oldArray, reader.result]);
  //         setProductImage((oldArray) => [...oldArray, file]);
  //       }
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };

  const onImagesChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setNewImagesPreview((oldArray) => [...oldArray, reader.result]);
          setNewImages((oldArray) => [...oldArray, file]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  // const removeImage = (index) => {
  //   setProductsImagesPreview((prevImages) =>
  //     prevImages.filter((_, i) => i !== index)
  //   );
  //   setProductImage((prevImages) => prevImages.filter((_, i) => i !== index));

  //   const inputElement = document.getElementById("productimages");

  //   if (inputElement) {
  //     inputElement.value = null;
  //   }

  // };

  // const removeImage = (index) => {
  //   setProductsImagesPreview((prevImages) =>
  //     prevImages.filter((_, i) => i !== index)
  //   );
  //   setProductImage((prevImages) => prevImages.filter((_, i) => i !== index));

  //   const inputElement = document.getElementById("productimages");
  //   if (inputElement) {
  //     inputElement.value = null;
  //   }
  // };

  const removeExistingImage = (index) => {
    const imageToRemove = existingImages[index].productimage;

    console.log("image Remove")
    axios
      .delete(`${API_BASE_URL}/products/${_id}/images`, {
        headers,
        data: { imageUrl: imageToRemove }
      })
    
      .then((response) => {
        setExistingImages((prevImages) =>
          prevImages.filter((_, i) => i !== index)
        );
        console.log("Image removed from backend:", response.data);
      })
      .catch((error) => {
        console.error("Error removing image from backend:", error);
      });
  };

  const removeNewImage = (index) => {
    setNewImagesPreview((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
    setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));

    const inputElement = document.getElementById("productimages");
    if (inputElement) {
      inputElement.value = null;
    }
  };

  // const handleSave = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();

  //   const data = {
  //     partNumber: productPartNumberEdit,
  //     manufacturer: manuFacturerEdit,
  //     weightperpiece: weightPerPiece,
  //     productweight: productWeight,
  //     boq: boxOfQuantity,
  //     Productprice: originalPrice,
  //     product: products,
  //     saleprice: salePrice,
  //     quantity: quantity,
  //     metatitle: metaTitle,
  //     writenotes: writeNotes,
  //     dealofday: dealofDay,
  //     oldprice: oldAmount,
  //     dealExpiryDate: selectedDate,
  //     category: sumbitCategory.length > 0 ?  sumbitCategory : currentCategories,
  //     // categoryname: sumbitCategory.length > 0 ?  sumbitCategory : "not a value",
  //     subcategoryname:  sumbitSubCategory.length > 0 ?  sumbitSubCategory : currentSubCategories,

  //   };

  //   if(Object.keys(updatedDescriptions).length > 0){
  //     data.descriptions=     updatedDescriptions.map((value, index) => ({
  //       name: descriptions[index].name,
  //       value: value,
  //     }))
  //   }else{

  //     const formattedDescriptions = Object.entries(selectDiscriptionType).map(
  //       ([name, value]) => ({
  //         name: name ?? "Default Name",
  //         value: value ?? "Default Value",
  //       })
  //     );

  //     console.log("formattedDescriptions  ----------->",formattedDescriptions)

  //     formattedDescriptions.forEach((description, index) => {
  //       formData.append(
  //         `descriptions[${index}][name]`,
  //         description.name || "loki"
  //       );

  //       formData.append(
  //         `descriptions[${index}][value]`,
  //         description.value || "loki"
  //       );
  //     });

  //     const validManualInputs = manualInput.filter((input) => {
  //       return (
  //         input &&
  //         typeof input === "object" &&
  //         Object.values(input).some((value) => value.trim() !== "")
  //       );
  //     });

  //     let manualInputIndex = formattedDescriptions.length;

  //     const formattedDescriptionsManualInput = validManualInputs.map(
  //       (item, index) => {
  //         if (item && typeof item === "object") {
  //           const keys = Object.keys(item);
  //           const values = Object.values(item);

  //           return {
  //             name: keys.length > 0 ? keys[0] : "Default Name",
  //             value: values.length > 0 ? values[0] : "Default Value",
  //           };
  //         }

  //         return {
  //           name: "Default Name",
  //           value: "Default Value",
  //         };
  //       }
  //     );
  // console.log("Manual description  --------->",formattedDescriptionsManualInput)
  //     formattedDescriptionsManualInput.forEach((value, index) => {
  //       const nameToAppend = value.name || "";
  //       const valueToAppend = value.value || "";

  //       if (nameToAppend.trim() !== "") {
  //         formData.append(
  //           `descriptions[${manualInputIndex + index}][name]`,
  //           nameToAppend
  //         );
  //         formData.append(
  //           `descriptions[${manualInputIndex + index}][value]`,
  //           valueToAppend
  //         );
  //       }
  //     });

  //     if (formData.get("Empty")) {
  //       formData.delete("Empty");
  //     }

  //   }

  //   if (productsImages) {
  //     data.productImage = productsImages;
  //   }

  //   productsImages.forEach((productsImage) => {
  //     formData.append("productimages", productsImage);
  //   });

  //   setLoading(true);

  //   axios
  //     .put(`${API_BASE_URL}/admin/products/updateproduct/${_id}`, data, {
  //       headers,
  //     })
  //     .then((response) => {
  //       console.log("response updateproduct:", response);
  //       Swal.fire({
  //         icon: "success",
  //         title: "Product Updated",
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });
  //       navigate("/newproductsViewPage");
  //     })
  //     .catch((error) => {
  //       console.error("Error updating category:", error);
  //       setLoading(false);

  //       if (error.response.status === 401) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops...",
  //           text: error.response.data.error,
  //         });
  //       }
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: error.response.data.message,
  //       });
  //     });
  // };

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const data = {
      partNumber: productPartNumberEdit,
      manufacturer: manuFacturerEdit,
      weightperpiece: weightPerPiece,
      productweight: productWeight,
      boq: boxOfQuantity,
      Productprice: originalPrice,
      product: products,
      saleprice: salePrice,
      quantity: quantity,
      metatitle: metaTitle,
      writenotes: writeNotes,
      dealofday: dealofDay,
      oldprice: oldAmount,
      dealExpiryDate: selectedDate,
      category: sumbitCategory.length > 0 ? sumbitCategory : currentCategories,
      subcategoryname:
        sumbitSubCategory.length > 0 ? sumbitSubCategory : currentSubCategories,
    };

    if (Object.keys(updatedDescriptions).length > 0) {
      data.descriptions = updatedDescriptions?.map((value, index) => ({
        name: descriptions[index].name,
        value: value,
      }));
    } else {
      const formattedDescriptions = Object.entries(selectDiscriptionType).map(
        ([name, value]) => ({
          name: name ?? "Default Name",
          value: value ?? "Default Value",
        })
      );

      console.log("formattedDescriptions  ----------->", formattedDescriptions);

      const validManualInputs = manualInput.filter((input) => {
        return (
          input &&
          typeof input === "object" &&
          Object.values(input).some((value) => value.trim() !== "")
        );
      });

      const formattedDescriptionsManualInput = validManualInputs.map((item) => {
        if (item && typeof item === "object") {
          const keys = Object.keys(item);
          const values = Object.values(item);

          return {
            name: keys.length > 0 ? keys[0] : "Default Name",
            value: values.length > 0 ? values[0] : "Default Value",
          };
        }

        return {
          name: "Default Name",
          value: "Default Value",
        };
      });

      console.log(
        "Manual description  --------->",
        formattedDescriptionsManualInput
      );

      const allDescriptions = [
        ...formattedDescriptions,
        ...formattedDescriptionsManualInput,
      ];
      data.descriptions = allDescriptions;
    }

    // if (productsImages) {
    //   data.productImage = productsImages;
    // }

    // productsImages.forEach((productsimage) => {
    //   formData.append("productimages", productsimage);
    // });

    if (newImages) {
      data.productImage = newImages;
    }

    newImages.forEach((image) => {
      formData.append("productimages", image);
    });

    // Append all data to formData
    Object.keys(data).forEach((key) => {
      if (key === "descriptions") {
        data.descriptions.forEach((desc, index) => {
          formData.append(`descriptions[${index}][name]`, desc?.name);
          formData.append(`descriptions[${index}][value]`, desc?.value);
        });
      } else {
        formData.append(key, data[key]);
      }
    });

    setLoading(true);

    axios
      .put(`${API_BASE_URL}/admin/products/updateproduct/${_id}`, formData, {
        headers,
      })
      .then((response) => {
        console.log("response updateproduct:", response);
        Swal.fire({
          icon: "success",
          title: "Product Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/newproductsViewPage");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        setLoading(false);

        if (error.response.status === 401) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
          });
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  //===================== Newly Added method   =================================

  const handleCategorySelect = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategory(selectedCategory);

    const findCategoryName = categories.find(
      (findCat) => findCat._id === selectedCategory
    );

    setSumbitCategory(findCategoryName.name);

    axios
      .get(`${API_BASE_URL}/category/admin/${selectedCategory}`)
      .then((response) => {
        setSubCategory(response.data.subcategoryNames);
        // console.log("New subcategory :" , response.data.subcategories);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  const handleSubCategorySelect = (e) => {
    const selectedSubCategory = e.target.value;
    setSelectedSubCategory(selectedSubCategory);

    const findSubCategory = subCategory.find(
      (finSub) => finSub._id === selectedSubCategory
    );
    setSumbitSubCategory(findSubCategory.name);

    axios
      .get(
        `${API_BASE_URL}/admin/getdescriptionnametypesbysubcategory/${selectedSubCategory}`
      )
      .then((response) => {
        setDiscriptionType(response?.data?.descriptionnames);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };
  const handleChange = (ele) => {
    const { name, value } = ele.target;
    setSelectDiscriptionType((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="p-3">
      <h2>Edit Product</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <EditNewProductsFields
          productPartNumberEdit={productPartNumberEdit}
          setProductPartNumberEdit={setProductPartNumberEdit}
          productId={_id}
         
          existingImages={existingImages}
          newImagesPreview={newImagesPreview}
          onImagesChange={onImagesChange}
          removeExistingImage={removeExistingImage}
          removeNewImage={removeNewImage}
          //==================================================================
          //==================================================================
          //==================================================================
          currentCategories={currentCategories} //old
          categories={categories}
          selectedCategory={selectedCategory} //old
          setSelectedCategory={setSelectedCategory} //old
          handleCategorySelect={handleCategorySelect} //new
          currentSubCategories={currentSubCategories} //old
          subCategory={subCategory}
          selectedSubCategory={selectedSubCategory} //old
          setSelectedSubCategory={setSelectedSubCategory} //old
          handleSubCategorySelect={handleSubCategorySelect} //new
          //========================================================
          //========================================================
          //========================================================
          manuFacturerEdit={manuFacturerEdit}
          setManuFacturerEdit={setManuFacturerEdit}
          oldAmount={oldAmount}
          setOldAmount={setOldAmount}
          setSalelPrice={setSalelPrice}
          salePrice={salePrice}
          weightPerPiece={weightPerPiece}
          setWeightPerPiece={setWeightPerPiece}
          boxOfQuantity={boxOfQuantity}
          setBoxOfQuantity={setBoxOfQuantity}
          originalPrice={originalPrice}
          setOriginalPrice={setOriginalPrice}
          productWeight={productWeight}
          setProductWeight={setProductWeight}
          products={products}
          setProducts={setProducts}
          quantity={quantity}
          setQuantity={setQuantity}
          metaTitle={metaTitle}
          setmetaTitle={setmetaTitle}
          setDealofDay={setDealofDay}
          dealofDay={dealofDay}
          currentApidata={currentApidata}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          writeNotes={writeNotes}
          setWriteNotes={setWriteNotes}
          //===========================================================
          //===========================================================
          //===========================================================

          descriptions={descriptions}
          setUpdatedDescriptions={setUpdatedDescriptions}
          updatedDescriptions={updatedDescriptions}
          discriptionType={discriptionType}
          handleChange={handleChange}
          setManualInput={setManualInput}
          manualInput={manualInput}
        />
        <div className="buttons">
          {loading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          )}

          <CancelButton path="/newproductsViewPage" />
        </div>
      </form>
    </div>
  );
}

export default EditNewProducts;
