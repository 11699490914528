// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import { AiFillDelete } from "react-icons/ai";
// import { API_BASE_URL } from "../../../utils/index";
// import Swal from "sweetalert2";
// import Form from "react-bootstrap/Form";
// import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap

// function Editblog() {
//   const adminId = localStorage.getItem("adminId");
//   const [categories, setCategories] = useState([]);
//   const [posters, setPosters] = useState([]);
//   const [hoveredIndex, setHoveredIndex] = useState(null);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [editModalShow, setEditModalShow] = useState(false); // State to control modal visibility
//   const [currentPoster, setCurrentPoster] = useState(null); // State to hold the poster being edited
//   const headers = { Authorization: `Bearer ${adminId}` };

//   // Fetch all categories
//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/admin/getcategory`)
//       .then((response) => {
//         setCategories(response.data.categories || []);
//       })
//       .catch((error) => {
//         console.error("Error fetching category data:", error);
//       });
//   }, []);

//   // Fetch posters based on selected category
//   useEffect(() => {
//     if (!selectedCategory) return;

//     axios
//       .get(`${API_BASE_URL}/blogs/${selectedCategory}`, { headers })
//       .then((response) => {
//         setPosters(response.data.blogs || []);
//       })
//       .catch((error) => {
//         console.error("Error fetching posters:", error);
//       });
//   }, [selectedCategory]);

//   // Delete poster function
//   const deletePoster = (id) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Are you sure you want to delete this poster?",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         axios
//           .delete(`${API_BASE_URL}/posters/${id}`, { headers })
//           .then(() => {
//             setPosters((prevData) =>
//               prevData.filter((poster) => poster._id !== id)
//             );
//             Swal.fire("Deleted!", "The poster has been deleted.", "success");
//           })
//           .catch((error) => {
//             console.error("Error deleting poster:", error);
//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: error?.response?.data?.error || "Something went wrong!",
//             });
//           });
//       }
//     });
//   };

//   // Show edit modal with selected poster data
//   const handleEditClick = (poster) => {
//     setCurrentPoster(poster);
//     setEditModalShow(true);
//   };

//   // Close the edit modal
//   const handleCloseModal = () => {
//     setEditModalShow(false);
//     setCurrentPoster(null);
//   };

//   // Handle form submission for editing blog
//   const handleEditSubmit = (event) => {
//     event.preventDefault();

//     axios
//       .put(`${API_BASE_URL}/blogs/${currentPoster._id}`, currentPoster, {
//         headers,
//       })
//       .then(() => {
//         Swal.fire("Updated!", "The blog has been updated.", "success");
//         setPosters((prevPosters) =>
//           prevPosters.map((poster) =>
//             poster._id === currentPoster._id ? currentPoster : poster
//           )
//         );
//         handleCloseModal();
//       })
//       .catch((error) => {
//         console.error("Error updating blog:", error);
//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error?.response?.data?.error || "Something went wrong!",
//         });
//       });
//   };

//   return (
//     <div className="p-4">
//       <div className="d-flex justify-content-between my-2">
//         <h2 className="m-0">Poster</h2>
//         <div className="col-md-6">
//           <label htmlFor="category" className="fs-6">
//             Category Name:
//           </label>
//           <Form.Select
//             id="category"
//             aria-label="Select Category"
//             className="my-2"
//             value={selectedCategory}
//             onChange={(e) => setSelectedCategory(e.target.value)}
//           >
//             <option value="">Select Category</option>
//             {categories.map((category) => (
//               <option key={category._id} value={category._id}>
//                 {category.name}
//               </option>
//             ))}
//           </Form.Select>
//         </div>
//         <Link to="/offerpost" className="btn btn-primary">
//           Add New
//         </Link>
//       </div>
//       <table
//         className="categorys-table"
//         style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
//       >
//         <thead>
//           <tr>
//             <th>Blog Image</th>
//             <th>author</th>
//             <th>title</th>
//             <th>content</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {Array.isArray(posters) && posters.length > 0 ? (
//             posters.map((poster, index) => (
//               <tr
//                 key={poster._id}
//                 onMouseEnter={() => setHoveredIndex(index)}
//                 onMouseLeave={() => setHoveredIndex(null)}
//               >
//                 <td>
//                   <div className="poster-container">
//                     <img
//                       key={poster._id}
//                       src={poster.blogimage}
//                       alt=""
//                       width="100px"
//                       className={
//                         index === 0 || hoveredIndex === index
//                           ? "poster-image"
//                           : "poster-image hidden"
//                       }
//                     />
//                   </div>
//                 </td>
//                 <td>{poster.author}</td>
//                 <td>{poster.title}</td>
//                 <td>{poster.content}</td>
//                 <td>
//                   <button
//                     className="mx-1 border-0 bg-white"
//                     onClick={() => handleEditClick(poster)}
//                   >
//                     Edit
//                   </button>
//                   <button
//                     className="mx-1 border-0 bg-white"
//                     onClick={() => deletePoster(poster._id)}
//                   >
//                     <AiFillDelete className="m-2 fs-3" />
//                   </button>
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="5">No Posters found</td>
//             </tr>
//           )}
//         </tbody>
//       </table>

//       {/* Edit Modal */}
//       {currentPoster && (
//         <Modal show={editModalShow} onHide={handleCloseModal}>
//           <Modal.Header closeButton>
//             <Modal.Title>Edit Blog Post</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form onSubmit={handleEditSubmit}>
//               <Form.Group controlId="editCategory">
//                 <Form.Label>Category Name:</Form.Label>
//                 <Form.Select
//                   id="category"
//                   aria-label="Select Category"
//                   className="my-2"
//                   value={selectedCategory}
//                   onChange={(e) => setSelectedCategory(e.target.value)}
//                 >
//                   <option value="">Select Category</option>
//                   {categories.map((category) => (
//                     <option key={category._id} value={category._id}>
//                       {category.name}
//                     </option>
//                   ))}
//                 </Form.Select>
//               </Form.Group>
//               <Form.Group controlId="editTitle">
//                 <Form.Label>Title:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={currentPoster.title}
//                   onChange={(e) =>
//                     setCurrentPoster({
//                       ...currentPoster,
//                       title: e.target.value,
//                     })
//                   }
//                 />
//               </Form.Group>
//               <Form.Group controlId="editContent">
//                 <Form.Label>Content:</Form.Label>
//                 <Form.Control
//                   as="textarea"
//                   rows={3}
//                   value={currentPoster.content}
//                   onChange={(e) =>
//                     setCurrentPoster({
//                       ...currentPoster,
//                       content: e.target.value,
//                     })
//                   }
//                 />
//               </Form.Group>
//               <Form.Group controlId="editAuthor">
//                 <Form.Label>Author:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={currentPoster.author}
//                   onChange={(e) =>
//                     setCurrentPoster({
//                       ...currentPoster,
//                       author: e.target.value,
//                     })
//                   }
//                 />
//               </Form.Group>
//               <Form.Group controlId="editImage">
//                 <Form.Label>Image URL:</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={currentPoster.blogimage}
//                   onChange={(e) =>
//                     setCurrentPoster({
//                       ...currentPoster,
//                       blogimage: e.target.value,
//                     })
//                   }
//                 />
//               </Form.Group>
//               <div className="mt-3">
//                 <button type="submit" className="btn btn-primary">
//                   Save Changes
//                 </button>
//                 <button
//                   type="button"
//                   className="btn btn-secondary ms-2"
//                   onClick={handleCloseModal}
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </Form>
//           </Modal.Body>
//         </Modal>
//       )}
//     </div>
//   );
// }

// export default Editblog;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { AiFillDelete } from "react-icons/ai";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal"; // Import Modal from react-bootstrap
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

function Editblog() {
  const adminId = localStorage.getItem("adminId");
  const [categories, setCategories] = useState([]);
  const [posters, setPosters] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [editModalShow, setEditModalShow] = useState(false); // State to control modal visibility
  const [currentPoster, setCurrentPoster] = useState(null); // State to hold the poster being edited
  const headers = { Authorization: `Bearer ${adminId}` };

  // Fetch all categories
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories || []);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  // Fetch posters based on selected category
  useEffect(() => {
    if (!selectedCategory) return;

    axios
      .get(`${API_BASE_URL}/blogs/${selectedCategory}`, { headers })
      .then((response) => {
        setPosters(response.data.blogs || []);
      })
      .catch((error) => {
        console.error("Error fetching posters:", error);
      });
  }, [selectedCategory]);

  // Delete poster function
  const deletePoster = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this poster?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API_BASE_URL}/blogs/${id}`, { headers })
          .then(() => {
            setPosters((prevData) =>
              prevData.filter((poster) => poster._id !== id)
            );
            Swal.fire("Deleted!", "The poster has been deleted.", "success");
          })
          .catch((error) => {
            console.error("Error deleting poster:", error);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error?.response?.data?.error || "Something went wrong!",
            });
          });
      }
    });
  };

  // Show edit modal with selected poster data
  const handleEditClick = (poster) => {
    setCurrentPoster(poster);
    setEditModalShow(true);
  };
  const truncateContent = (content) => {
    const words = content.split(' ');
    if (words.length <= 25) return content;
    return words.slice(0, 25).join(' ') + '...';
  };
  // Close the edit modal
  const handleCloseModal = () => {
    setEditModalShow(false);
    setCurrentPoster(null);
  };

  // Handle form submission for editing blog
  const handleEditSubmit = (event) => {
    event.preventDefault();

    axios
      .put(`${API_BASE_URL}/blogs/${currentPoster._id}`, currentPoster, {
        headers,
      })
      .then(() => {
        Swal.fire("Updated!", "The blog has been updated.", "success");
        setPosters((prevPosters) =>
          prevPosters.map((poster) =>
            poster._id === currentPoster._id ? currentPoster : poster
          )
        );
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error updating blog:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.response?.data?.error || "Something went wrong!",
        });
      });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Edit blog</h2>
        <div className="">
          {/* <label htmlFor="category" className="fs-6"> */}
            
          {/* </label> */}
          <Form.Select
            id="category"
            aria-label="Select Category"
            className=""
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <Link to="/blog" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table
        className="categorys-table"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <thead>
          <tr>
            <th>Blog Image</th>
            <th>Author</th>
            <th>Title</th>
            <th>Content</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(posters) && posters.length > 0 ? (
            posters.map((poster, index) => (
              <tr
                key={poster._id}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <td>
                  <div className="poster-container">
                    <img
                      key={poster._id}
                      src={poster.blogimage}
                      alt=""
                      width="100px"
                      className={
                        index === 0 || hoveredIndex === index
                          ? "poster-image"
                          : "poster-image hidden"
                      }
                    />
                  </div>
                </td>
                <td>{poster.author}</td>
                <td>{poster.title}</td>
                {/* <td>{poster.content}</td> */}
                <td>{truncateContent(poster.content)}</td> {/* Use truncate function here */}

                <td>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdown-toggle bg-white text-black"
                      id="dropdown-status"
                    >
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                    className=""
                    //   style={{ boxShadow: "0 0 5px rgba(0,0,0,0.3)" }}
                    >
                      <Dropdown.Item onClick={() => handleEditClick(poster)}>
                        <FaEdit className=" fs-3" /> Edit
                    
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deletePoster(poster._id)}>
                        <AiFillDelete className=" fs-3" /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No Posters found</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Edit Modal */}
      {currentPoster && (
        <Modal show={editModalShow} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Blog Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleEditSubmit}>
              <Form.Group controlId="editCategory">
                <Form.Label>Category Name:</Form.Label>
                <Form.Select
                  id="category"
                  aria-label="Select Category"
                  className="my-2"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="editTitle">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  value={currentPoster.title}
                  onChange={(e) =>
                    setCurrentPoster({
                      ...currentPoster,
                      title: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editContent">
                <Form.Label>Content:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={currentPoster.content}
                  onChange={(e) =>
                    setCurrentPoster({
                      ...currentPoster,
                      content: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editAuthor">
                <Form.Label>Author:</Form.Label>
                <Form.Control
                  type="text"
                  value={currentPoster.author}
                  onChange={(e) =>
                    setCurrentPoster({
                      ...currentPoster,
                      author: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editImage">
                <Form.Label>Image URL:</Form.Label>
                <Form.Control
                  type="text"
                  value={currentPoster.blogimage}
                  onChange={(e) =>
                    setCurrentPoster({
                      ...currentPoster,
                      blogimage: e.target.value,
                    })
                  }
                />
                {currentPoster.blogimage && (
                  <div className="mt-2">
                    <img
                      src={currentPoster.blogimage}
                      alt="Preview"
                      style={{ width: "150px", height: "auto" }}
                    />
                  </div>
                )}
              </Form.Group>
              <div className="mt-3">
                <button type="submit" className="btn btn-primary">
                  Save Changes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ms-2"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default Editblog;
