// import React, { useEffect, useState } from "react";
// import { CiCircleRemove } from "react-icons/ci";
// import { API_BASE_URL } from "../../../utils";
// import axios from "axios";
// import DatePicker from "react-datepicker";
// import ReactQuill from "react-quill";
// import { useAuthHeaders } from "../../../components/Token";
// import { Form } from "react-bootstrap";
// import DescriptionMethodConditions from "../../../components/DescriptionMethodConditions/DescriptionMethodConditions";
// import NoteEditor from "../../../components/noteEditor/NoteEditor ";
// // import Swal from "sweetalert2/dist/sweetalert2.js";

// function EditNewProductsFields({
//   productPartNumberEdit,
//   setProductPartNumberEdit,

//   productId,
//   ProductsImagesPreview,
//   // setProductImage,
//   // newProductImage,
//   // setNewProductImage,
//   onImagesChange,
//   removeImage,
//   // ProductsImagesPreview,

//   categories,
//   currentCategories,
//   selectedCategory,
//   setSelectedCategory,
//   handleCategorySelect,

//   currentSubCategories, //its return current subCategory
//   subCategory, //[rivet,rivetNUt,splancher]
//   selectedSubCategory,
//   setSelectedSubCategory,
//   handleSubCategorySelect,

//   manuFacturerEdit,
//   setManuFacturerEdit,

//   oldAmount,
//   setOldAmount,

//   salePrice,
//   setSalelPrice,

//   weightPerPiece,
//   setWeightPerPiece,

//   boxOfQuantity,
//   setBoxOfQuantity,

//   originalPrice,
//   setOriginalPrice,

//   productWeight,
//   setProductWeight,

//   products,
//   setProducts,

//   quantity,
//   setQuantity,

//   metaTitle,
//   setmetaTitle,

//   dealofDay,
//   setDealofDay,

//   currentApidata,
//   selectedDate,
//   setSelectedDate,

//   writeNotes,
//   setWriteNotes,

//   descriptions,
//   setUpdatedDescriptions,
//   updatedDescriptions,

//   discriptionType,
//   handleChange,
//   setManualInput,
//   manualInput,
// }) {
//   // const [categories, setCategories] = useState([]);
//   // const [subCategory, setSubCategory] = useState([]);

//   const headers = useAuthHeaders();

//   // useEffect(() => {
//   //   axios
//   //     .get(`${API_BASE_URL}/category/admin/getcategory`)
//   //     .then((response) => {
//   //       setCategories(response.data.categories);
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error fetching category data:", error);
//   //     });
//   // }, [selectedCategory]);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleDescriptionChange = (index, newValue) => {
//     setUpdatedDescriptions((prevDescriptions) => {
//       const updated = [...prevDescriptions];
//       updated[index] = newValue;
//       return updated;
//     });
//   };

//   useEffect(() => {
//     if (isNaN(boxOfQuantity) || isNaN(salePrice) || isNaN(weightPerPiece)) {
//       console.log("One of the input values is not a number.");
//       return;
//     }

//     const calculateProductWeight = boxOfQuantity * salePrice;
//     setOriginalPrice(calculateProductWeight);

//     const calculateWeightPerPiece = boxOfQuantity * weightPerPiece;
//     const calculateInKg = calculateWeightPerPiece / 1000;

//     let formattedWeightPerPiece;

//     if (calculateInKg >= 1) {
//       formattedWeightPerPiece = `${calculateInKg.toFixed(2)} kg`;
//     } else {
//       const calculateInGrams = calculateWeightPerPiece;
//       formattedWeightPerPiece = `${calculateInGrams} g`;
//     }

//     setProductWeight(formattedWeightPerPiece);
//   }, [salePrice, weightPerPiece, boxOfQuantity]);

//   return (
//     <div className="row category-field">
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Change Part Number:
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={productPartNumberEdit}
//           onChange={(e) => setProductPartNumberEdit(e.target.value)}
//         />
//       </div>
//       {/* <div className="col-lg-3 col-md-4">
//         <label htmlFor="productimages" className="fs-6">
//           Change product Image:
//         </label>
//         <br />
//         <input
//           type="file"
//           name="productimages"
//           id="productimages"
//           onChange={onImagesChange}
//           multiple
//           // required
//         />

//         <div className="d-flex">
//           {ProductsImagesPreview.map((image, index) => (
//             <div key={index}>
//               <img
//                 src={image.productimage}
//                 alt={`Existing Product Image ${index}`}
//                 width="50"
//                 height="50"
//               />

//               <span
//                 // onClick={(e) =>
//                   // handleImageRemove(image.productimage)
//                   // handleImageRemove(image.productimage, image._id)
//                 // }
//                 onClick={() => removeImage(index)}
//               >
//                 <CiCircleRemove />
//               </span>
//             </div>
//           ))}

//         </div>
//       </div>
//  */}

// <div className="col-lg-3 col-md-4">
//   <label htmlFor="productimages" className="fs-6">
//     Change product Image:
//   </label>
//   <br />
//   <input
//     type="file"
//     name="productimages"
//     id="productimages"
//     onChange={onImagesChange}
//     multiple
//   />
//   <div className="d-flex">
//     {ProductsImagesPreview.map((image, index) => (
//       <div key={index}>
//         <img
//           src={typeof image === 'string' ? image : image.productimage}
//           alt={`Existing Product Image ${index}`}
//           width="50"
//           height="50"
//         />
//         <span onClick={() => removeImage(index)}>
//           <CiCircleRemove />
//         </span>
//       </div>
//     ))}
//   </div>
// </div>

//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Change Category :
//         </label>
//         <br />
//         <Form.Select
//           aria-label="Default select example"
//           id="category"
//           className="my-2"
//           value={selectedCategory}
//           onChange={handleCategorySelect}
//           required
//         >
//           {currentCategories.name === selectedCategory.name ? (
//             <option value={selectedSubCategory?._id}>
//               {selectedCategory?.name}
//             </option>
//           ) : (
//             <option value="">select the category</option>
//           )}

//           {categories.map((item) => (
//             <option key={item?._id} value={item?._id}>
//               {item?.name}
//             </option>
//           ))}
//         </Form.Select>
//       </div>
//       <div className="col-md-4 col-lg-3">
//         <label htmlFor="name" className="fs-6">
//           Sub Category Name:
//         </label>
//         <br />
//         <Form.Select
//           id="subCategory"
//           aria-label="Default select example"
//           className="my-2"
//           value={selectedSubCategory}
//           onChange={handleSubCategorySelect}
//           required
//         >
//           {/* <option value={selectedSubCategory?._id}>
//             {selectedSubCategory._id!==selectedSubCategory._id ? selectedSubCategory?.name : "%%%%%%%%%%%"}
//           </option>
//           {subCategory.map((item) =>
//             selectedSubCategory?._id !== item._id ? (
//               <option key={item._id} value={item._id}>
//                 {item.name}
//               </option>
//             ) : (
//               "ssssssssssssss"
//             )
//           )} */}

//           <option value={selectedSubCategory?._id}>
//             {currentSubCategories.name === selectedSubCategory.name
//               ? selectedSubCategory?.name
//               : "select the subcategory "}
//           </option>
//           {subCategory.map((item) =>
//             /* it's condition*/ currentSubCategories === item.name ? (
//               <option key={item?._id} value={item?._id}>
//                 {item?.name}
//               </option>
//             ) : (
//               <option key={item?._id} value={item?._id}>
//                 {item?.name}
//               </option>
//             )
//           )}
//         </Form.Select>
//         {/* <h5 className="my-2">{selectedSubCategory?.name}</h5> */}
//       </div>
//       {/* descriptions */}
//       {/* {descriptions.map((descriptions, index) => {
//         // console.log("descriptions in loop", descriptions);
//         return (
//           <div className="col-lg-3 col-md-4">
//             <label htmlFor="ManuFacturer" className="fs-6">
//               {descriptions.name} :
//             </label>
//             <br />

//             <input
//               type="text"
//               id={`description-${index}`}
//               // value={updatedDescriptions[index] || descriptions.value}
//               value={updatedDescriptions[index]}
//               required
//               onChange={(e) => handleDescriptionChange(index, e.target.value)}
//             />
//           </div>
//         );
//       })} */}
//       {/*////////////////////////////////////////////  */}
//       {Object.keys(descriptions).length > 0 ? (
//         descriptions.map((descriptions, index) => {
//           // console.log("descriptions in loop", descriptions);
//           return (
//             <div className="col-lg-3 col-md-4">
//               <label htmlFor="ManuFacturer" className="fs-6">
//                 {descriptions.name} :
//               </label>
//               <br />

//               <input
//                 type="text"
//                 id={`description-${index}`}
//                 // value={updatedDescriptions[index] || descriptions.value}
//                 value={updatedDescriptions[index]}
//                 required
//                 onChange={(e) => handleDescriptionChange(index, e.target.value)}
//               />
//             </div>
//           );
//         })
//       ) : (
//         <DescriptionMethodConditions
//           dataList={discriptionType}
//           handleChange={handleChange}
//           setManualInput={setManualInput}
//           manualInput={manualInput}
//         />
//       )}
//        {/* descriptions */}
//       {/* descriptions */}
//       {/* ManuFacturer */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="ManuFacturer" className="fs-6">
//           ManuFacturer :
//         </label>
//         <br />
//         <input
//           type="text"
//           id="ManuFacturer"
//           value={manuFacturerEdit}
//           onChange={(e) => setManuFacturerEdit(e.target.value)}
//         />
//       </div>
//       {/* Old Amount */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="BOQ" className="fs-6">
//           Old Price :
//         </label>
//         <br />
//         <input
//           type="number"
//           id="BOQ"
//           placeholder="0"
//           className="w-100"
//           value={oldAmount}
//           step="0.00001"
//           onChange={(e) => setOldAmount(e.target.value)}
//         />
//       </div>
//       {/* Price For Piece  */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Price For Piece :
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={salePrice}
//           onChange={(e) => setSalelPrice(e.target.value)}
//         />
//       </div>
//       {/* Weight Per Piece */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Weight Per Piece (in Grams) :
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={weightPerPiece}
//           onChange={(e) => setWeightPerPiece(e.target.value)}
//         />
//       </div>
//       {/* boq */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           boq:
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={boxOfQuantity}
//           onChange={(e) => setBoxOfQuantity(e.target.value)}
//         />
//       </div>
//       {/* Product Price */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Product Price :
//         </label>
//         <br />
//         <p className="my2">{originalPrice}</p>
//       </div>
//       {/* Product Weight */}
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Product Weight:
//         </label>
//         <br />
//         <p className="my-2">{productWeight}</p>
//       </div>
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Products :
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={products}
//           onChange={(e) => setProducts(e.target.value)}
//         />
//       </div>
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Quantity :
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={quantity}
//           onChange={(e) => setQuantity(e.target.value)}
//         />
//       </div>
//       <div className="col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Meta Title :
//         </label>
//         <br />
//         <input
//           type="text"
//           id="name"
//           value={metaTitle}
//           onChange={(e) => setmetaTitle(e.target.value)}
//         />
//       </div>
//       <div className="p-2 col-lg-3 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           DEAL OF THE DAY :
//         </label>
//         <br />
//         <div className="d-flex align-items-center">
//           <input
//             type="checkbox"
//             id="flexCheckDefault"
//             style={{ width: "10%" }}
//             checked={dealofDay}
//             onChange={(e) => setDealofDay(e.target.checked)}
//           />
//           <label class="form-check-label" for="flexCheckDefault">
//             DEAL OF THE DAY
//           </label>
//         </div>
//       </div>
//       {dealofDay === true && (
//         <div className="col-lg-3 col-md-4">
//           <label style={{ marginRight: "10px" }}>Select a Till Date : </label>
//           <DatePicker
//             selected={selectedDate}
//             onChange={handleDateChange}
//             dateFormat="dd/MM/yyyy"
//             className="w-100"
//             placeholderText="Select Date"
//             minDate={new Date()}
//           />
//         </div>
//       )}
//       <div className="p-2 col-md-12 col-md-4">
//         <label htmlFor="name" className="fs-6">
//           Write Notes :
//         </label>
//         <br />
//         <div class="form-group">
//           <NoteEditor setWriteNotes={setWriteNotes} writeNotes={writeNotes} />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default EditNewProductsFields;

import React, { useEffect, useState } from "react";
import { CiCircleRemove } from "react-icons/ci";
import { API_BASE_URL } from "../../../utils";
import axios from "axios";
import DatePicker from "react-datepicker";
// import ReactQuill from "react-quill";
import { useAuthHeaders } from "../../../components/Token";
import { Form } from "react-bootstrap";
import DescriptionMethodConditions from "../../../components/DescriptionMethodConditions/DescriptionMethodConditions";
import NoteEditor from "../../../components/noteEditor/NoteEditor ";

function EditNewProductsFields({
  productPartNumberEdit,
  setProductPartNumberEdit,

  productId,

  existingImages,
  newImagesPreview,
  onImagesChange,
  removeExistingImage,
  removeNewImage,

  categories,
  currentCategories,
  selectedCategory,
  setSelectedCategory,
  handleCategorySelect,

  currentSubCategories, //its return current subCategory
  subCategory, //[rivet,rivetNUt,splancher]
  selectedSubCategory,
  setSelectedSubCategory,
  handleSubCategorySelect,

  manuFacturerEdit,
  setManuFacturerEdit,

  oldAmount,
  setOldAmount,
salePrice
  ,
  setSalelPrice,

  weightPerPiece,
  setWeightPerPiece,

  boxOfQuantity,
  setBoxOfQuantity,

  originalPrice,
  setOriginalPrice,

  productWeight,
  setProductWeight,

  products,
  setProducts,

  quantity,
  setQuantity,

  metaTitle,
  setmetaTitle,

  dealofDay,
  setDealofDay,

  currentApidata,
  selectedDate,
  setSelectedDate,

  writeNotes,
  setWriteNotes,

  descriptions,
  setUpdatedDescriptions,
  updatedDescriptions,

  discriptionType,
  handleChange,
  setManualInput,
  manualInput,
}) {
  const headers = useAuthHeaders();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDescriptionChange = (index, newValue) => {
    setUpdatedDescriptions((prevDescriptions) => {
      const updated = [...prevDescriptions];
      updated[index] = newValue;
      return updated;
    });
  };

  const handleImageRemove = (productimage) => {
    const imageUrl = productimage;
    // console.log()

    axios
      .delete(
        `${API_BASE_URL}/products/${productId}/images`,
        { imageUrl },
        {
          headers,
        }
      )
      .then((res) => {
        console.log("res in product image", res.data);
      })
      .catch((error) => {
        console.log("error in product image", error);
      });
  };

  useEffect(() => {
    if (isNaN(boxOfQuantity) || isNaN(salePrice) || isNaN(weightPerPiece)) {
      console.log("One of the input values is not a number.");
      return;
    }

    const calculateProductWeight = boxOfQuantity * salePrice;
    setOriginalPrice(calculateProductWeight);

    const calculateWeightPerPiece = boxOfQuantity * weightPerPiece;
    const calculateInKg = calculateWeightPerPiece / 1000;

    let formattedWeightPerPiece;

    if (calculateInKg >= 1) {
      formattedWeightPerPiece = `${calculateInKg.toFixed(2)} kg`;
    } else {
      const calculateInGrams = calculateWeightPerPiece;
      formattedWeightPerPiece = `${calculateInGrams} g`;
    }

    setProductWeight(formattedWeightPerPiece);
  }, [salePrice, weightPerPiece, boxOfQuantity]);


  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        // setCategories(response.data.categories);
        selectedCategory(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, [selectedCategory]);

  return (
    <div className="row category-field">
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Change Part Number:
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={productPartNumberEdit}
          onChange={(e) => setProductPartNumberEdit(e.target.value)}
        />
      </div>
      <div className="col-lg-3 col-md-4">
        <label htmlFor="productimages" className="fs-6">
          Change product Image:
        </label>
        <br />
        <input
          type="file"
          name="productimages"
          id="productimages"
          onChange={onImagesChange}
          multiple
        />
        <div className="d-flex">
          {existingImages?.map((image, index) => (
            <div key={index}>
              <img
                src={typeof image === "string" ? image : image.productimage}
                alt={`Existing Product Image ${index}`}
                width="50"
                height="50"
              />
              <span onClick={() => removeExistingImage(index)}>
                <CiCircleRemove />
              </span>
            </div>
          ))}

          {newImagesPreview.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`New Product Image ${index}`}
                width="50"
                height="50"
              />
              <span onClick={() => removeNewImage(index)}>
                <CiCircleRemove />
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Change Category :
        </label>
        <br />
        <Form.Select
          aria-label="Default select example"
          id="category"
          className="my-2"
          value={selectedCategory}
          onChange={handleCategorySelect}
          required
        >
          {console.log("currentCategories ======>",currentCategories)}
          {currentCategories?.name === selectedCategory?.name ? (
            <option value={selectedSubCategory?._id}>
              {selectedCategory?.name}
            </option>
          ) : (
            <option value="">select the category</option>
          )}

          {categories.map((item) => (
            <option key={item?._id} value={item?._id}>
              {item?.name}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="col-md-4 col-lg-3">
        <label htmlFor="name" className="fs-6">
          Sub Category Name:
        </label>
        <br />
        <Form.Select
          id="subCategory"
          aria-label="Default select example"
          className="my-2"
          value={selectedSubCategory}
          onChange={handleSubCategorySelect}
          required
        >
          <option value={selectedSubCategory?._id}>
            {currentSubCategories?.name === selectedSubCategory?.name
              ? selectedSubCategory?.name
              : "select the subcategory "}
          </option>
                    
            {/* {currentSubCategories?.name === selectedSubCategory?.name
              ? <option value={selectedSubCategory?._id}>{selectedSubCategory?.name}</option>
              :<option > "select the subcategory " </option>} */}
          
          {subCategory.map((item) =>
            /* it's condition*/ currentSubCategories === item.name ? (
              <option key={item?._id} value={item?._id}>
                {item?.name}
              </option>
            ) : (
              <option key={item?._id} value={item?._id}>
                {item?.name}
              </option>
            )
          )}
        </Form.Select>
      </div>
      {Object.keys(descriptions).length > 0 ? (
        descriptions.map((descriptions, index) => {
          // console.log("descriptions in loop", descriptions);
          return (
            <div className="col-lg-3 col-md-4">
              <label htmlFor="ManuFacturer" className="fs-6">
                {descriptions.name} :
              </label>
              <br />

              <input
                type="text"
                id={`description-${index}`}
                // value={updatedDescriptions[index] || descriptions.value}
                value={updatedDescriptions[index]}
                required
                onChange={(e) => handleDescriptionChange(index, e.target.value)}
              />
            </div>
          );
        })
      ) : (
        <DescriptionMethodConditions
          dataList={discriptionType}
          handleChange={handleChange}
          setManualInput={setManualInput}
          manualInput={manualInput}
        />
      )}
      {/* descriptions */}
      {/* descriptions */}
      {/* ManuFacturer */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="ManuFacturer" className="fs-6">
          ManuFacturer :
        </label>
        <br />
        <input
          type="text"
          id="ManuFacturer"
          value={manuFacturerEdit}
          onChange={(e) => setManuFacturerEdit(e.target.value)}
        />
      </div>
      {/* Old Amount */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="BOQ" className="fs-6">
          Old Price :
        </label>
        <br />
        <input
          type="number"
          id="BOQ"
          placeholder="0"
          className="w-100"
          value={oldAmount}
          step="0.00001"
          onChange={(e) => setOldAmount(e.target.value)}
        />
      </div>
      {/* Price For Piece  */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Price For Piece :
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={salePrice}
          onChange={(e) => setSalelPrice(e.target.value)}
        />
      </div>
      {/* Weight Per Piece */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Weight Per Piece (in Grams) :
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={weightPerPiece}
          onChange={(e) => setWeightPerPiece(e.target.value)}
        />
      </div>
      {/* boq */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          boq:
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={boxOfQuantity}
          onChange={(e) => setBoxOfQuantity(e.target.value)}
        />
      </div>
      {/* Product Price */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Product Price :
        </label>
        <br />
        <p className="my2">{originalPrice}</p>
      </div>
      {/* Product Weight */}
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Product Weight:
        </label>
        <br />
        <p className="my-2">{productWeight}</p>
      </div>
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Products :
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={products}
          onChange={(e) => setProducts(e.target.value)}
        />
      </div>
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Quantity :
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
      <div className="col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          Meta Title :
        </label>
        <br />
        <input
          type="text"
          id="name"
          value={metaTitle}
          onChange={(e) => setmetaTitle(e.target.value)}
        />
      </div>
      <div className="p-2 col-lg-3 col-md-4">
        <label htmlFor="name" className="fs-6">
          DEAL OF THE DAY :
        </label>
        <br />
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            id="flexCheckDefault"
            style={{ width: "10%" }}
            checked={dealofDay}
            onChange={(e) => setDealofDay(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            DEAL OF THE DAY
          </label>
        </div>
      </div>
      {dealofDay === true && (
        <div className="col-lg-3 col-md-4">
          <label style={{ marginRight: "10px" }}>Select a Till Date : </label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            className="w-100"
            placeholderText="Select Date"
            minDate={new Date()}
          />
        </div>
      )}
      <div className="p-2 col-md-12 col-md-4">
        <label htmlFor="name" className="fs-6">
          Write Notes :
        </label>
        <br />
        <div className="form-group">
          <NoteEditor setWriteNotes={setWriteNotes} writeNotes={writeNotes} />
        </div>
      </div>
    </div>
  );
}

export default EditNewProductsFields;
