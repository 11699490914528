import React, { useState } from "react";
import "../Headers/Sidebar/Sidebar.scss";
import Home_icon from "../../Assets/Images/home-icon.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { RxDashboard } from "react-icons/rx";
function DashBoardIteams() {
  const [menuOpenDashboard, setMenuOpenDashboard] = useState(false);

  return (
    <div className="text-decoration-none">
      <li
        onClick={() => setMenuOpenDashboard(!menuOpenDashboard)}
        aria-controls="example-collapse-text"
        aria-expanded={menuOpenDashboard}
      >
        <div className="d-flex">
          {/* <img className="sidebar-icons" src={Home_icon} /> */}<RxDashboard className="me-2"/>

          <div className="sidebar-text">DashBoard</div>
        </div>
        {menuOpenDashboard === false ? (
          <IoIosArrowForward className="sidebar-menu-icon" />
        ) : (
          <IoIosArrowDown className="sidebar-menu-icon" />
        )}
      </li>

      <Collapse in={menuOpenDashboard}>
        <div id="example-collapse-text">
          
          <Link
            className={
             "text-dark text-decoration-none"
            }
            style={{ fontSize: "13px" }}
            to="/users"
          >
            <li className="mx-2">Users</li>
          </Link>
          <Link
            className={
              "text-dark text-decoration-none"
            }
            style={{ fontSize: "13px" }}
            to="/orderProducts"
          >
            <li className="mx-2">Total Orders</li>
          </Link>
          <Link
            className={
              "text-dark text-decoration-none"
            }
            style={{ fontSize: "13px" }}
            to="/inquiry"
          >
            <li className="mx-2">Inquiry</li>
          </Link>
        </div>
      </Collapse>
    </div>
  );
}

export default DashBoardIteams;
