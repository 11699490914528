import axios from "axios";
import React from "react";
import Form from "react-bootstrap/Form";
import { API_BASE_URL } from "../../utils/index";
import { useAuthHeaders } from "../Token";


export default function DescriptionTypeCategorys({
  setSelectedCategory,
  selectedCategory,
  category,
  setSubCategory,
  setSubmitCategory,
  submitCategory,
}) {
  const headers = useAuthHeaders();

  const handleCategorySelect = (e) => {
    const categoryId = e.target?.value;

    const setCategoryName = category.find((cat) => cat?._id === categoryId);
    const categoryName = setCategoryName?.name; 

    setSelectedCategory(categoryId);
    setSubmitCategory(categoryName);

    axios
      .get(`${API_BASE_URL}/category/admin/${categoryId}`)
      .then((response) => {
        setSubCategory(response?.data?.subcategoryNames);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-6">
      <label htmlFor="name" className="fs-6">
        Category Name:
      </label> 
      <br />
      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedCategory}
        onChange={handleCategorySelect}
        required
      >
        <option value="">Select a Category</option>
        {Array.isArray(category) ? (
          category.map((category) => (
            <option key={category?._id} value={category?._id}>
              {category?.name}
            </option>
          ))
        ) : (
          <option>Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}
