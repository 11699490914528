import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import BackArrow from "../../components/MinorComponents/BackArrow";

function ZoneDetials() {
  const { _id } = useParams();
  const headers = useAuthHeaders();
  const [data, setData] = useState("");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getsinglezone/${_id}`, { headers })
      .then((response) => {
        setData(response?.data?.Zone);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="m-4" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
      <BackArrow path={"/editNewZone"} />

      <h6>Zone Name :</h6>
      <p>{data?.zonename}</p>

      <h6>Zone Weight :</h6>
      <p>
        {data?.weight?.value} {data?.weight?.unit}
      </p>

      <h6>Zone Rate :</h6>
      <p>{data?.defaultRate}</p>
    </div>
  );
}

export default ZoneDetials;
