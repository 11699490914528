import React, { useState } from "react";
import Form from "react-bootstrap/Form";

export default function DescriptionMethodConditions({
  dataList,
  handleChange,
  setManualInput,
  manualInput,
}) {
  
  const handleChangeManual = (e, index) => {
    const { name, value } = e.target;
    const data = [...manualInput];

    if (!data[index]) {
      data[index] = {};
    }

    data[index][name] = value;
    setManualInput(data);
  };

  return (
    <>
      {Array.isArray(dataList) &&
        dataList.map((item, index) => (
          // console.log("dataList", item)
          <div className="p-2 col-md-4" key={index}>
            <label htmlFor="name" className="fs-6 w-100">
              {item?.descriptionname?.descriptionName}:
            </label>
            <br />

            {item?.type === "Drop Down" ? (
              <Form.Select
                aria-label="Default select example"
                className="my-2"
                key={item?._id}
                name={item.descriptionname?.descriptionName}
                onChange={handleChange}
              >
                <option value="">Select Desriptions</option>

                {item?.descriptionnametypes &&
                item?.descriptionnametypes?.length > 0 ? (
                  item?.descriptionnametypes?.map((option, index) => (
                    <option key={index} href={`#${option}`}>
                      {option}
                    </option>
                  ))
                ) : (
                  <option value="">No options available</option>
                )}
              </Form.Select>
            ) : null}

            {item.type === "Manual" ? (
              <input
                name={item?.descriptionname?.descriptionName}
                type="text"
                className="w-100"
                onChange={(e) => handleChangeManual(e, index)}
              />
            ) : null}
          </div>
        ))}
    </>
  );
}
