import React, { useEffect, useState } from "react";
import "../ProductCategory/productCategory.scss";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function  SubProductCategory() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
      .then((response) => {
        setCategories(response.data?.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${API_BASE_URL}/subcategory/admin/newsubcategory`,
        {
          name: subCategoryName,
          categoryId: selectedCategory,
        },
        {
          headers,
        }
      )
      .then((response) => {
        setIsSuccess(true);
        Swal.fire({
          icon: "success",
          title: "SubCategory Create has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/subCategory");
        // Clear the form and reset the state
        console.log("response", response);
        setSubCategoryName("");
        setSelectedCategory("");
      })
      .catch((error) => {
        console.error("Error adding sub-category:", error);
        setIsSuccess(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <h2>Sub Category</h2>
      <form onSubmit={handleFormSubmit} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target?.value)}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                // console.log(category,"category") 
                <option key={category?._id} value={category?.name}>
                  {category?.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Sub Category Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              value={subCategoryName}
              onChange={(e) => setSubCategoryName(e.target?.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <button  style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton  path="/subCategory" />
        </div>
      </form>
      {isSuccess && (
        <p className="text-danger">Sub category added successfully!</p>
      )}
    </div>
  );
}

export default SubProductCategory;
