// import React, { useContext, useEffect } from "react";
// import "../Navbar/Navbar.scss";
// import "../Sidebar/Sidebar.scss";
// import { useState } from "react";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import brand from "../../../Assets/Images/brand-logo.svg";
// import "../Sidebar/responsiveSidebar.scss";
// import { Link, useNavigate } from "react-router-dom";
// import { CgProfile } from "react-icons/cg";
// import axios from "axios";
// import Dropdown from "react-bootstrap/Dropdown";
// import { API_BASE_URL } from "../../../utils/index";
// import DashBoardIteams from "../../SidebarItems/DashBoardIteams";
// import ProductsItems from "../../SidebarItems/ProductsItems";
// import CouponItems from "../../SidebarItems/CouponItems";
// import ShippingItems from "../../SidebarItems/ShippingItems";
// import MasterItems from "../../SidebarItems/MasterItems";
// import userContext from "../../UseContext/UseContext";
// import Export from "../../../Pages/Products/NewProducts/Export";

// const Navbar = ({ handleClick }) => {
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [menuOpen, setmenuOpen] = useState(false);
//   const [product, setProduct] = useState(null);
//   const navigate = useNavigate();
//   const logOutContext = useContext(userContext);

//   useEffect(() => {
//     const adminId = localStorage.getItem("adminId");

//     if (!adminId) {
//       navigate("/login");
//       return;
//     }

//     const headers = {
//       Authorization: `Bearer ${adminId}`,
//       "Content-Type": "application/json",
//     };

//     axios
//       .get(`${API_BASE_URL}/admin/profile`, {
//         headers,
//       })
//       .then((result) => {
//         setProduct(result?.data?.admin);
//       })
//       .catch((error) => {
//         console.log("error in profile api", error.response.status);
//         if (error.response.status === 401) {
//           navigate("/login");
//         }
//       });
//   }, []);

//   const handleLogout = () => {
//     localStorage.removeItem("adminId");
//     logOutContext.setLogOut(true);
//     navigate("/login")
//   };

//   return (
//     <div className="dashboard-nav px-4">
//       <div className="nav-menu">
//         <svg
//           onClick={handleClick}
//           className="fs-5"
//           xmlns="http://www.w3.org/2000/svg"
//           height="1em"
//           viewBox="0 0 448 512"
//         >
//           <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
//         </svg>
//       </div>

//       <div className="responsive-sidebar">
//         <svg
//           onClick={handleShow}
//           className="fs-5"
//           xmlns="http://www.w3.org/2000/svg"
//           height="1em"
//           viewBox="0 0 448 512"
//         >
//           <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
//         </svg>

//         <Offcanvas show={show} onHide={handleClose} backdrop="static">
//           <Offcanvas.Header closeButton>
//             <Offcanvas.Title className="text-dark"></Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body className="text-danger">
//             <img
//               src={brand}
//               alt="brand_logo"
//               className="responsive-sidebar-logo"
//             />


//             {/* <div className="py-4">
//               <Link to="/" className="text-decoration-none">
//                 <div
//                   className="responsive-sidebar-iteam d-flex justify-content-between"
//                   onClick={() => setmenuOpen(!menuOpen)}
//                   aria-controls="example-collapse-text"
//                   aria-expanded={menuOpen}
//                 >
//                   <div className="d-flex align-items-center">
//                     <img className="responsive-sidebar-icon" src={Home_icon} />
//                     <p className="m-2 text-dark ">DashBoard</p>
//                   </div>

//                   <img
//                     src={sidebar_menu_icon}
//                     className=" sidebarmenu-icon"
//                     alt="sidebar_menu_icon"
//                   />
//                 </div>
//                 <Collapse in={menuOpen}>
//                   <div id="example-collapse-text">
//                     <li>
//                       <div className=" text-dark">Link 1</div>
//                     </li>
//                     <li>
//                       <div className="text-dark ">Link 2</div>
//                     </li>
//                     <li>
//                       <div className="text-dark ">Link 3</div>
//                     </li>
//                   </div>
//                 </Collapse>
//               </Link>
//             </div> */}


//             <ul className="sidebar-items">
//               <DashBoardIteams />

//               <ProductsItems />

//               <CouponItems />

//               <ShippingItems />

//               <MasterItems />

//               {/* <Export/>  */}
//             </ul>
//           </Offcanvas.Body>
//         </Offcanvas>
//       </div>

//       <div className="d-flex align-iteam-center">
//         <Link to="/profilepage" className="user">
//           {/* <img className="user-image mx-2" src={userImage} /> */}
//         </Link>

//         <Dropdown className="user">
//           <Dropdown.Toggle
//             id="dropdown-button-dark-example1"
//             // variant="secondary"
//           >
//             <div className="user-name">
//               {product ? product?.name : "Loading..."}
//             </div>
//             <CgProfile className="mx-2 text-dark fs-4" />
//           </Dropdown.Toggle>

//           <Dropdown.Menu>
//             <Dropdown.Item href="/profilepage">Profile Page</Dropdown.Item>
//             <Dropdown.Divider />
//             <div className="w-100 d-flex align-items-center justify-content-center">
//               <button
//                 className="py-1 px-2 rounded  logout"
//                 onClick={handleLogout}
//               >
//                 Log Out
//               </button>
//             </div>
//           </Dropdown.Menu>
//         </Dropdown>
//       </div>
//     </div>
//   );
// };

// export default Navbar;




//update on 07/24/2024

import React, { useContext, useEffect } from "react";
import "../Navbar/Navbar.scss";
import "../Sidebar/Sidebar.scss";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import brand from "../../../Assets/Images/brand-logo.svg";
import "../Sidebar/responsiveSidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { API_BASE_URL } from "../../../utils/index";
import DashBoardIteams from "../../SidebarItems/DashBoardIteams";
import ProductsItems from "../../SidebarItems/ProductsItems";
import CouponItems from "../../SidebarItems/CouponItems";
import ShippingItems from "../../SidebarItems/ShippingItems";
import MasterItems from "../../SidebarItems/MasterItems";
import userContext from "../../UseContext/UseContext";
import Export from "../../../Pages/Products/NewProducts/Export";
import Notification from "./Notification";

const Navbar = ({ handleClick ,orders, users, inquiries }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [menuOpen, setmenuOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();
  const logOutContext = useContext(userContext);

  useEffect(() => {
    const adminId = localStorage.getItem("adminId");

    if (!adminId) {
      navigate("/login");
      return;
    }

    const headers = {
      Authorization: `Bearer ${adminId}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/admin/profile`, {
        headers,
      })
      .then((result) => {
        setProduct(result?.data?.admin);
      })
      .catch((error) => {
        console.log("error in profile api", error.response.status);
        if (error.response.status === 401) {
          navigate("/login");
        }
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("adminId");
    logOutContext.setLogOut(true);
    navigate("/login")
  };

  return (
    <div className="dashboard-nav px-4">
      <div className="nav-menu">
        <svg
          onClick={handleClick}
          className="fs-5"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
        >
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
      </div>

      <div className="responsive-sidebar">
        <svg
          onClick={handleShow}
          className="fs-5"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 448 512"
          >
          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
        </svg>
        <Offcanvas show={show} onHide={handleClose} backdrop="static">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="text-dark"></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="text-danger">
            <img
              src={brand}
              alt="brand_logo"
              className="responsive-sidebar-logo"
              />


            {/* <div className="py-4">
              <Link to="/" className="text-decoration-none">
              <div
              className="responsive-sidebar-iteam d-flex justify-content-between"
              onClick={() => setmenuOpen(!menuOpen)}
              aria-controls="example-collapse-text"
              aria-expanded={menuOpen}
              >
                  <div className="d-flex align-items-center">
                  <img className="responsive-sidebar-icon" src={Home_icon} />
                  <p className="m-2 text-dark ">DashBoard</p>
                  </div>
                  
                  <img
                    src={sidebar_menu_icon}
                    className=" sidebarmenu-icon"
                    alt="sidebar_menu_icon"
                  />
                </div>
                <Collapse in={menuOpen}>
                <div id="example-collapse-text">
                <li>
                <div className=" text-dark">Link 1</div>
                </li>
                <li>
                <div className="text-dark ">Link 2</div>
                </li>
                <li>
                <div className="text-dark ">Link 3</div>
                </li>
                  </div>
                  </Collapse>
                  </Link>
                  </div> */}


            <ul className="sidebar-items">
              <DashBoardIteams />

              <ProductsItems />

              <CouponItems />

              <ShippingItems />

              <MasterItems />

              {/* <Export/>  */}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <div className="d-flex align-iteam-center">
        <Link to="/profilepage" className="user">
          {/* <img className="user-image mx-2" src={userImage} /> */}
        </Link>

        <Dropdown className="user">
          <Dropdown.Toggle
            id="dropdown-button-dark-example1"
            // variant="secondary"
            >
            <div className="user-name">
              {product ? product?.name : "Loading..."}
            </div>
            <CgProfile className="mx-2 text-dark fs-4" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/profilepage">Profile Page</Dropdown.Item>
            <Dropdown.Divider />
            <div className="w-100 d-flex align-items-center justify-content-center">
              <button
                className="py-1 px-2 rounded  logout"
                onClick={handleLogout}
                >
                Log Out
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        <Notification orders={orders} users={users} inquiries={inquiries}  />
        </div>
    </div>
  );
};

export default Navbar;