import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../Categorys/categorys.scss";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";
import Lottie from "lottie-react";
import Preloader from "../../../Preloader/Preloader-Animation.json";

function SubCategorys() {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const headers = useAuthHeaders();
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   try {
  //     setLoading(true);

  //     axios
  //       .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
  //       .then((response) => {
  //         setCategories(response?.data?.categories);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching category data:", error);
  //       });

  //     axios
  //       .get(`${API_BASE_URL}/subcategory/admin/getsubcategory`, { headers })
  //       .then((response) => {
  //         setData(response?.data?.subcategory);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching subcategory data:", error);
  //       });
  //   } finally {
  //     setLoading(false);
  //   }
  // }, []);





  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        const [categoriesResponse, subcategoriesResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/category/admin/getcategory`, { headers }),
          axios.get(`${API_BASE_URL}/subcategory/admin/getsubcategory`, { headers })
        ]);
  
        setCategories(categoriesResponse?.data?.categories);
        setData(subcategoriesResponse?.data?.subcategory);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  











  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Sub Category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${API_BASE_URL}/subcategory/admin/deletesubcategory/${categoryId}`,
            {
              headers,
            }
          )
          .then(() => {
            setData((prevData) =>
              prevData?.filter((item) => item?._id !== categoryId)
            );
          })
          .catch((error) => {
            console.error("Error deleting category:", error);

            if (error.response) {
              // Log the response data and status code for more information
              console.error("Response data:", error?.response?.data);
              console.error("Response status:", error?.response?.status);
            }
          });
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const toggleCategoryStatus = (categoryId, isActive) => {
    axios
      .put(
        `${API_BASE_URL}/subcategory/admin/status/${categoryId}`,
        {
          isActive: !isActive,
        },
        {
          headers,
        }
      )
      .then(() => {
        setData((prevData) =>
          prevData?.map((item) =>
            item?._id === categoryId ? { ...item, isActive: !isActive } : item
          )
        );
      })
      .catch((error) => {
        console.error("Error toggling category status:", error);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Sub Categorys</h2>
        <Link to="/subproductcategory" className="btn btn-primary">
          Add New
        </Link>
      </div>
      <table
        className="categorys-table"
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <thead>
          <tr>
            <th>Sub Categorys</th>
            <th>Categorys</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Lottie
              style={{ width: "100vw", height: "50vh" }}
              animationData={Preloader}
            />
          ) : (
            <>
              {data && data?.length > 0 ? (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>
                      {categories.map((category) => {
                        if (category?.name === item?.category?.name) {
                          return category?.name;
                        }
                        return null;
                      })}
                    </td>
                    <td>
                      <Link to={`/subCategorysDetial/${item?._id}`}>
                        <button className="mx-1">
                          <AiFillEye className="m-2 fs-6" />
                        </button>
                      </Link>
                      <button
                        className="mx-1"
                        onClick={() => deleteCategory(item?._id)}
                      >
                        <AiFillDelete className="m-2 fs-6" />
                      </button>
                      <Link to={`/editSubCategory/${item?._id}`}>
                        <button className="mx-1">
                          <FiEdit className="m-2 fs-6" />
                        </button>
                      </Link>

                      <button
                        className="mx-1"
                        onClick={() =>
                          toggleCategoryStatus(item?._id, item?.isActive)
                        }
                      >
                        {item.isActive ? (
                          <BsToggleOn className="m-2 fs-6" />
                        ) : (
                          <BsToggleOff className="m-2 fs-6" />
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3">No subcategories found</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SubCategorys;
