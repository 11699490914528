import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import BackArrow from "../../../components/MinorComponents/BackArrow";

function DescriptionNameTypesDetial() {
  const { _id } = useParams();
  const [descriptionTypeDetial, setDescriptionTypeDetial] = useState({});
  const [descriptionnametypes, setDescriptionnametypes] = useState({});

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singledescriptiontype/${_id}`)
      .then((response) => {
        setDescriptionTypeDetial(response?.data?.descriptiontype);
        setDescriptionnametypes(
          response?.data?.descriptiontype?.descriptionnametypes
        );
        // console.log(response.data.descriptiontype, "singledescription 1234");
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <BackArrow path="/viewDescriptionNameTypes" />
      <h2>Description Type Detial</h2>

      <h6>Description Name:</h6>
      <p>
        {Array.isArray(descriptionnametypes)
          ? descriptionnametypes.map((item, index) => (
              <p className="m-0" key={item}>
                <span>{index + 1}</span> {item}
              </p>
            ))
          : "descriptionnametypes is not an array"}
      </p>

      <h6>Description Name:</h6>
      <p>{descriptionTypeDetial?.descriptionname?.descriptionName}</p>

      <h6>Sub Category Name:</h6>
      <p>{descriptionTypeDetial?.subcategory?.name}</p>

      <h6>Category Name:</h6>
      <p>{descriptionTypeDetial?.category?.name}</p>
      <h6>Created At</h6>
      <p>{descriptionTypeDetial?.createdAt}</p>
    </div>
  );
}

export default DescriptionNameTypesDetial;
