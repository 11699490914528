// import React, { useContext, useEffect, useState } from "react";
// import "../../../components/Headers/Sidebar/Sidebar.scss";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { AiFillDelete, AiFillEye } from "react-icons/ai";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { FiEdit } from "react-icons/fi";
// import { API_BASE_URL } from "../../../utils/index";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import { useAuthHeaders } from "../../../components/Token";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import { SlOptionsVertical } from "react-icons/sl";
// import { Dropdown } from "react-bootstrap";
// import userContext from "../../../components/UseContext/UseContext";

// function NewproductsViewPage() {
//   const [data, setData] = useState([]);
//   const [category, setCategory] = useState([]);
//   const [errorMessage, setErrorMessage] = useState("");
//   const headers = useAuthHeaders();
//   const navigate = useNavigate();
//   const [categoryId, setCategoryId] = useState("")
//   const exportContext = useContext(userContext)

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         setCategory(response?.data?.categories || []);
//         console.log("first useEffect from new Product view pages", category)
//         const initialCategory = response?.data?.categories[0]?._id;
//         onSelectCategory(initialCategory);
//         setCategoryId(initialCategory)
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const deleteCategory = (categoryId) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Are you sure you want to delete this Product",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         axios
//           .delete(
//             `${API_BASE_URL}/admin/products/deleteproduct/${categoryId}`,
//             { headers }
//           )
//           .then(() => {
//             setData((prevData) =>
//               prevData.filter((item) => item?._id !== categoryId)
//             );
//           })
//           .catch((error) => {
//             console.error("Error deleting category:", error);

//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: error.response.data.error,
//             });
//           });
//         Swal.fire({
//           title: "Deleted!",
//           text: "Your Product has been deleted.",
//           icon: "success",
//         });
//       }
//     });

//     // if (shouldDelete) {

//     // }
//   };

//   const toggleCategoryStatus = (categoryId, isActive) => {
//     axios
//       .put(
//         `${API_BASE_URL}/admin/products/productStatus/${categoryId}`,
//         {
//           isActive: !isActive,
//         },
//         {
//           headers,
//         }
//       )
//       .then(() => {
//         setData((prevData) =>
//           prevData.map((item) =>
//             item._id === categoryId ? { ...item, isActive: !isActive } : item
//           )
//         );
//       })
//       .catch((error) => {
//         console.error("Error toggling category status:", error);

//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error.response.data.error,
//         });
//       });
//   };

//   const onSelectCategory = (categoryId) => {
//     axios
//       .get(`${API_BASE_URL}/admin/product/getproductbycategory/${categoryId}`)
//       .then((response) => {
//         setData(response?.data?.products);
//         setErrorMessage("");
//         exportContext.setExportProducts(response?.data?.products)
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         setErrorMessage(error?.response?.data?.message);
//       });
//   };

//   const onExport = () => {

//     navigate('../export')

//   }

//   return (
//     <div className="p-4">
//       <div className="d-flex justify-content-between my-2">
//         <h2 className="m-0">Products</h2>
//         <div style={{ width: "30%", display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
//           <Link to="/newProducts" className="btn btn-primary">
//             Add New
//           </Link>
//           <Link to='/bulkupload' className="btn btn-secondary" >Bluk Upload</Link>
//           <button className="btn btn-success" onClick={onExport}>
//             Export All
//           </button>
//         </div>
//       </div>

//       <Tabs
//         defaultActiveKey={category[0]?._id}
//         id="uncontrolled-tab-example"
//         className="mb-3"
//         onSelect={(key) => onSelectCategory(key)}
//       >
//         {category.map((item, i) => (
//           // <Tab eventKey={item?._id} title={item?.name} key={i}>
//           //   <table className="categorys-table">
//           //     <thead>
//           //       <tr>
//           //         <th>Part Number</th>
//           //         <th>Sub Category</th>
//           //         <th>Category</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //       </tr>
//           //     </thead>

//           //     {errorMessage ? (
//           //       <h6 className="text-danger m-3">No Products</h6>
//           //     ) : (
//           //       <tbody>
//           //         {data.map((item, index) => (
//           //           <tr key={index}>
//           //             <td>{item?.partNumber}</td>
//           //             <td> {item?.subcategoryname?.name} </td>
//           //             <td> {item?.category?.name} </td>

//           //             <td>
//           //               <Dropdown>
//           //                 <Dropdown.Toggle
//           //                   variant="transparent"
//           //                   id="dropdown-basic"
//           //                 >
//           //                   <SlOptionsVertical />
//           //                 </Dropdown.Toggle>

//           //                 <Dropdown.Menu>
//           //                   <Dropdown.Item
//           //                     as={Link}
//           //                     to={`/newProductsDetials/${item?._id}`}
//           //                   >
//           //                     View
//           //                   </Dropdown.Item>
//           //                   <Dropdown.Item
//           //                     as={Link}
//           //                     onClick={() => deleteCategory(item?._id)}
//           //                   >
//           //                     Delete
//           //                   </Dropdown.Item>

//           //                   <Dropdown.Item
//           //                     to={`/editNewProducts/${item?._id}`}
//           //                     as={Link}
//           //                   >
//           //                     Edit
//           //                   </Dropdown.Item>

//           //                   <Dropdown.Item
//           //                     as={Link}
//           //                     onClick={() =>
//           //                       toggleCategoryStatus(item?._id, item?.isActive)
//           //                     }
//           //                   >
//           //                     {item?.isActive ? "Active" : "Deactivate"}
//           //                     {item?.isActive ? (
//           //                       <BsToggleOn className="m-2 fs-6" />
//           //                     ) : (
//           //                       <BsToggleOff className="m-2 fs-6" />
//           //                     )}
//           //                   </Dropdown.Item>
//           //                 </Dropdown.Menu>
//           //               </Dropdown>
//           //             </td>
//           //           </tr>
//           //         ))}
//           //       </tbody>
//           //     )}
//           //   </table>
//           // </Tab>
//           <Tab eventKey={item?._id} title={item?.name} key={i} style={{overflowY:"auto",maxWidth: "100%",backgroundColor:"purple"}}>
//         <div className="scrollable-container" style={{overflowY:"auto",maxWidth: "80%"}}>
//           <table className="categorys-table">
//             <thead>
//               <tr>
//                 <th>Part Number</th>
//                 <th>Sub Category</th>
//                 <th>Category</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//              <th>1</th>
//                 <th>444444444444444</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>

//               </tr>
//             </thead>
//             {errorMessage ? (
//               <h6 className="text-danger m-3">No Products</h6>
//             ) : (
//               <tbody>
//                 {data.map((item, index) => (
//                   <tr key={index}>
//                     <td>{item?.partNumber}</td>
//                     <td>{item?.subcategoryname?.name}</td>
//                     <td>{item?.category?.name}</td>
//                     <td>
//                       <Dropdown>
//                         <Dropdown.Toggle
//                           variant="transparent"
//                           id="dropdown-basic"
//                         >
//                           <SlOptionsVertical />
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                           <Dropdown.Item
//                             as={Link}
//                             to={`/newProductsDetials/${item?._id}`}
//                           >
//                             View
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             as={Link}
//                             onClick={() => deleteCategory(item?._id)}
//                           >
//                             Delete
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             to={`/editNewProducts/${item?._id}`}
//                             as={Link}
//                           >
//                             Edit
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             as={Link}
//                             onClick={() =>
//                               toggleCategoryStatus(item?._id, item?.isActive)
//                             }
//                           >
//                             {item?.isActive ? 'Active' : 'Deactivate'}
//                             {item?.isActive ? (
//                               <BsToggleOn className="m-2 fs-6" />
//                             ) : (
//                               <BsToggleOff className="m-2 fs-6" />
//                             )}
//                           </Dropdown.Item>
//                         </Dropdown.Menu>
//                       </Dropdown>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             )}
//           </table>
//         </div>
//       </Tab>

//         ))}
//       </Tabs>
//     </div>
//   );
// }

// export default NewproductsViewPage;

// import React, { useContext, useEffect, useState } from "react";
// import "../../../components/Headers/Sidebar/Sidebar.scss";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { AiFillDelete, AiFillEye } from "react-icons/ai";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { FiEdit } from "react-icons/fi";
// import { API_BASE_URL } from "../../../utils/index";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import { useAuthHeaders } from "../../../components/Token";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";


// import { SlOptionsVertical } from "react-icons/sl";
// import { Dropdown } from "react-bootstrap";
// import userContext from "../../../components/UseContext/UseContext";
// import Button from "react-bootstrap/Button";


// const useWindowWidth = () => {
//   const [width, setWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => setWidth(window.innerWidth);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return width;
// };

// function NewproductsViewPage() {
//   const [data, setData] = useState([]);
//   const [category, setCategory] = useState([]); // it returns the ["Fasteners", "Tools", "Machines"]
//   const [errorMessage, setErrorMessage] = useState("");
//   const headers = useAuthHeaders();
//   const navigate = useNavigate();
//   const [categoryId, setCategoryId] = useState("");
//   const [subCategories, setSubCategories] = useState([]); // it returns the [rivet, rivetNut, washer]
//   const [subCategoryId, setSubCategoryId] = useState("");

//   const exportContext = useContext(userContext);

//   const width = useWindowWidth();
//   const [showMoreCategories, setShowMoreCategories] = useState(false);
//   const [showMoreSubCategories, setShowMoreSubCategories] = useState(false);

//   const getMaxTabsToShow = () => {
//     if (width > 1200) return 8; // Desktop
//     if (width > 768) return 5; // Tablet
//     return 3; // Mobile
//   };

//   const maxTabsToShow = getMaxTabsToShow();

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         const categories = response?.data?.categories || [];
//         setCategory(categories);
//         console.log("Fetched categories:", categories);

//         if (categories.length > 0) {
//           const initialCategory = categories[0]._id;
//           setCategoryId(initialCategory);
//           // Automatically fetch subcategories and products for the initial category
//           onSelectCategory(initialCategory);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching categories:", error);
//       });
//   }, []);

//   useEffect(() => {
//     if (categoryId) {
//       axios
//         .get(`${API_BASE_URL}/category/admin/${categoryId}`)
//         .then((response) => {
//           const subCategories = response?.data?.subcategoryNames || [];
//           setSubCategories(subCategories);
//           console.log("Fetched subcategories:", subCategories);

//           // Automatically select the first subcategory if available
//           if (subCategories.length > 0) {
//             const initialSubCategory = subCategories[0]._id;
//             setSubCategoryId(initialSubCategory);
//             // Fetch products for the initial subcategory
//             onSelectSubCategory(initialSubCategory);
//           } else {
//             setSubCategoryId(""); // Clear the subcategory selection if no subcategories
//             setData([]); // Clear the data if no subcategories
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching subcategories:", error);
//         });
//     }
//   }, [categoryId]);

//   useEffect(() => {
//     if (subCategoryId) {
//       onSelectSubCategory(subCategoryId);
//     }
//   }, [subCategoryId]);

//   const onSelectCategory = (categoryId) => {
//     axios
//       .get(`${API_BASE_URL}/category/admin/${categoryId}`)
//       .then((response) => {
//         const subCategories = response?.data?.subcategoryNames || [];
//         setSubCategories(subCategories);
//         console.log("Fetched subcategories:", subCategories);

//         // Automatically select the first subcategory if available
//         if (subCategories.length > 0) {
//           const initialSubCategory = subCategories[0]._id;
//           setSubCategoryId(initialSubCategory);
//           onSelectSubCategory(initialSubCategory); // Fetch products for initial subcategory
//         } else {
//           setSubCategoryId(""); // Clear subcategory selection if no subcategories
//           setData([]); // Clear data if no subcategories
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategories:", error);
//       });
//   };

//   const onSelectSubCategory = (subCategoryId) => {
//     axios
//       .get(
//         `${API_BASE_URL}/user/product/getprodutbysubcategory/${subCategoryId}`
//       )
//       .then((response) => {
//         setData(response?.data?.products || []);
//         setErrorMessage("");
//         exportContext.setExportProducts(response?.data?.products || []);
//         console.log("Products by subcategory:", response?.data?.products);
//       })
//       .catch((error) => {
//         console.error("Error fetching products by subcategory:", error);
//         setErrorMessage(
//           error?.response?.data?.message || "Error fetching products."
//         );
//       });
//   };

//   const deleteCategory = (categoryId) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Are you sure you want to delete this Product?",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         axios
//           .delete(
//             `${API_BASE_URL}/admin/products/deleteproduct/${categoryId}`,
//             { headers }
//           )
//           .then(() => {
//             setData((prevData) =>
//               prevData.filter((item) => item._id !== categoryId)
//             );
//             Swal.fire("Deleted!", "Your Product has been deleted.", "success");
//           })
//           .catch((error) => {
//             console.error("Error deleting category:", error);
//             Swal.fire(
//               "Oops...",
//               error?.response?.data?.error || "Error deleting product.",
//               "error"
//             );
//           });
//       }
//     });
//   };

//   const toggleCategoryStatus = (categoryId, isActive) => {
//     axios
//       .put(
//         `${API_BASE_URL}/admin/products/productStatus/${categoryId}`,
//         { isActive: !isActive },
//         { headers }
//       )
//       .then(() => {
//         setData((prevData) =>
//           prevData.map((item) =>
//             item._id === categoryId ? { ...item, isActive: !isActive } : item
//           )
//         );
//       })
//       .catch((error) => {
//         console.error("Error toggling category status:", error);
//         Swal.fire(
//           "Oops...",
//           error?.response?.data?.error || "Error toggling status.",
//           "error"
//         );
//       });
//   };

//   const onExport = () => {
//     navigate("../export");
//   };

//   return (
//     <div className="p-4">
//       <div className="d-flex justify-content-between my-2">
//         <h2 className="m-0">Products</h2>
//         <div
//           style={{
//             width: "34%",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-evenly",
//             gap: "10px",
//           }}
//         >
//           <Link to="/newProducts" className="btn btn-primary">
//             Add New
//           </Link>
//           <Link to="/bulkupload" className="btn btn-secondary">
//             Bulk Upload
//           </Link>
//           <button className="btn btn-success" onClick={onExport}>
//             Export All
//           </button>
//         </div>
//       </div>

//       <Tabs
//         defaultActiveKey={category.find((cat) => cat._id === categoryId)?._id}
//         id="category-tabs"
//         className="mb-3"
//         onSelect={(key) => setCategoryId(key)}
//         style={{
//           backgroundColor: "#071952",
//           fontWeight: "bold",
//           color: "#F5F7F8",
//           borderTopLeftRadius: "8px",
//           borderTopRightRadius: "8px",
//         }}
//       >
   
//         {category
//           .slice(0, showMoreCategories ? category.length : maxTabsToShow)
//           .map((item) => (
//             <Tab eventKey={item?._id} title={item?.name} key={item?._id}>
//               {subCategories.length > 0 ? (
//                 <>
//                   <Tabs
//                     defaultActiveKey={
//                       subCategories.find((sub) => sub._id === subCategoryId)
//                         ?._id
//                     }
//                     id="subcategory-tabs"
//                     className="mb-3"
//                     onSelect={(key) => setSubCategoryId(key)}
//                     style={{
//                       backgroundColor: "#E7F0DC",
//                       fontWeight: "bold",
//                       borderTopLeftRadius: "8px",
//                       borderTopRightRadius: "8px",
//                     }}
//                   >
//                     {subCategories
//                       // .slice(
//                       //   0,
//                       //   showMoreSubCategories
//                       //     ? subCategories.length
//                       //     : maxTabsToShow
//                       // )
//                       .map((subItem) => (
//                         <Tab
//                           eventKey={subItem._id}
//                           title={subItem.name}
//                           key={subItem._id}
//                           style={{ backgroundColor: "#EEEEEE" }}
//                         >
//                           <div
//                             className="scrollable-container"
//                             style={{ overflowY: "auto", maxWidth: "100%" }}
//                           >
//                             <table className="categorys-table">
//                               <thead>
//                                 <tr>
//                                   <th>Part Number</th>
//                                   <th>Sub Category</th>
//                                   <th>Category</th>
//                                   <th>Quantity</th>
//                                   <th>Weight Per Piece</th>
//                                   <th>Product Weight</th>
//                                   <th>Box of Quantity</th>
//                                   <th>Sale Price</th>
//                                   <th>Status</th>
//                                   <th>Action</th>
//                                 </tr>
//                               </thead>
//                               {data.length === 0 ? (
//                                 <h6 className="text-danger m-3">No Products</h6>
//                               ) : (
//                                 <tbody>
//                                   {data.map((product, index) => (
//                                     <tr key={index}>
//                                       <td>{product.partNumber}</td>
//                                       <td>{product.subcategoryname?.name}</td>
//                                       <td>{product.category?.name}</td>
//                                       <td>{product.quantity}</td>
//                                       <td>{product.weightperpiece}</td>
//                                       <td>{product.productweight}</td>
//                                       <td>{product.boq}</td>
//                                       <td>{product.saleprice}</td>
//                                       <td style={{ width: "10%" }}>
//                                         {product.isActive ? (
//                                           <Button
//                                             variant="success"
//                                             style={{ width: "100%" }}
//                                           >
//                                             Active
//                                           </Button>
//                                         ) : (
//                                           <Button
//                                             variant="danger"
//                                             style={{ width: "100%" }}
//                                           >
//                                             Deactivate
//                                           </Button>
//                                         )}
//                                       </td>
//                                       <td>
//                                         <Dropdown>
//                                           <Dropdown.Toggle
//                                             variant="transparent"
//                                             id="dropdown-basic"
//                                           >
//                                             <SlOptionsVertical />
//                                           </Dropdown.Toggle>
//                                           <Dropdown.Menu>
//                                             <Dropdown.Item
//                                               as={Link}
//                                               to={`/newProductsDetials/${product._id}`}
//                                             >
//                                               View
//                                             </Dropdown.Item>
//                                             <Dropdown.Item
//                                               onClick={() =>
//                                                 deleteCategory(product._id)
//                                               }
//                                             >
//                                               Delete
//                                             </Dropdown.Item>
//                                             <Dropdown.Item
//                                               as={Link}
//                                               to={`/editNewProducts/${product._id}`}
//                                             >
//                                               Edit
//                                             </Dropdown.Item>
//                                             <Dropdown.Item
//                                               onClick={() =>
//                                                 toggleCategoryStatus(
//                                                   product._id,
//                                                   product.isActive
//                                                 )
//                                               }
//                                             >
//                                               {product.isActive
//                                                 ? "Deactivate"
//                                                 : "Activate"}
//                                               {product.isActive ? (
//                                                 <BsToggleOn className="m-2 fs-6" />
//                                               ) : (
//                                                 <BsToggleOff className="m-2 fs-6" />
//                                               )}
//                                             </Dropdown.Item>
//                                           </Dropdown.Menu>
//                                         </Dropdown>
//                                       </td>
//                                     </tr>
//                                   ))}
//                                 </tbody>
//                               )}
//                             </table>
//                           </div>
//                         </Tab>
//                       ))}
//                     {/* {subCategories.length > maxTabsToShow && (
//                       <Tab id="view-more" style={{backgroundColor:"pink"}} eventKey="view-more-subcategories" title={showMoreSubCategories ? "Show Less" : "View More"}>
//                         <Button
//                           variant="link"
//                           onClick={() =>
//                             setShowMoreSubCategories(!showMoreSubCategories)
//                           }
//                         >
//                           {showMoreSubCategories ? "Show Less" : "View More"}
//                         </Button>
//                       </Tab>
//                     )} */}
//                   </Tabs>
//                 </>
//               ) : (
//                 <div className="no-subcategories-message">
//                   <h6 className="text-muted m-3">No subcategories available</h6>
//                 </div>
//               )}
//             </Tab>
//           ))}
//         {category.length > maxTabsToShow && (
//           <Tab eventKey="view-more-categories" title="View More">
//             <Button
//               variant="link"
//               onClick={() => setShowMoreCategories(!showMoreCategories)}
//             >
//               {showMoreCategories ? "Show Less" : "View More"}
//             </Button>
//           </Tab>
//         )}

//             </Tabs>
//     </div>
//   );
// }

// export default NewproductsViewPage;








// import React, { useContext, useEffect, useState } from "react";
// import "../../../components/Headers/Sidebar/Sidebar.scss";
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { AiFillDelete, AiFillEye } from "react-icons/ai";
// import { BsToggleOff, BsToggleOn } from "react-icons/bs";
// import { FiEdit } from "react-icons/fi";
// import { API_BASE_URL } from "../../../utils/index";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import { useAuthHeaders } from "../../../components/Token";
// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";
// import { SlOptionsVertical } from "react-icons/sl";
// import { Dropdown } from "react-bootstrap";
// import userContext from "../../../components/UseContext/UseContext";

// function NewproductsViewPage() {
//   const [data, setData] = useState([]);
//   const [category, setCategory] = useState([]);
//   const [errorMessage, setErrorMessage] = useState("");
//   const headers = useAuthHeaders();
//   const navigate = useNavigate();
//   const [categoryId, setCategoryId] = useState("")
//   const exportContext = useContext(userContext)

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/category/user/getcategory`)
//       .then((response) => {
//         setCategory(response?.data?.categories || []);
//         console.log("first useEffect from new Product view pages", category)
//         const initialCategory = response?.data?.categories[0]?._id;
//         onSelectCategory(initialCategory);
//         setCategoryId(initialCategory)
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const deleteCategory = (categoryId) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "Are you sure you want to delete this Product",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         axios
//           .delete(
//             `${API_BASE_URL}/admin/products/deleteproduct/${categoryId}`,
//             { headers }
//           )
//           .then(() => {
//             setData((prevData) =>
//               prevData.filter((item) => item?._id !== categoryId)
//             );
//           })
//           .catch((error) => {
//             console.error("Error deleting category:", error);

//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               text: error.response.data.error,
//             });
//           });
//         Swal.fire({
//           title: "Deleted!",
//           text: "Your Product has been deleted.",
//           icon: "success",
//         });
//       }
//     });

//     // if (shouldDelete) {

//     // }
//   };

//   const toggleCategoryStatus = (categoryId, isActive) => {
//     axios
//       .put(
//         `${API_BASE_URL}/admin/products/productStatus/${categoryId}`,
//         {
//           isActive: !isActive,
//         },
//         {
//           headers,
//         }
//       )
//       .then(() => {
//         setData((prevData) =>
//           prevData.map((item) =>
//             item._id === categoryId ? { ...item, isActive: !isActive } : item
//           )
//         );
//       })
//       .catch((error) => {
//         console.error("Error toggling category status:", error);

//         Swal.fire({
//           icon: "error",
//           title: "Oops...",
//           text: error.response.data.error,
//         });
//       });
//   };

//   const onSelectCategory = (categoryId) => {
//     axios
//       .get(`${API_BASE_URL}/admin/product/getproductbycategory/${categoryId}`)
//       .then((response) => {
//         setData(response?.data?.products);
//         setErrorMessage("");
//         exportContext.setExportProducts(response?.data?.products)
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         setErrorMessage(error?.response?.data?.message);
//       });
//   };

//   const onExport = () => {

//     navigate('../export')

//   }

//   return (
//     <div className="p-4">
//       <div className="d-flex justify-content-between my-2">
//         <h2 className="m-0">Products</h2>
//         <div style={{ width: "30%", display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
//           <Link to="/newProducts" className="btn btn-primary">
//             Add New
//           </Link>
//           <Link to='/bulkupload' className="btn btn-secondary" >Bluk Upload</Link>
//           <button className="btn btn-success" onClick={onExport}>
//             Export All
//           </button>
//         </div>
//       </div>

//       <Tabs
//         defaultActiveKey={category[0]?._id}
//         id="uncontrolled-tab-example"
//         className="mb-3"
//         onSelect={(key) => onSelectCategory(key)}
//       >
//         {category.map((item, i) => (
//           // <Tab eventKey={item?._id} title={item?.name} key={i}>
//           //   <table className="categorys-table">
//           //     <thead>
//           //       <tr>
//           //         <th>Part Number</th>
//           //         <th>Sub Category</th>
//           //         <th>Category</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //         <th>Action</th>
//           //       </tr>
//           //     </thead>

//           //     {errorMessage ? (
//           //       <h6 className="text-danger m-3">No Products</h6>
//           //     ) : (
//           //       <tbody>
//           //         {data.map((item, index) => (
//           //           <tr key={index}>
//           //             <td>{item?.partNumber}</td>
//           //             <td> {item?.subcategoryname?.name} </td>
//           //             <td> {item?.category?.name} </td>

//           //             <td>
//           //               <Dropdown>
//           //                 <Dropdown.Toggle
//           //                   variant="transparent"
//           //                   id="dropdown-basic"
//           //                 >
//           //                   <SlOptionsVertical />
//           //                 </Dropdown.Toggle>

//           //                 <Dropdown.Menu>
//           //                   <Dropdown.Item
//           //                     as={Link}
//           //                     to={`/newProductsDetials/${item?._id}`}
//           //                   >
//           //                     View
//           //                   </Dropdown.Item>
//           //                   <Dropdown.Item
//           //                     as={Link}
//           //                     onClick={() => deleteCategory(item?._id)}
//           //                   >
//           //                     Delete
//           //                   </Dropdown.Item>

//           //                   <Dropdown.Item
//           //                     to={`/editNewProducts/${item?._id}`}
//           //                     as={Link}
//           //                   >
//           //                     Edit
//           //                   </Dropdown.Item>

//           //                   <Dropdown.Item
//           //                     as={Link}
//           //                     onClick={() =>
//           //                       toggleCategoryStatus(item?._id, item?.isActive)
//           //                     }
//           //                   >
//           //                     {item?.isActive ? "Active" : "Deactivate"}
//           //                     {item?.isActive ? (
//           //                       <BsToggleOn className="m-2 fs-6" />
//           //                     ) : (
//           //                       <BsToggleOff className="m-2 fs-6" />
//           //                     )}
//           //                   </Dropdown.Item>
//           //                 </Dropdown.Menu>
//           //               </Dropdown>
//           //             </td>
//           //           </tr>
//           //         ))}
//           //       </tbody>
//           //     )}
//           //   </table>
//           // </Tab>
//           <Tab eventKey={item?._id} title={item?.name} key={i} style={{overflowY:"auto",maxWidth: "100%",backgroundColor:"purple"}}>
//         <div className="scrollable-container" style={{overflowY:"auto",maxWidth: "80%"}}>
//           <table className="categorys-table">
//             <thead>
//               <tr>
//                 <th>Part Number</th>
//                 <th>Sub Category</th>
//                 <th>Category</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//                 <th>Action</th>
//              <th>1</th>
//                 <th>444444444444444</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>
//                 <th>dsdfwsef</th>

//               </tr>
//             </thead>
//             {errorMessage ? (
//               <h6 className="text-danger m-3">No Products</h6>
//             ) : (
//               <tbody>
//                 {data.map((item, index) => (
//                   <tr key={index}>
//                     <td>{item?.partNumber}</td>
//                     <td>{item?.subcategoryname?.name}</td>
//                     <td>{item?.category?.name}</td>
//                     <td>
//                       <Dropdown>
//                         <Dropdown.Toggle
//                           variant="transparent"
//                           id="dropdown-basic"
//                         >
//                           <SlOptionsVertical />
//                         </Dropdown.Toggle>
//                         <Dropdown.Menu>
//                           <Dropdown.Item
//                             as={Link}
//                             to={`/newProductsDetials/${item?._id}`}
//                           >
//                             View
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             as={Link}
//                             onClick={() => deleteCategory(item?._id)}
//                           >
//                             Delete
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             to={`/editNewProducts/${item?._id}`}
//                             as={Link}
//                           >
//                             Edit
//                           </Dropdown.Item>
//                           <Dropdown.Item
//                             as={Link}
//                             onClick={() =>
//                               toggleCategoryStatus(item?._id, item?.isActive)
//                             }
//                           >
//                             {item?.isActive ? 'Active' : 'Deactivate'}
//                             {item?.isActive ? (
//                               <BsToggleOn className="m-2 fs-6" />
//                             ) : (
//                               <BsToggleOff className="m-2 fs-6" />
//                             )}
//                           </Dropdown.Item>
//                         </Dropdown.Menu>
//                       </Dropdown>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             )}
//           </table>
//         </div>
//       </Tab>

//         ))}
//       </Tabs>
//     </div>
//   );
// }

// export default NewproductsViewPage;





















































import React, { useContext, useEffect, useState } from "react";
import "../../../components/Headers/Sidebar/Sidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuthHeaders } from "../../../components/Token";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";


import { SlOptionsVertical } from "react-icons/sl";
import { Dropdown } from "react-bootstrap";
import userContext from "../../../components/UseContext/UseContext";
import Button from "react-bootstrap/Button";
import Lottie from "lottie-react";
import Preloader from "../../../Preloader/Preloader-Animation.json";




const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

function NewproductsViewPage() {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]); // it returns the ["Fasteners", "Tools", "Machines"]
  const [errorMessage, setErrorMessage] = useState("");
  const headers = useAuthHeaders();
  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState("");
  const [subCategories, setSubCategories] = useState([]); // it returns the [rivet, rivetNut, washer]
  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");

  const exportContext = useContext(userContext);

  const width = useWindowWidth();
  const [showMoreCategories, setShowMoreCategories] = useState(false);
  const [showMoreSubCategories, setShowMoreSubCategories] = useState(false);
  const [loading, setLoading] = useState(true);



  const getMaxTabsToShow = () => {
    if (width > 1200) return 8; // Desktop
    if (width > 768) return 5; // Tablet
    return 3; // Mobile
  };

  const maxTabsToShow = getMaxTabsToShow();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/category/user/getcategory`);
        const categories = response?.data?.categories || [];
        setCategory(categories);
        console.log("Fetched categories:", categories);

        if (categories.length > 0) {
          const initialCategory = categories[0]._id;
          setCategoryId(initialCategory);
          onSelectCategory(initialCategory);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      if (categoryId) {
        try {
          const response = await axios.get(`${API_BASE_URL}/category/admin/${categoryId}`);
          const subCategories = response?.data?.subcategoryNames || [];
          setSubCategories(subCategories);
          console.log("Fetched subcategories:", subCategories);

          if (subCategories.length > 0) {
            const initialSubCategory = subCategories[0]._id;
            const initialSubCategoryName = subCategories[0].name;
            setSubCategoryId(initialSubCategory);
            setSubCategoryName(initialSubCategoryName)
            onSelectSubCategory(initialSubCategory);
          } else {
            setSubCategoryId("");
            setSubCategoryName("");
            setData([]);
          }
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSubCategories();
  }, [categoryId]);

  useEffect(() => {
    if (subCategoryId) {
      onSelectSubCategory(subCategoryId);
    }
  }, [subCategoryId]);

  const onSelectCategory = async (categoryId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/category/admin/${categoryId}`);
      const subCategories = response?.data?.subcategoryNames || [];
      setSubCategories(subCategories);
      console.log("Fetched subcategories:", subCategories);

      if (subCategories.length > 0) {
        const initialSubCategory = subCategories[0]._id;
        // const initialSubCategory = subCategories[0].name;
        setSubCategoryId(initialSubCategory);
        onSelectSubCategory(initialSubCategory);
      } else {
        setSubCategoryId("");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectSubCategory = async (subCategoryId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_BASE_URL}/admin/product/getprodutbysubcategory/${subCategoryId}`
      );
      const products = response?.data?.products || [];
      setData(products);
      setErrorMessage("");
      exportContext.setExportProducts(products);
      console.log("Products by subcategory:", products);
    } catch (error) {
      console.error("Error fetching products by subcategory:", error);
      setErrorMessage(
        error?.response?.data?.message || "Error fetching products."
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          await axios.delete(
            `${API_BASE_URL}/admin/products/deleteproduct/${categoryId}`,
            { headers }
          );
          setData((prevData) =>
            prevData.filter((item) => item._id !== categoryId)
          );
          Swal.fire("Deleted!", "Your Product has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting category:", error);
          Swal.fire(
            "Oops...",
            error?.response?.data?.error || "Error deleting product.",
            "error"
          );
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const toggleCategoryStatus = async (categoryId, isActive) => {
    try {
      setLoading(true);
      await axios.put(
        `${API_BASE_URL}/admin/products/productStatus/${categoryId}`,
        { isActive: !isActive },
        { headers }
      );
      setData((prevData) =>
        prevData.map((item) =>
          item._id === categoryId ? { ...item, isActive: !isActive } : item
        )
      );
    } catch (error) {
      console.error("Error toggling category status:", error);
      Swal.fire(
        "Oops...",
        error?.response?.data?.error || "Error toggling status.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const onExport = () => {
    setLoading(true);
    navigate("../export");
    setLoading(false);
  };


  return (
    <div className="p-4">
      <div className="d-flex justify-content-between my-2">
        <h2 className="m-0">Products</h2>
        <div
          style={{
            width: "34%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: "10px",
          }}
        >
          <Link to="/newProducts" className="btn btn-primary">
            Add New
          </Link>
          <Link to="/bulkupload" className="btn btn-secondary">
            Bulk Upload
          </Link>
          <button className="btn btn-success" onClick={onExport}>
            Export All
          </button>
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center mt-5">

          <Lottie
            style={{ width: "100vw", height: "50vh" }}
            animationData={Preloader}
          />
        </div>
      ) : (
      <Tabs
        defaultActiveKey={category.find((cat) => cat._id === categoryId)?._id}
        id="category-tabs"
        className="mb-3"
        onSelect={(key) => setCategoryId(key)}
        style={{
          backgroundColor: "#0443C0",
          fontWeight: "bold",
          color: "#F5F7F8",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
   
   
        {category
          .slice(0, showMoreCategories ? category.length : maxTabsToShow)
          .map((item) => (
            // <Tab eventKey={item?._id} title={item?.name} key={item?._id} >
            <Tab eventKey={item?._id} title={item?.name} key={item?.name} >
              {subCategories.length > 0 ? (
                <>
                      {loading ? (
        <Lottie
          style={{ width: "100vw", height: "50vh"}}
          animationData={Preloader}
        />
      ) : ( 
                
                  <Tabs
                    defaultActiveKey={
                      subCategories.find((sub) => sub._id === subCategoryId)?._id}
                      // subCategories.find((sub) => sub._id === subCategoryId)?.name}
                    id="subcategory-tabs"
                    className="mb-3"
                    onSelect={(key) => setSubCategoryId(key)}
                    style={{
                      backgroundColor: "#F04B25",
                      fontWeight: "bold",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  >
                    {subCategories
                      // .slice(
                      //   0,
                      //   showMoreSubCategories
                      //     ? subCategories.length
                      //     : maxTabsToShow
                      // )
                      .map((subItem) => (
                        <Tab
                          eventKey={subItem._id}
                          title={subItem.name}
                          // key={subItem._id}
                          key={subItem.name}
                          style={{ backgroundColor: "#EEEEEE" }}
                        >
                          <div
                            className="scrollable-container"
                            style={{ overflowY: "auto", maxWidth: "100%",height:"100vh" }}
                          >
                            <table className="categorys-table">
                              <thead>
                                <tr>
                                  <th>Part Number</th>
                                  <th>Sub Category</th>
                                  <th>Category</th>
                                  <th>Quantity</th>
                                  <th>Weight Per Piece</th>
                                  <th>Product Weight</th>
                                  <th>Box of Quantity</th>
                                  <th>Sale Price</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {data.length === 0 ? (
                                <h6 className="text-danger m-3">No Products</h6>
                              ) : (
                                <tbody>
                                  {data.map((product, index) => (
                                    <tr key={index}>
                                      <td>{product.partNumber}</td>
                                      <td>{product.subcategoryname?.name}</td>
                                      <td>{product.category?.name}</td>
                                      <td>{product.quantity}</td>
                                      <td>{product.weightperpiece}</td>
                                      <td>{product.productweight}</td>
                                      <td>{product.boq}</td>
                                      <td>{product.saleprice}</td>
                                      <td style={{ width: "10%" }}>
                                        {product.isActive ? (
                                          <Button
                                            variant="success"
                                            style={{ width: "100%" }}
                                          >
                                            Active
                                          </Button>
                                        ) : (
                                          <Button
                                            variant="danger"
                                            style={{ width: "100%" }}
                                          >
                                            Deactivate
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="transparent"
                                            id="dropdown-basic"
                                          >
                                            <SlOptionsVertical />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              as={Link}
                                              to={`/newProductsDetials/${product._id}`}
                                              // to={`/newProductsDetials/${product.partNumber}`}
                                              
                                            >
                                              {console.log("product details came or not--------->",product)}
                                              View
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                deleteCategory(product._id)
                                              }
                                            >
                                              Delete
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              as={Link}
                                              to={`/editNewProducts/${product._id}`}
                                              // to={`/editNewProducts/${product.partNumber}`}
                                            >
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                toggleCategoryStatus(
                                                  product._id,
                                                  product.isActive
                                                )
                                              }
                                            >
                                              {product.isActive
                                                ? "Deactivate"
                                                : "Activate"}
                                              {product.isActive ? (
                                                <BsToggleOn className="m-2 fs-6" />
                                              ) : (
                                                <BsToggleOff className="m-2 fs-6" />
                                              )}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </Tab>
                      ))}
                    {/* {subCategories.length > maxTabsToShow && (
                      <Tab id="view-more" style={{backgroundColor:"pink"}} eventKey="view-more-subcategories" title={showMoreSubCategories ? "Show Less" : "View More"}>
                        <Button
                          variant="link"
                          onClick={() =>
                            setShowMoreSubCategories(!showMoreSubCategories)
                          }
                        >
                          {showMoreSubCategories ? "Show Less" : "View More"}
                        </Button>
                      </Tab>
                    )} */}
                  </Tabs>
                  )}
                </>
              ) : (
                <div className="no-subcategories-message">
                  <h6 className="text-muted m-3">No subcategories available</h6>
                </div>
              )}
            </Tab>
          ))}



        {category.length > maxTabsToShow && (
          <Tab eventKey="view-more-categories" title="View More">
            <Button
              variant="link"
              onClick={() => setShowMoreCategories(!showMoreCategories)}
            >
              {showMoreCategories ? "Show Less" : "View More"}
            </Button>
          </Tab>
        )}

            </Tabs>
            )}
    </div>
  );
}

export default NewproductsViewPage;
