import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../../components/Token";
import { API_BASE_URL } from "../../../../utils/index";
import BackArrow from "../../../../components/MinorComponents/BackArrow";

function ViewFiltersTypes() {
  const { _id } = useParams();
  const [data, setdata] = useState({});
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefiltertype/${_id}`, {
        headers,
      })
      .then((response) => {
        setdata(response?.data?.filtertype);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  console.log("data in singlefiltertype", data);

  return (
    <div className="p-4">
      <BackArrow path="/editFiltersTypes" />
      <h2>Filter Details</h2>
      <h6>Filter Types :</h6>
      <p>
        {data.filter_Types &&
          data.filter_Types.map((item, index) => (
            <div key={index}>
              {/*<span>{index + 1}</span>) {item} */}
              <span>{index + 1}</span> {item}  
            </div>
          ))}
      </p>
      <h6>Category</h6>
      <p>{data?.category?.name}</p>

      <h6>createdAt</h6>
      <p>{data?.createdAt}</p>
    </div>
  );
}

export default ViewFiltersTypes;
