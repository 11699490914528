// // import Table from "react-bootstrap/Table";
// // import * as XLSX from "xlsx";
// // import axios from "axios";
// // import * as React from "react";
// // import { styled } from "@mui/material/styles";
// // import Button from "@mui/material/Button";
// // import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// // //======================
// // import { useState } from "react";
// // import { API_BASE_URL } from "../../../utils/index";
// // import { useNavigate } from "react-router-dom";
// // import { useAuthHeaders } from "../../../components/Token";
// // import Swal from "sweetalert2";
// // // import SampleJson from "./sample";

// // const VisuallyHiddenInput = styled("button")({
// //   clip: "rect(0 0 0 0)",
// //   clipPath: "inset(50%)",
// //   height: 1,
// //   overflow: "hidden",
// //   position: "absolute",
// //   bottom: 0,
// //   left: 0,
// //   whiteSpace: "nowrap",
// //   width: 1,
// // });

// // const BulkUpload = () => {
// //   const [excelFile, setExcelFile] = useState(null);
// //   const [typeError, setTypeErro] = useState(null);
// //   const [excelData, setExcelData] = useState(null);

// //   const headers = useAuthHeaders();

// //   // ==============
// //   const navigate = useNavigate();

// //   //=====================

// //   const handleFile = (e) => {
// //     e.preventDefault();
// //     let fileTypes = [
// //       "application/vnd.ms-excel",
// //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
// //       "text/csv",
// //     ];
// //     let selectedFile = e.target.files[0];
// //     if (selectedFile) {
// //       if (selectedFile && fileTypes.includes(selectedFile.type)) {
// //         setTypeErro(null);
// //         let reader = new FileReader();
// //         reader.readAsArrayBuffer(selectedFile);
// //         reader.onload = (e) => {
// //           setExcelFile(e.target.result);
// //         };
// //       } else {
// //         setTypeErro("Please select a valid excel file");
// //         setExcelFile(null);
// //       }
// //     } else {
// //       console.log("please select your file");
// //     }
// //   };

// //   const handleFileSubmit = (e) => {
// //     e.preventDefault();
// //     // console.log("==========   Type of exceldata==============>");
// //     if (excelFile !== null) {
// //       const workbook = XLSX.read(excelFile, { type: "buffer" });
// //       const worksheetName = workbook.SheetNames[0];
// //       const worksheet = workbook.Sheets[worksheetName];
// //       const data = XLSX.utils.sheet_to_json(worksheet);
// //       setExcelData(data.slice(0, 10));
// //     }
// //   };

// //   const uploadFile = () => {
// //     if (
// //       typeof excelData === "object" &&
// //       excelData !== null &&
// //       typeof excelData !== "undefined"
// //     ) {
// //       if (Object.keys(excelData).length !== 0) {
// //         const obj = { products: excelData };
// //         // console.log("type of exceldata *****====",excelData.category)
// //         // console.log("type of exceldata *****",typeof excelData)
// //         // console.log("===*********=== ",typeof obj)

// //         const timeout = Object.keys(excelData).length * 2;

// //         axios
// //           .post(`${API_BASE_URL}/admin/bulkupload`, obj, { headers })
// //           .then((result) => {
// //             Swal.fire({
// //               icon: "success",
// //               title: "Product  SuccessFully",
// //               showConfirmButton: true,
// //               timer: 32000,
// //               text: result.data.message,
// //             });
// //             setTimeout(() => {
// //               navigate("/newproductsViewPage");
// //             }, timeout * 20000);

// //             console.log(result.data.data);
// //           })
// //           .catch((error) => {
// //             Swal.fire({
// //               icon: "error",
// //               title: "Oops...",
// //               showConfirmButton: false,
// //               text: error.response.data.message,
// //               timer: 1500,
// //             });
// //           });
// //       }
// //     }
// //   };
// //   return (
// //     <div className="wrappper" style={{ padding: "3%" }}>
// //       <h3>Upload & view Excel sheet</h3>

// //       <form
// //         className="form-group custom-form"
// //         onSubmit={handleFileSubmit}
// //         style={{ display: "flex" }}
// //       >
// //         <input
// //           type="file"
// //           className="form-control"
// //           required
// //           onChange={handleFile}
// //           style={{ width: "50%", marginRight: "2%" }}
// //         />

// //         <Button
// //           component="label"
// //           role={undefined}
// //           variant="contained"
// //           tabIndex={-1}
// //         >
// //           View File
// //           <VisuallyHiddenInput type="submit" />
// //         </Button>

// //         <Button
// //           component="label"
// //           role={undefined}
// //           variant="contained"
// //           tabIndex={-1}
// //           startIcon={<CloudUploadIcon />}
// //           onClick={uploadFile}
// //           style={{ marginLeft: "2%" }}
// //         >
// //           Upload file
// //           <VisuallyHiddenInput type="submit" />
// //         </Button>

// //         {typeError && (
// //           <div className="alert alert-danger" role="alert">
// //             {typeError}
// //           </div>
// //         )}
// //       </form>
// //       <div className="viewer">
// //         {excelData ? (
// //           <div style={{ width: "90%", overflow: "auto", marginTop: "5%" }}>
// //             <Table striped bordered hover>
// //               <thead>
// //                 <tr>
// //                   {Object.keys(excelData[0]).map((val, i) => (
// //                     <th key={i}>{val}</th>
// //                   ))}
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {excelData.map((data, id) => (
// //                   <tr key={id}>
// //                     {Object.keys(data).map((d1) => (
// //                       <td
// //                         style={{ textAlign: "center", textWrap: "nowrap" }}
// //                         key={d1}
// //                       >
// //                         {data[d1]}
// //                       </td>
// //                     ))}
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </Table>
// //           </div>
// //         ) : (
// //           <div>No file is uplodaed</div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default BulkUpload;

// import React, { useState } from "react";
// import Table from "react-bootstrap/Table";
// import * as XLSX from "xlsx";
// import axios from "axios";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import Swal from "sweetalert2";
// import { API_BASE_URL } from "../../../utils/index";
// import { useNavigate } from "react-router-dom";
// import { useAuthHeaders } from "../../../components/Token";
// import GetAppIcon from "@mui/icons-material/GetApp";
// // import RotateLoader from 'react-spinners/RotateLoader'

// const VisuallyHiddenInput = styled("button")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

// const BulkUpload = () => {
//   const [excelFile, setExcelFile] = useState(null);
//   const [typeError, setTypeError] = useState(null);
//   const [excelData, setExcelData] = useState(null);
//   const [loading, setLoading] = useState(true);

//   const headers = useAuthHeaders();
//   const navigate = useNavigate();

//   const handleFile = (e) => {
//     e.preventDefault();
//     let fileTypes = [
//       "application/vnd.ms-excel",
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//       "text/csv",
//     ];
//     let selectedFile = e.target.files[0];
//     if (selectedFile) {
//       if (selectedFile && fileTypes.includes(selectedFile?.type)) {
//         setTypeError(null);
//         let reader = new FileReader();
//         reader.readAsArrayBuffer(selectedFile);
//         reader.onload = (e) => {
//           setExcelFile(e.target.result);
//         };
//       } else {
//         setTypeError("Please select a valid excel file");
//         setExcelFile(null);
//       }
//     } else {
//       console.log("please select your file");
//     }
//   };

//   const handleFileSubmit = (e) => {
//     e.preventDefault();
//     if (excelFile !== null) {
//       const workbook = XLSX.read(excelFile, { type: "buffer" });
//       const worksheetName = workbook?.SheetNames[0];
//       const worksheet = workbook?.Sheets[worksheetName];
//       const data = XLSX.utils.sheet_to_json(worksheet);
//       setExcelData(data.slice(0, 50));
//     }
//   };

//   const uploadFile = () => {
//     if (typeof excelData === "object" && excelData !== null) {
//       if (Object.keys(excelData).length !== 0) {
//         const obj = { products: excelData };
//         const timeout = Object.keys(excelData).length * 2;

//         setLoading(true);
//         axios
//           .post(`${API_BASE_URL}/admin/bulkupload`, obj, { headers })
//           .then((result) => {
//             setLoading(false);
//             Swal.fire({
//               icon: "success",
//               title: "Product Successfully Uploaded",
//               showConfirmButton: true,
//               timer: 32000,
//               text: result.data.message,
//             });
//             navigate("/newproductsViewPage");
//             setTimeout(() => {
//               navigate("/newproductsViewPage");
//             }, 2000);

//             // console.log(result.data.data);
//           })
//           .catch((error) => {
//             Swal.fire({
//               icon: "error",
//               title: "Oops...",
//               showConfirmButton: true,
//               text: error.response.data.message,
//               timer: 30000,
//             });
//           });
//       }
//     }
//   };

//   const handleCancel = () => {
//     setExcelFile(null);
//     setExcelData(null);
//     setTypeError(null);
//     document.getElementById("fileInput").value = null;
//   };

//   return (
//     <div className="wrapper" style={{ padding: "3%" }}>
//       <h3>Bulk Upload & view</h3>
//       <form
//         className="form-group custom-form bulkupload-file-form"
//         onSubmit={handleFileSubmit}
//         style={{ display: "flex" }}
//       >
//         <input
//           type="file"
//           id="fileInput"
//           className="form-control bulkupload-file-form-input"
//           required
//           onChange={handleFile}
//           style={{ width: "50%", marginRight: "2%" }}
//         />
//         <Button
//           component="label"
//           role={undefined}
//           variant="contained"
//           tabIndex={-1}
//         >
//           View File
//           <VisuallyHiddenInput type="submit" />
//         </Button>
//         <Button
//           component="label"
//           role={undefined}
//           variant="contained"
//           color="success"
//           tabIndex={-1}
//           startIcon={<CloudUploadIcon />}
//           onClick={uploadFile}
//           style={{ marginLeft: "2%" }}
//         >
//           Upload file
//           <VisuallyHiddenInput type="submit" />
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<GetAppIcon />}
//           href="public\Demo file for Bulk Upload.xlsx" // Assuming the file is in the public folder
//           download="demo-file.xlsx"
//           style={{ marginLeft: "2%" }}
//         >
//           Demo File
//         </Button>
//         {excelFile && (
//           <Button
//             component="label"
//             role={undefined}
//             variant="contained"
//             color="error"
//             tabIndex={-1}
//             onClick={handleCancel}
//             style={{ marginLeft: "2%" }}
//           >
//             Cancel
//           </Button>
//         )}
//         {typeError && (
//           <div className="alert alert-danger" role="alert">
//             {typeError}
//           </div>
//         )}
//       </form>

//       <div className="viewer">
//         {excelData ? (
//           <div style={{ width: "90%", overflow: "auto", marginTop: "5%" }}>
//             <Table striped bordered hover>
//               <thead>
//                 <tr>
//                   {Object.keys(excelData[0]).map((val, i) => (
//                     <th key={i}>{val}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {excelData.map((data, id) => (
//                   <tr key={id}>
//                     {Object.keys(data).map((d1) => (
//                       <td
//                         style={{ textAlign: "center", textWrap: "nowrap" }}
//                         key={d1}
//                       >
//                         {data[d1]}
//                       </td>
//                     ))}
//                   </tr>
//                 ))}
//               </tbody>
//             </Table>
//           </div>
//         ) : (
//           <div>No file is uploaded</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default BulkUpload;


import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import * as XLSX from "xlsx";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../../utils/index";
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";

const VisuallyHiddenInput = styled("button")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BulkUpload = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(true);

  const headers = useAuthHeaders();
  const navigate = useNavigate();

  const handleFile = (e) => {
    e.preventDefault();
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile?.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Please select a valid excel file");
        setExcelFile(null);
      }
    } else {
      console.log("please select your file");
    }
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook?.SheetNames[0];
      const worksheet = workbook?.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);  // Process all rows
    }
  };

  const uploadFile = () => {

    
    if (typeof excelData === "object" && excelData !== null) {
      if (Object.keys(excelData).length !== 0) {
        const obj = { products: excelData };

        setLoading(true);
        axios
          .post(`${API_BASE_URL}/admin/bulkupload`, obj, { headers })
          .then((result) => {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Product Successfully Uploaded",
              showConfirmButton: true,
              timer: 32000,
              text: result.data.message,
            });
            navigate("/newproductsViewPage");
            setTimeout(() => {
              navigate("/newproductsViewPage");
            }, 2000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              showConfirmButton: true,
              text: error.response.data.message,
              timer: 30000,
            });
          });
      }
    }
  };

  const handleCancel = () => {
    setExcelFile(null);
    setExcelData(null);
    setTypeError(null);
    document.getElementById("fileInput").value = null;
  };

  return (
    // <div className="wrapper" style={{ padding: "3%" }}>
    //   <h3>Upload & view Excel sheet</h3>
    //   <form
    //     className="form-group custom-form bulkupload-file-form"
    //     onSubmit={handleFileSubmit}
    //     style={{ display: "flex", marginBottom: "20px" }}
    //   >
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       startIcon={<GetAppIcon />}
    //       href="/demo-file.xlsx"  // Ensure this file is in the public folder
    //       download="demo-file.xlsx"
    //       style={{ marginRight: "2%" }}
    //     >
    //       Download Demo File
    //     </Button>

    //     <input
    //       type="file"
    //       id="fileInput"
    //       className="form-control bulkupload-file-form-input"
    //       required
    //       onChange={handleFile}
    //       style={{ width: "50%", marginRight: "2%" }}
    //     />
    //     <Button
    //       component="label"
    //       role={undefined}
    //       variant="contained"
    //       tabIndex={-1}
    //     >
    //       View File
    //       <VisuallyHiddenInput type="submit" />
    //     </Button>
    //     <Button
    //       component="label"
    //       role={undefined}
    //       variant="contained"
    //       color="success"
    //       tabIndex={-1}
    //       startIcon={<CloudUploadIcon />}
    //       onClick={uploadFile}
    //       style={{ marginLeft: "2%" }}
    //     >
    //       Upload file
    //       <VisuallyHiddenInput type="submit" />
    //     </Button>
    //     {excelFile && (
    //       <Button
    //         component="label"
    //         role={undefined}
    //         variant="contained"
    //         color="error"
    //         tabIndex={-1}
    //         onClick={handleCancel}
    //         style={{ marginLeft: "2%" }}
    //       >
    //         Cancel
    //       </Button>
    //     )}
    //     {typeError && (
    //       <div className="alert alert-danger" role="alert">
    //         {typeError}
    //       </div>
    //     )}
    //   </form>
      
    //   <div className="viewer">
    //     {excelData ? (
    //       <div style={{ width: "90%", overflow: "auto", marginTop: "5%" }}>
    //         <Table striped bordered hover>
    //           <thead>
    //             <tr>
    //               {Object.keys(excelData[0]).map((val, i) => (
    //                 <th key={i}>{val}</th>
    //               ))}
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {excelData.map((data, id) => (
    //               <tr key={id}>
    //                 {Object.keys(data).map((d1) => (
    //                   <td
    //                     style={{ textAlign: "center", textWrap: "nowrap" }}
    //                     key={d1}
    //                   >
    //                     {data[d1]}
    //                   </td>
    //                 ))}
    //               </tr>
    //             ))}
    //           </tbody>
    //         </Table>
    //       </div>
    //     ) : (
    //       <div>No file is uploaded</div>
    //     )}
    //   </div>
    // </div>
    <div className="wrapper" style={{ padding: "3%" }}>
          <h3>Bulk Upload & view</h3>
          <form
            className="form-group custom-form bulkupload-file-form"
            onSubmit={handleFileSubmit}
            style={{ display: "flex" }}
          >
            <input
              type="file"
              id="fileInput"
              className="form-control bulkupload-file-form-input"
              required
              onChange={handleFile}
              style={{ width: "50%", marginRight: "2%" }}
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
            >
              View File
              <VisuallyHiddenInput type="submit" />
            </Button>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              color="success"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              onClick={uploadFile}
              style={{ marginLeft: "1%" }}
            >
              Upload file
              <VisuallyHiddenInput type="submit" />
            </Button>
            <Button
              variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    href="/demo-file.xlsx"  // Ensure this file is in the public folder
                    download="demo-file.xlsx"
                    style={{ marginLeft: "1%" }}
            >
              Demo File
            </Button>
            {excelFile && (
              <Button
                component="label"
                role={undefined}
                variant="contained"
                color="error"
                tabIndex={-1}
                onClick={handleCancel}
                style={{ marginLeft: "2%" }}
              >
                Cancel
              </Button>
            )}
            {typeError && (
              <div className="alert alert-danger" role="alert">
                {typeError}
              </div>
            )}
          </form>
    
          <div className="viewer">
            {excelData ? (
              <div style={{ width: "90%", overflow: "auto", marginTop: "5%" }}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {Object.keys(excelData[0]).map((val, i) => (
                        <th key={i}>{val}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((data, id) => (
                      <tr key={id}>
                        {Object.keys(data).map((d1) => (
                          <td
                            style={{ textAlign: "center", textWrap: "nowrap" }}
                            key={d1}
                          >
                            {data[d1]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div style={{color:"red",textTransform:"uppercase"}}>No file is uploaded and Only 50 products data can be uploaded in sinlge Excel file </div>
            )}
          </div>
        </div>
  );
};

export default BulkUpload;
