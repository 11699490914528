import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from "../../utils";
import axios from "axios";
import { useAuthHeaders } from "../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Form from "react-bootstrap/Form";
import BackArrow from "../../components/MinorComponents/BackArrow";
import CancelButton from "../../components/MinorComponents/CancelButton";

function UpdateZone() {
  const { _id } = useParams();

  const [name, setName] = useState();
  const [weight, setWeight] = useState("");
  const [weightUnit, setWeightUnit] = useState("");
  const [defaultRate, setDefaultRate] = useState("");

  const navigate = useNavigate();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getsinglezone/${_id}`, { headers })
      .then((response) => {
        setName(response?.data?.Zone?.zonename);
        setWeight(response?.data?.Zone?.weight?.value);
        setWeightUnit(response?.data?.Zone?.weight?.unit);
        setDefaultRate(response?.data?.Zone?.defaultRate);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    const data = {};
    axios
      .put(
        `${API_BASE_URL}/admin/Zone/updateZone/${_id}`,
        {
          zonename: name,
          weight: {
            value: weight,
            unit: weightUnit,
          },
          defaultRate: defaultRate,
        },
        { headers }
      )
      .then((response) => {
        console.log("response :", response);
        Swal.fire({
          icon: "success",
          title: "Zone Updated Success",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/editNewZone");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response?.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Zone </h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Create Zone Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              className="w-100"
              required
              value={name}
              placeholder="Zone Name"
              onChange={(e) => setName(e.target?.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Weight :
            </label>
            <br />
            <div className="d-flex align-items-center">
              <input
                type="number"
                required
                min={0}
                className="w-100 weight-input"
                step="0.00001"
                onWheel={(event) => event.currentTarget.blur()}
                value={weight}
                placeholder="Zone Weight"
                onChange={(e) => setWeight(e.target?.value)}
              />
              <Form.Select
                aria-label="Default select example"
                className="weight-unit"
                value={weightUnit}
                onChange={(e) => setWeightUnit(e.target?.value)}
              >
                <option value="Kg">Kg</option>
                <option value="Gram">Gram</option>
              </Form.Select>
            </div>
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Zone Default Rate :
            </label>
            <br />

            <input
              type="number"
              required
              min={0}
              className="w-100"
              step="0.00001"
              onWheel={(event) => event.currentTarget.blur()}
              placeholder="Zone Rate"
              value={defaultRate}
              onChange={(e) => setDefaultRate(e.target?.value)}
            />
          </div>
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/editNewZone" />
        </div>
      </form>
    </div>
  );
}

export default UpdateZone;
