// import React, { useEffect, useState } from "react";
// import "../ProductCategory/productCategory.scss";
// import Form from "react-bootstrap/Form";
// import axios from "axios";
// import { Toast } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import SelectDescriptionType from "../../../components/SelectDescriptionType/SelectDescriptionType";
// import DescriptionCategory from "../../../components/DescriptionTitle/DescriptionCategory ";
// import DescriptionSubCategory from "../../../components/DescriptionTitle/DescriptionSubCategory";
// import DescriptionName from "../../../components/DescriptionTitle/DescriptionName";
// import DescriptionTypesField from "../../../components/DescriptionNameType/DescriptionTypesField";
// import DescriptionTypes from "../../../components/DescriptionTitle/DescriptionTypes";
// import {API_BASE_URL} from "../../../utils/index"


// function UnderSubCategory() {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [subCategory, setSubCategory] = useState([]);
//   const [selectedSubCategory, setSelectedSubCategory] = useState("");
//   const [discriptionName, setDiscriptionName] = useState([]);
//   const [selectedDiscriptionName, setSelectedDiscriptionName] = useState("");
//   const [discriptionType, setDiscriptionType] = useState([]);
//   const [selectedDescriptionType, setSelectedDescriptionType] = useState("");
//   const [discriptionMethod, setDiscriptionMethod] = useState([]);
//   const [selectedDescriptionMethod, setSelectedDescriptionMethod] =
//     useState("");
//   const [isSuccess, setIsSuccess] = useState(false);
//   const [show, setShow] = useState(false);
//   const [errorMsg, setErrorMsg] = useState(null);
//   const navigate = useNavigate();

//   // State for an array of input fields
//   const defaultData = {
//     name: "",
//     descriptionType: "",
//     type: "",
//   };

//   const [inputFields, setInputFields] = useState([{ ...defaultData }]);

//   const onChange = (e, index) => {
//     const { name, value } = e.target;
//     const data = [...inputFields];
//     data[index][name] = value;
//     setInputFields(data);
//   };

//   const onAddClick = () => {
//     setInputFields([...inputFields, { ...defaultData }]);
//   };

//   const onRemoveClick = (index) => {
//     const data = [...inputFields];
//     data.splice(index, 1);
//     setInputFields(data);
//   };
//   //

//   const sampleTost = (data) => {
//     console.log("came inside sampleTost");
//     return (
//       <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
//         <Toast.Body>{data}</Toast.Body>
//       </Toast>
//     );
//   };

//   useEffect(() => {
//     sampleTost("sample testing");
//     // Fetch categories
//     axios
//       .get(`${API_BASE_URL}/category/admin/getcategory`)
//       .then((response) => {
//         setCategories(response.data.categories);
//         // console.log(response.data.categories," catrgories")
//       })
//       .catch((error) => {
//         console.error("Error fetching category data:", error);
//       });
//     // Fetch Description method data
//     axios
//       .get(`${API_BASE_URL}/formfieldcategory/admin/getformfieldcategories`)
//       .then((response) => {
//         setDiscriptionMethod(response.data.formfieldcategory);
//         // console.log("response :",response.data.formfieldcategory)
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategory data:", error);
//       });
//   }, []);

//   const handleFormSubmit = (e) => {
//     e.preventDefault();

//     const descriptionMethodData = {
//       name: selectedDiscriptionName,
//       nametypes: selectedDescriptionType,
//       type: selectedDescriptionMethod,
//     };

//     axios
//       .post(`${API_BASE_URL}/admin/descriptionmethod`, {
//         categoryname: selectedCategory,
//         subcategoryname: selectedSubCategory,
//         descriptionmethod: [descriptionMethodData],
//       })
//       .then((response) => {
//         setIsSuccess(true);
//         // navigate("/underSubProductCategory");
//         // Clear the form and reset the state
//         setSubCategory("");
//         setSelectedCategory("");
//         setDiscriptionMethod("");
//         setDiscriptionType("");
//       })
//       .catch((error) => {
//         console.log("message AxiosError ", error?.AxiosError?.message);
//         console.log("message ", error?.response?.data?.message);
//         setShow(true);
//         if (error?.response?.data?.message) {
//           setErrorMsg(error?.response?.data?.message);
//           sampleTost(error?.response?.data?.message);
//         }
//         // console.error("Error adding Description Method:", error);
//         setIsSuccess(false);
//       });
//   };

//   return (
//     <div className="p-4">
//       <h2>Description</h2>
//       <Toast bg="danger" delay={500} show={show} autohide={true}>
//         <Toast.Body className="text-white">{errorMsg}</Toast.Body>
//       </Toast>
//       <form className="category" onSubmit={handleFormSubmit}style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}} >
//         <div className="row category-field">
//           <DescriptionCategory
//             categories={categories}
//             setSelectedCategory={setSelectedCategory}
//             selectedCategory={selectedCategory}
//             setSubCategory={setSubCategory}
//           />

//           <DescriptionSubCategory
//             subCategory={subCategory}
//             selectedSubCategory={selectedSubCategory}
//             setSelectedSubCategory={setSelectedSubCategory}
//             setDiscriptionName={setDiscriptionName}
//           />

//           <div className="border py-2 position-relative">
//             <h6>Description Method</h6>

//             {inputFields.map((field, index) => (
//               <div key={field.id} className="my-2 row">
//                 <DescriptionName
//                   discriptionName={discriptionName}
//                   setSelectedDiscriptionName={setSelectedDiscriptionName}
//                   selectedDiscriptionName={selectedDiscriptionName}
//                   setDiscriptionType={setDiscriptionType}
//                   onChange={(e) => onChange(e, index)}
//                 />

//                 {/* <DescriptionTypes discriptionType={discriptionType} /> */}

//                 <div className="col-md-4">
//                   <label htmlFor="name" className="fs-6">
//                     Type:
//                   </label>
//                   <br />
//                   <Form.Select
//                     id="subCategory"
//                     aria-label="Default select example"
//                     className="my-2"
//                     value={selectedDescriptionMethod}
//                     onChange={(e) =>
//                       setSelectedDescriptionMethod(e.target.value)
//                     }
//                   >
//                     <option value="">Select Description Method</option>
//                     {Array.isArray(discriptionMethod) &&
//                       discriptionMethod.map((method) => (
//                         // console.log("method",method)
//                         <option key={method._id} value={method.name}>
//                           {method.name}
//                         </option>
//                       ))}
//                   </Form.Select>
//                 </div>

//                 {/*  */}
//                 <div className="col-md-4">
//                   <div className="my-2">
//                     {selectedDescriptionMethod.trim() === "Drop Down" && (
//                       <div>
//                         <label htmlFor="name" className="fs-6">
//                           Description Type:
//                         </label>
//                         <br />
//                         <SelectDescriptionType
//                           discriptionType={discriptionType}
//                           value={selectedDescriptionType}
//                           onChange={setSelectedDescriptionType}
//                         />
//                       </div>
//                     )}

//                     {selectedDescriptionMethod.trim() === "Check Box" && (
//                       <div>
//                         <label htmlFor="name" className="fs-6">
//                           Description Type:
//                         </label>
//                         <br />
//                         <SelectDescriptionType
//                           discriptionType={discriptionType}
//                           value={selectedDescriptionType}
//                           onChange={setSelectedDescriptionType}
//                         />
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 {/*  */}

//                 {/* add field */}
//                 <span>
//                   {inputFields.length - 1 === index && (
//                     <button
//                       variant="contained"
//                       size="small"
//                       sx={{ ml: 2 }}
//                       type="button"
//                       className="bg-primary m-2 mx-4 text-light p-1 position-absolute"
//                       onClick={onAddClick}
//                       style={{ right: "0", top: "0" }}
//                     >
//                       Add
//                     </button>
//                   )}
//                 </span>
//                 {/* add field */}

//                 {/* Remove field */}
//                 <span>
//                   {inputFields.length > 0 && inputFields.length !== 1 && (
//                     <button
//                       variant="contained"
//                       size="small"
//                       color="error"
//                       sx={{ ml: 2 }}
//                       onClick={() => onRemoveClick(index)}
//                     >
//                       Remove
//                     </button>
//                   )}
//                 </span>
//                 {/* Remove field */}
//               </div>
//             ))}
//           </div>

//           <div className="buttons">
//             <button style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button >
//             <button type="button" onClick={() => setShow(false)} style={{ backgroundColor: "#F04B25", color: "white" }} >
//               Cancel
//             </button>
      
//           </div>
//         </div>
//       </form>
//       {isSuccess && <p>Description added successfully!</p>}
//     </div>
//   );
// }

// export default UnderSubCategory;
import React, { useEffect, useState, useCallback } from "react";
import "../ProductCategory/productCategory.scss";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Toast, ToastContainer } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import "./OfferPost.scss"; // Ensure you have the necessary styles

function OfferPost() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [images, setImages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();
  const adminId = localStorage.getItem("adminId");


  // Fetch categories on component mount
  useEffect(() => {

    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);


  const onDrop = useCallback((acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);


  const { getRootProps, getInputProps } = useDropzone({ onDrop });


  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("category", selectedCategory);

    images.forEach((image, index) => {
      formData.append(`offerposter`, image);
    });

    const headers = {
      Authorization: `Bearer ${adminId}`,
    };

    axios
      .post(`${API_BASE_URL}/admin/offerPoster`, formData, { headers })
      .then((response) => {
        Swal.fire("Success!", "Your Poster has been added successfully", "success");
        setSelectedCategory("");
        setImages([]);
        setShowToast(false);
        navigate("/underSubProductCategory");
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message || "An error occurred. Please try again.";
        setToastMessage(errorMessage);
        setShowToast(true);
      });
  };


  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  return (
    <div className="p-4">
      <h2>Description</h2>
      <form
        className="category"
        onSubmit={handleFormSubmit}
        style={{ boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset" }}
      >
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="category" className="fs-6">Category Name:</label>
            <Form.Select
              id="category"
              aria-label="Select Category"
              className="my-2"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="col-md-6">
            <label htmlFor="image" className="fs-6">Upload Images:</label>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="dropzone my-2"
            >
              <input {...getInputProps()} id="image" />
              <p>Drag & drop some files here, or click to select files</p>
            </div>
            <div className="image-preview">
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`preview-${index}`}
                    className="preview-image"

                    onLoad={() => URL.revokeObjectURL(image)} // Clean up object URLs

                  />
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => removeImage(index)}
                  >
                    <FaTimes />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="buttons">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="btn btn-danger"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
      <ToastContainer position="top-end" className="p-3">
        <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default OfferPost;
