import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/user/Login";
import DashBoard from "./Pages/DashBoard/DashBoard";
import ProductCategory from "./Pages/Masters/ProductCategory/ProductCategory";
import SubProductCategory from "./Pages/Masters/SubProductCategory/SubProductCategory";
import Categorys from "./Pages/Products/Categorys/Categorys";
import SubCategorys from "./Pages/Products/SubCategorys/SubCategorys";
import CategorysDetial from "./Pages/Products/Categorys/CategorysDetial/CategorysDetial";
import SubCategorysDetial from "./Pages/Products/SubCategorys/SubCategorysDetial/SubCategorysDetial";
import UnderSubProductCategoryDetial from "./Pages/Products/UnderSubProductCategory/UnderSubProductCategoryDetial/UnderSubProductCategoryDetial";
import EditCategory from "./Pages/Products/Categorys/EditCategory/EditCategory";
import EditUnderSubProductCategory from "./Pages/Products/UnderSubProductCategory/EditUnderSubProductCategory/EditUnderSubProductCategory";
import EditSubCategory from "./Pages/Products/SubCategorys/EditSubCategory/EditSubCategory";
import UnderSubProductCategory from "./Pages/Products/UnderSubProductCategory/UnderSubProductCategory";
import OfferPost from "./Pages/Masters/UnderSubCategory/UnderSubCategory";
import CreateDescriptionMethod from "./Pages/Masters/CreateDescriptionMethod/CreateDescriptionMethod";
import DescriptionMethod from "./Pages/Products/DescriptionMethod/DescriptionMethod";
import DescriptionMethodDetial from "./Pages/Products/DescriptionMethod/DescriptionMethodDetial/DescriptionMethodDetial";
import CreateDescriptionType from "./Pages/Masters/CreateDescriptionType/CreateDescriptionType";
import DescriptionType from "./Pages/Products/DescriptionType/DescriptionType";
import DescriptionTypeDetial from "./Pages/Products/DescriptionType/DescriptionTypeDetial/DescriptionTypeDetial";
import CreateDescriptionNameTypes from "./Pages/Masters/DescriptionNameTypes/CreateDescriptionNameTypes";
import EditDescriptionMethod from "./Pages/Products/DescriptionMethod/EditDescriptionMethod/EditDescriptionMethod";
import EditDescriptionType from "./Pages/Products/DescriptionType/EditDescriptionType/EditDescriptionType";
import NewProducts from "./Pages/Products/NewProducts/NewProducts";
import Register from "./components/user/Register";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import ViewDescriptionNameTypes from "./Pages/Products/DescriptionNameTypes/ViewDescriptionNameTypes";
import DescriptionNameTypesDetial from "./Pages/Products/DescriptionNameTypes/DescriptionNameTypesDetial";
import EditDescriptionNameTypesDetial from "./Pages/Products/DescriptionNameTypes/EditDescriptionNameTypesDetial";
import NewproductsViewPage from "./Pages/Products/NewProducts/NewproductsViewPage";
import NewProductsDetials from "./Pages/Products/NewProducts/NewProductsDetials";
import Filters from "./Pages/Products/Filters/Filters";
import { AuthProvider } from "./components/Token";
import FiltersDetials from "./Pages/Products/Filters/FiltersDetials";
import FilterViewPage from "./Pages/Products/Filters/FilterViewPage";
import FilterEdit from "./Pages/Products/Filters/FilterEdit";
import EditNewProducts from "./Pages/Products/NewProducts/EditNewProducts";
import FiltersTypes from "./Pages/Products/Filters/FiltersTypes/FiltersTypes";
import EditFiltersTypes from "./Pages/Products/Filters/FiltersTypes/EditFiltersTypes";
import ViewFiltersTypes from "./Pages/Products/Filters/FiltersTypes/ViewFiltersTypes";
import UpdateFiltersTypes from "./Pages/Products/Filters/FiltersTypes/UpdateFiltersTypes";
import ScrollTop from "./components/ScrollTop";
import OrderProducts from "./Pages/DashBoard/orderProducts/OrderProducts";
import AddNewZone from "./Pages/Shipping/AddNewZone";
import EditNewZone from "./Pages/Shipping/EditNewZone";
import ZoneDetials from "./Pages/Shipping/ZoneDetials";
import UpdateZone from "./Pages/Shipping/UpdateZone";
import ZoneRegion from "./Pages/Shipping/zoneRegion/ZoneRegion";
import EditZoneRegion from "./Pages/Shipping/zoneRegion/EditZoneRegion";
import ZoneRegionDetial from "./Pages/Shipping/zoneRegion/ZoneRegionDetial";
import UpdateZoneRegion from "./Pages/Shipping/zoneRegion/UpdateZoneRegion";
import AddShipping from "./Pages/Shipping/shipping/AddShipping";
import EditShipping from "./Pages/Shipping/shipping/EditShipping";
import UpdatedShipping from "./Pages/Shipping/shipping/UpdatedShipping";
import DetialShipping from "./Pages/Shipping/shipping/DetialShipping";
import AddCoupen from "./Pages/Coupon/AddCoupen";
import EditCoupen from "./Pages/Coupon/EditCoupen";
import DetialCoupen from "./Pages/Coupon/DetialCoupen";
import UpdateCoupen from "./Pages/Coupon/UpdateCoupen";
import userContext from "./components/UseContext/UseContext";
import ErrorBoundary from "./components/ErrorBoundary";
import Export from "./Pages/Products/NewProducts/Export";
import BulkUpload from "./Pages/Products/NewProducts/BulkUpload";
import Users from "./Pages/DashBoard/Users/Users";
import Enquiry from "./Pages/DashBoard/Inquiry/Enquiry";
import Notification from "./components/Headers/Navbar/Notification";
import ViewEnquiryDetails from "./Pages/DashBoard/Inquiry/viewenquiryDetails";
import Blogs from "./Pages/Masters/UnderSubCategory/Blog";
import Editblog from "./Pages/Masters/UnderSubCategory/Editblog";

function App() {
  useEffect(() => {
    localStorage.getItem("adminId");
  }, []);

  const [logOut, setLogOut] = useState(false);
  const [exportProducts, setExportProducts] = useState([]);

  const defaultValue = {
    logOut,
    setLogOut,
    exportProducts,
    setExportProducts,
  };

  return (
    <ErrorBoundary>
      <userContext.Provider value={defaultValue}>
        <AuthProvider>
          {/* <BulkUpload/> */}
          <BrowserRouter>
            <Routes>
              {/* <Route index element={<Register />} /> */}
              <Route path="/login" element={<Login />} />

              <Route path="/" element={<Layout />}>
                <Route path="/profilepage" element={<ProfilePage />} />
                <Route index element={<DashBoard />} />
                {/* Shipping */}
                <Route path="/addNewZone" element={<AddNewZone />} />
                <Route path="/editNewZone" element={<EditNewZone />} />
                <Route path="/zoneDetials/:_id" element={<ZoneDetials />} />
                <Route path="/viewenquiryDetails/:_id" element={< ViewEnquiryDetails />} />
                <Route path="/updateZone/:_id" element={<UpdateZone />} />
                <Route path="/addNewZoneRegion" element={<ZoneRegion />} />
                <Route path="/editNewZoneRegion" element={<EditZoneRegion />} />
                <Route
                  path="/zoneRegionDetial/:_id"
                  element={<ZoneRegionDetial />}
                />
                <Route
                  path="/updateZoneRegion/:_id"
                  element={<UpdateZoneRegion />}
                />
                <Route path="/addShipping" element={<AddShipping />} />
                <Route path="/editShipping" element={<EditShipping />} />
                <Route
                  path="/updatedShipping/:_id"
                  element={<UpdatedShipping />}
                />
                <Route
                  path="/detialShipping/:_id"
                  element={<DetialShipping />}
                />

                {/* Coupen */}
                <Route path="/addCoupen" element={<AddCoupen />} />
                <Route path="/editCoupen" element={<EditCoupen />} />
                <Route path="/detialCoupen/:_id" element={<DetialCoupen />} />
                <Route path="/updateCoupen/:_id" element={<UpdateCoupen />} />

                {/* master */}
                <Route path="/productcategory" element={<ProductCategory />} />
                <Route
                  path="/subproductcategory"
                  element={<SubProductCategory />}
                />
                <Route
                  path="/underSubProductCategory"
                  element={<UnderSubProductCategory />}
                />
                <Route path="/category" element={<Categorys />} />
                <Route path="/subCategory" element={<SubCategorys />} />
                <Route
                  path="/categorysDetial/:_id"
                  element={<CategorysDetial />}
                />
                <Route  element={<Notification/>}/>
                <Route
                  path="/subCategorysDetial/:_id"
                  element={<SubCategorysDetial />}
                />
                <Route
                  path="/underSubCategorysDetial/:_id"
                  element={<UnderSubProductCategoryDetial />}
                />
                <Route path="/editCategory/:_id" element={<EditCategory />} />
                <Route
                  path="/editUnderSubProductCategory/:_id"
                  element={<EditUnderSubProductCategory />}
                />
                <Route
                  path="/editSubCategory/:_id"
                  element={<EditSubCategory />}
                />
                <Route
                  path="/offerpost"
                  element={<OfferPost />}
                />
                 <Route
                  path="/blog"
                  element={<Blogs />}
                />
                <Route
                  path="/editblog"
                  element={<Editblog />}
                />
                <Route
                  path="/createDescriptionMethod"
                  element={<CreateDescriptionMethod />}
                />
                <Route
                  path="/descriptionMethod"
                  element={<DescriptionMethod />}
                />
                <Route
                  path="/descriptionMethod/:_id"
                  element={<DescriptionMethodDetial />}
                />
                <Route
                  path="/createDescriptionType"
                  element={<CreateDescriptionType />}
                />
                <Route path="/descriptionType" element={<DescriptionType />} />
                <Route
                  path="/descriptionTypeDetial/:_id"
                  element={<DescriptionTypeDetial />}
                />
                <Route
                  path="/editDescriptionNameTypesDetial/:_id"
                  element={<EditDescriptionNameTypesDetial />}
                />
                <Route
                  path="/createDescriptionNameTypes"
                  element={<CreateDescriptionNameTypes />}
                />
                <Route
                  path="/viewDescriptionNameTypes"
                  element={<ViewDescriptionNameTypes />}
                />
                <Route
                  path="/descriptionNameTypesDetial/:_id"
                  element={<DescriptionNameTypesDetial />}
                />
                <Route
                  path="/editDescriptionType/:_id"
                  element={<EditDescriptionType />}
                />
                <Route
                  path="/editDescriptionMethod/:_id"
                  element={<EditDescriptionMethod />}
                />
                <Route path="/newProducts" element={<NewProducts />} />
                <Route
                  path="/newproductsViewPage"
                  element={<NewproductsViewPage />}
                />
                <Route
                  path="/newProductsDetials/:_id"
                  element={<NewProductsDetials />}
                />

                <Route
                  path="/editNewProducts/:_id"
                  element={<EditNewProducts />}
                />

                <Route path="/filters" element={<Filters />} />
                <Route path="/filtersDetials" element={<FiltersDetials />} />
                <Route
                  path="/filterViewPage/:_id"
                  element={<FilterViewPage />}
                />
                <Route path="/filterEdit/:_id" element={<FilterEdit />} />
                <Route path="/filtersTypes" element={<FiltersTypes />} />
                <Route
                  path="/editFiltersTypes"
                  element={<EditFiltersTypes />}
                />
                <Route
                  path="/viewFiltersTypes/:_id"
                  element={<ViewFiltersTypes />}
                />
                <Route
                  path="/updateFiltersTypes/:_id"
                  element={<UpdateFiltersTypes />}
                />
                <Route path="/orderProducts" element={<OrderProducts />} />
                <Route path="/export" element={<Export />} />
                <Route path="/bulkupload" element={<BulkUpload />} />
                <Route path="/users" element={<Users/>}/>
                <Route path="/inquiry" element={<Enquiry/>}/>
              </Route>
            </Routes>
            <ScrollTop />
          </BrowserRouter>
        </AuthProvider>
      </userContext.Provider>
    </ErrorBoundary>
  );
}

export default App;




