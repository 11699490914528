import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Form from "react-bootstrap/Form";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function Filters() {
  const [name, setName] = useState();
  const navigate = useNavigate();
  const headers = useAuthHeaders();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [submitCategory, setSubmitCategory] = useState("");

  const handleCategoryCreation = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    axios
      .post(
        `${API_BASE_URL}/admin/filter`,
        {
          name: name,
          subcategory: selectedSubCategory,
          category: submitCategory,
        },
        {
          headers: headers,
        }
      )
      .then((result) => {
        console.log("result in filter", result);
        Swal.fire({
          icon: "success",
          title: "filter work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/filtersDetials");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "The Internet?",
          text: error.response.data.message,
          icon: "question",
        });

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/category/admin/getcategory`, { headers })
      .then((response) => {
        setCategories(response?.data?.categories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);

  const handleSelectSubCategory = (e) => {
    const categoryID = e.target.value;

    const findSubCategory = categories.find((item) => item?._id === categoryID);

    setSelectedCategory(categoryID);
    setSubmitCategory(findSubCategory?.name);

    axios
      .get(`${API_BASE_URL}/category/admin/${categoryID}`)
      .then((response) => {
        setSubCategories(response?.data?.subcategoryNames);
      })
      .catch((error) => {
        console.log("select sub category", error);
      });
  };

  return (
    <div className="p-3">
      <h2>Create Filter</h2>
      <form action="" className="category" onSubmit={handleCategoryCreation} style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="row category-field">
          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedCategory}
              onChange={handleSelectSubCategory}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category?._id} value={category?._id}>
                  {category?.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Sub Category Name:
            </label>
            <br />
            <Form.Select
              aria-label="Default select example"
              className="my-2"
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target?.value)}
              required
            >
              <option value="">Select a category</option>
              {subCategories.map((subCategories) => (
                <option key={subCategories?._id} value={subCategories?.name}>
                  {subCategories?.name}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="col-md-6">
            <label htmlFor="name" className="fs-6">
              Create Filter Name:
            </label>
            <br />
            <input
              type="text"
              id="name"
              onChange={(e) => setName(e.target?.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <button type="submit" value="send"  style={{ backgroundColor: "#0443C0", color: "white" }}>
            Save
          </button>
          <CancelButton path="/filtersDetials" />
        </div>
      </form>
    </div>
  );
}

export default Filters;
