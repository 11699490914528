import { useEffect, useState } from "react";
import "../../Pages/DashBoard/DashBoard.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Counter from "../../components/Counter/Counter";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import axios from "axios";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../../components/Token";
import { Link } from "react-router-dom";
//--------------Pie chart
import AdminPannelPieChart from "./Chart/AdminPannelPieChart";
import BarAnimation from "./Chart/WeeklyBarChart";

//-------Bar chart imports----

import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useNavigate } from "react-router-dom";

const size = {
  width: 400,
  height: 200,
};

function DashBoard() {
  const [orderData, setOrderData] = useState([]);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const headers = useAuthHeaders();
  const [errorMessage, setErrorMessage] = useState("");
  const [totalProducts, setTotalProducts] = useState(0);
  const [orders, setOrders] = useState([]);
  const [userLenght, setUserLength] = useState(0);
  const [totalInquiry, setTOtalInquiry] = useState(0);

  //-------------Bar chart
  const [weeks, setWeeks] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [monthlyAmounts, setMonthlyAmounts] = useState([]);
  const [yearly, setYearly] = useState([]);
  const [yearlyAmounts, setYearlyAmounts] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminId = localStorage.getItem("adminId");

        if (!adminId) {
          navigate("/login");
          return;
        }

        const headers = {
          Authorization: `Bearer ${adminId}`,
          "Content-Type": "application/json",
        };
        const response = await axios.get(
          `${API_BASE_URL}/admin/product/sales-data`,
          { headers }
        );
        console.log("response come in sales api", response.data);

        const weeksData = response.data.weekly.map((val) => val.period);
        const amountsData = response.data.weekly.map((val) => val.amount);
        const monthsData = response.data.monthly.map((val) => val.period);
        const monthsAmountsData = response.data.monthly.map(
          (val) => val.amount
        );
        const yearsData = response.data.yearly.map((val) => val.period);
        const yearsAmountsData = response.data.yearly.map((val) => val.amount);

        setWeeks(weeksData);
        setAmounts(amountsData);
        setMonthly(monthsData);
        setMonthlyAmounts(monthsAmountsData);
        setYearly(yearsData);
        setYearlyAmounts(yearsAmountsData);
      } catch (e) {
        console.log("error in api fetchin ", e);
      }
    };

    fetchData();
  }, [navigate]);

  //------------------Bar chart closing
  useEffect(() => {
    const adminId = localStorage.getItem("adminId");
    const headers = {
          Authorization: `Bearer ${adminId}`,
          "Content-Type": "application/json",
        };
    // Fetch order data
    axios
      .get(`${API_BASE_URL}/admin/order/getOrders`, { headers })
      .then((res) => {
        setOrderData(res?.data);
        console.log("status for the data", res?.data?.orders);
        setOrders(res?.data?.orders);
      })
      .catch((error) => {
        console.log("error", error);
      });

    axios
      .get(`${API_BASE_URL}/admin/users`, { headers })
      .then((response) => {
        setUserLength(response.data?.users?.length);
      })
      .catch((error) => {
        console.log("Error fetching users", error);
      });
    axios
      .get(`${API_BASE_URL}/admin/enquiry`, { headers })
      .then((response) => {
        setTOtalInquiry(response?.data?.EnquiryCount);
        // console.log(response.data.EnquiryCount);
      })
      .catch((error) => {
        console.log("Error fetching users", error);
      });

    // Fetch categories and then fetch products for each category
    axios
      .get(`${API_BASE_URL}/category/user/getcategory`)
      .then((response) => {
        console.log("get category list", response.data);
        const categories = response?.data?.categories || [];
        setCategory(categories);
        console.log("categories finding -------->",categories)
        // Fetch products for each category
        const fetchProductsPromises = categories.map((category) => {
          console.log("categories finding -------->",category)  
          return axios
            .get(
              `${API_BASE_URL}/Admin/product/getproductbycategory/${category._id}`
            )
            .then((response) => response.data.products)
            .catch((error) => {
              console.error("Error fetching data:------------>", error);
              setErrorMessage(
                error?.response?.data?.message || "Error fetching products"
              );
              return [];
            });
        });

        // Wait for all product fetch requests to complete
        Promise.all(fetchProductsPromises).then((allProducts) => {
          const products = allProducts.flat();
          setData(products);
          setTotalProducts(products.length);
        });
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [headers]);

  console.log("products length is ", totalProducts);

  const cardData = [
    {
      value: <Counter endValue={userLenght} />,
      label: "Users",
      color: "",
      navlinks: "/users",
    },
    {
      value: <Counter endValue={orderData?.totalOrder} />,
      label: "Total Orders",
      color: "bg-success",
      navlinks: "/orderProducts",
    },
    {
      value: <Counter endValue={totalProducts} />,
      label: "Products",
      color: "bg-warning",
      navlinks: "/newproductsViewPage",
    },
    {
      value: <Counter endValue={totalInquiry} />,
      label: "Inquiry",
      color: "bg-danger",
      navlinks: "/inquiry",
    },
  ];

  const val = orderData.map;

  return (
    <div className="dashboard container">
      <h1 className="text-dark py-2">DashBoard </h1>

      <section className="container">
        <section className="row"></section>
        <div className="card-container ">
          {cardData.map((card, index) => (
            <div key={index} className={`card ${card?.color} col `}>
              <div className="card-content">
                <h4>{card?.value}</h4>
                <p>{card?.label}</p>
              </div>
              <Link to={card?.navlinks} className="btn card-botton">
                View More
                <FaRegArrowAltCircleRight style={{ fontSize: "18px" }} />
              </Link>
            </div>
          ))}
        </div>
     
        <AdminPannelPieChart className="col-md-6"/>
       
      </section>
      <Tabs
        // defaultActiveKey="product"
        defaultActiveKey="week"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {/* <Tab eventKey="product" className="text-center" title="Products">
          <h1>Prodcuts chart</h1>
          <AdminPannelPieChart />
        </Tab> */}
        <Tab eventKey="week" className="text-center" title="Week">
          <h1>Weekley Sales Charts</h1>
          <div style={{ width: "100%", overflow: "auto" }}>
            <BarChart
              xAxis={[{ scaleType: "band", data: weeks }]}
              series={[{ data: amounts }]}
              width={1000}
              height={300}
            />
          </div>
        </Tab>
        <Tab eventKey="month" className="text-center" title="Month">
          <h1>Monthly Sales Charts</h1>
          <div style={{ width: "100%", overflow: "auto" }}>
            <BarChart
              xAxis={[{ scaleType: "band", data: monthly }]}
              series={[{ data: monthlyAmounts }]}
              width={1000}
              height={300}
            />
          </div>
        </Tab>
        <Tab eventKey="year" className="text-center" title="Year">
          <h1>Yearly Sales Charts</h1>
          <div style={{ width: "100%", overflow: "auto" }}>
            <BarChart
              xAxis={[{ scaleType: "band", data: yearly }]}
              series={[{ data: yearlyAmounts }]}
              width={1000}
              height={300}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default DashBoard;
