// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../../utils/index";

// const Users = () => {
//   const [userData, setUserData] = useState([]);

//   useEffect(() => {
//     axios
//       .get("${API_BASE_URL}/users")
//       .then((response) => {
//         setUserData(response.data);
//       })
//       .catch((error) => {
//         console.log("error showin in users all showing page", error.data);
//       });
//   }, []);

//   return (
//     <div>
//       <div
//         className="scrollable-container"
//         style={{ overflowY: "auto", maxWidth: "100%", height: "100vh" }}
//       >
//         <table className="categorys-table">
//           <thead>
//             <tr>
//               <th>S.No</th>
//               <th>User ID</th>
//               <th>Name</th>
//               <th>Mobile No</th>
//               <th>Email</th>
//               <th>Address</th>
//               <th>Verified</th>
//             </tr>
//           </thead>
//           {userData?.length === 0 ? (
//             <h6 className="text-danger m-3">No Users</h6>
//           ) : (
//             <tbody>
//               {userData.map((user, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{user?._id}</td>
//                   <td>{user?.name}</td>
//                   <td>{user?.mobileno}</td>
//                   <td>{user?.email}</td>
//                   <td>
//                     {user?.address.map((address, index) => {
//                       <ol>
//                         <li>{address.street}</li>
//                         <li>{address.country}</li>
//                         <li>{address.state}</li>
//                         <li>{address.district}</li>
//                         <li>{address.pincode}</li>
//                       </ol>;
//                     })}
//                   </td>
//                   <td>{user?.isVerified}</td>
//                 </tr>
//               ))}
//             </tbody>
//           )}
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Users;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../../utils/index";
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
// // import { useAuthHeaders } from "../../../components/Token";
// const Users = () => {
//   const [userData, setUserData] = useState([]);
// //   const header =useAuthHeaders();

//   useEffect(() => {
//     const adminId=   localStorage.getItem("adminId");
//     const headers = {
//         Authorization: `Bearer ${adminId}`,
//       };
//     axios
//       .get(`${API_BASE_URL}/admin/users`,{headers})
//       .then((response) => {
//         setUserData(response.data);
//       })
//       .catch((error) => {
//         console.log("Error showing in users all showing page", error);
//       });
//   }, []);

//   return (
//     <div className="container-fluid">
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">S.No</th>
//               <th scope="col">User ID</th>
//               <th scope="col">Name</th>
//               <th scope="col">Mobile No</th>
//               <th scope="col">Email</th>
//               <th scope="col">Address</th>
//               <th scope="col">Verified</th>
//             </tr>
//           </thead>
//           {userData.length === 0 ? (
//             <tbody>
//               <tr>
//                 <td colSpan="7" className="text-center text-danger">
//                   No Users
//                 </td>
//               </tr>
//             </tbody>
//           ) : (
//             <tbody>
//               {userData?.map((user, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{user._id}</td>
//                   <td>{user.name}</td>
//                   <td>{user.mobileno}</td>
//                   <td>{user.email}</td>
//                   <td>
//                     {user.address.map((address, idx) => (
//                       <ol key={idx} className="list-unstyled">
//                         <li>{address.street}</li>
//                         <li>{address.country}</li>
//                         <li>{address.state}</li>
//                         <li>{address.district}</li>
//                         <li>{address.pincode}</li>
//                       </ol>
//                     ))}
//                   </td>
//                   <td>{user.isVerified ? "Yes" : "No"}</td>
//                 </tr>
//               ))}
//             </tbody>
//           )}
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Users;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../../utils/index";
// import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
// // import { useAuthHeaders } from "../../../components/Token";

// const Users = () => {
//   const [userData, setUserData] = useState([]);

//   useEffect(() => {
//     const adminId = localStorage.getItem("adminId");
//     const headers = {
//       Authorization: `Bearer ${adminId}`,
//     };
//     axios
//       .get(`${API_BASE_URL}/admin/users`, { headers })
//       .then((response) => {
//         // Check if the response data is an array
//         if (Array.isArray(response.data.users)) {
//           setUserData(response.data.users);
//         } else {
//           console.error("Unexpected response format", response.data);
//           setUserData([]);
//         }
//       })
//       .catch((error) => {
//         console.log("Error fetching users", error);
//         setUserData([]); // Ensure state is an empty array in case of error
//       });
//   }, []);

//   return (
//     <div className="container-fluid">
//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">S.No</th>
//               {/* <th scope="col">User ID</th> */}
//               <th scope="col">Name</th>
//               <th scope="col">Mobile No</th>
//               <th scope="col">Email</th>
//               <th scope="col">Address</th>
//               <th scope="col">district</th>
//               <th scope="col">state</th>
//               <th scope="col">country</th>
//               <th scope="col">pincode</th>
//             </tr>
//           </thead>
//           {userData.length === 0 ? (
//             <tbody>
//               <tr>
//                 <td colSpan="7" className="text-center text-danger">
//                   No Users
//                 </td>
//               </tr>
//             </tbody>
//           ) : (
//             <tbody>
//               {userData.map((user, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   {/* <td>{user._id}</td> */}
//                   <td>{user.name}</td>
//                   <td>{user.mobileno}</td>
//                   <td>{user.email}</td>

//                   <td>
//                     <table>
//                       {user.address && user.address.length > 0 ? (
//                         user.address.map((address, idx) => {
//                           <tr>
//                             <td>{address.street}</td>
//                           </tr>;
//                         })
//                       ) : (
//                         <p colSpan="5">No address information available</p>
//                       )}
//                     </table>
//                   </td>
//                   <td>
//                     <table>
//                       {user.address && user.address.length > 0 ? (
//                         user.address.map((address, idx) => {
//                           <tr>
//                             <td>{address.district}</td>
//                           </tr>;
//                         })
//                       ) : (
//                         <p colSpan="5">No address information available</p>
//                       )}
//                     </table>
//                   </td>

//                   <td>
//                     <table>
//                       {user.address && user.address.length > 0 ? (
//                         user.address.map((address, idx) => {
//                           <tr>
//                             <td>{address.pincode}</td>
//                           </tr>;
//                         })
//                       ) : (
//                         <p colSpan="5">No address information available</p>
//                       )}
//                     </table>
//                   </td>

//                   <td>
//                     <table>
//                       {user.address && user.address.length > 0 ? (
//                         user.address.map((address, idx) => {
//                           <tr>
//                             <td>{address.country}</td>
//                           </tr>;
//                         })
//                       ) : (
// ""
//                       )}
//                     </table>
//                   </td>

//                   <td>
//                     <table>
//                       {user.address && user.address.length > 0 ? (
//                         user.address.map((address, idx) => {
//                           <tr>
//                             <td>{address.country}</td>
//                           </tr>;
//                         })
//                       ) : (
//                         <p colSpan="5">No address information available</p>
//                       )}
//                     </table>
//                   </td>

//                   {/* <td>{user.isVerified ? "Yes" : "No"}</td> */}
//                 </tr>
//               ))}
//             </tbody>
//           )}
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Users;






















// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../../utils/index";
// import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
// import "./Users.scss"; // Import the CSS file
// import { FaCircleUser } from "react-icons/fa6";
// import { FaUsers } from "react-icons/fa";

// const Users = () => {
//   const [userData, setUserData] = useState([]);

//   useEffect(() => {
//     const adminId = localStorage.getItem("adminId");
//     const headers = {
//       Authorization: `Bearer ${adminId}`,
//     };
//     axios
//       .get(`${API_BASE_URL}/admin/users`, { headers })
//       .then((response) => {
//         if (Array.isArray(response.data.users)) {
//           setUserData(response.data.users);
//         } else {
//           console.error("Unexpected response format", response.data);
//           setUserData([]);
//         }
//       })
//       .catch((error) => {
//         console.log("Error fetching users", error);
//         setUserData([]); // Ensure state is an empty array in case of error
//       });
//   }, []);

//   return (
//     <div className="container">
//       <div className="table-responsive">
// <h4 className="mt-4"> User List <FaUsers  /></h4>
//         <table className="table table-striped table-bordered">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">S.No</th>
//               <th scope="col">Name</th>
//               <th scope="col">Mobile No</th>
//               <th scope="col">Email</th>
//               <th scope="col" className="nowrap">Street</th>
//               <th scope="col" className="nowrap">District</th>
//               <th scope="col" className="nowrap">State</th>
//               <th scope="col" className="nowrap">Country</th>
//               <th scope="col" className="nowrap">Pincode</th>
//             </tr>
//           </thead>
//           {userData.length === 0 ? (
//             <tbody>
//               <tr>
//                 <td colSpan="9" className="text-center text-danger">
//                   No Users
//                 </td>
//               </tr>
//             </tbody>
//           ) : (
//             <tbody>
//               {userData.map((user, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{user.name}</td>
//                   <td>{user.mobileno}</td>
//                   <td>{user.email}</td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.street}</div>
//                       ))
//                     ) : (
//                       <div>No address information available</div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.district}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.state}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.country}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.pincode}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           )}
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Users;
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { API_BASE_URL } from "../../../utils/index";
// import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
// import "./Users.scss"; // Import the CSS file
// import { FaUsers, FaTrash } from "react-icons/fa";
// import Swal from "sweetalert2";

// const Users = () => {
//   const [userData, setUserData] = useState([]);

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = () => {
//     const adminId = localStorage.getItem("adminId");
//     const headers = {
//       Authorization: `Bearer ${adminId}`,
//     };
//     axios
//       .get(`${API_BASE_URL}/admin/users`, { headers })
//       .then((response) => {
//         if (Array.isArray(response.data.users)) {
//           setUserData(response.data.users);
//         } else {
//           console.error("Unexpected response format", response.data);
//           setUserData([]);
//         }
//       })
//       .catch((error) => {
//         console.log("Error fetching users", error);
//         setUserData([]); // Ensure state is an empty array in case of error
//       });
//   };

//   const handleDelete = (userId) => {
//     Swal.fire({
//       title: 'Are you sure?',
//       text: "You won't be able to revert this!",
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#3085d6',
//       cancelButtonColor: '#d33',
//       confirmButtonText: 'Yes, delete it!'
//     }).then((result) => {
//       if (result.isConfirmed) {
//         const adminId = localStorage.getItem("adminId");
//         const headers = {
//           Authorization: `Bearer ${adminId}`,
//         };
//         axios
//           .delete(`${API_BASE_URL}/admin/user/${userId}`, { headers })
//           .then(() => {
//             setUserData((prevData) =>
//               prevData.filter((user) => user._id !== userId)
//             );
//             Swal.fire(
//               'Deleted!',
//               'User has been deleted.',
//               'success'
//             );
//           })
//           .catch((error) => {
//             console.log("Error deleting user:", error);
//             Swal.fire(
//               'Error!',
//               'There was a problem deleting the user.',
//               'error'
//             );
//           });
//       }
//     });
//   };

//   return (
//     <div className="container">
//       <div className="table-responsive">
//         <h4 className="mt-4"> User List <FaUsers /></h4>
//         <table className="table table-striped table-bordered">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">S.No</th>
//               <th scope="col">Name</th>
//               <th scope="col">Mobile No</th>
//               <th scope="col">Email</th>
//               <th scope="col" className="nowrap">Street</th>
//               <th scope="col" className="nowrap">District</th>
//               <th scope="col" className="nowrap">State</th>
//               <th scope="col" className="nowrap">Country</th>
//               <th scope="col" className="nowrap">Pincode</th>
//               <th scope="col" className="nowrap">Actions</th>
//             </tr>
//           </thead>
//           {userData.length === 0 ? (
//             <tbody>
//               <tr>
//                 <td colSpan="10" className="text-center text-danger">
//                   No Users
//                 </td>
//               </tr>
//             </tbody>
//           ) : (
//             <tbody>
//               {userData.map((user, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{user.name}</td>
//                   <td>{user.mobileno}</td>
//                   <td>{user.email}</td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.street}</div>
//                       ))
//                     ) : (
//                       <div>No address information available</div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.district}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.state}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.country}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     {user.address && user.address.length > 0 ? (
//                       user.address.map((address, idx) => (
//                         <div key={idx}>{address.pincode}</div>
//                       ))
//                     ) : (
//                       <div></div>
//                     )}
//                   </td>
//                   <td className="nowrap">
//                     <button
//                       className="btn btn-danger btn-sm"
//                       onClick={() => handleDelete(user._id)}
//                     >
//                       <FaTrash />
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           )}
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Users;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../utils/index";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap CSS is imported
import "./Users.scss"; // Import the CSS file
import { FaUsers, FaTrash, FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";
import Lottie from 'lottie-react';
 import Preloader from "../../../Preloader/Preloader-Animation.json";// Ensure the path to the animation file is correct

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
 
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    const adminId = localStorage.getItem("adminId");
    const headers = {
      Authorization: `Bearer ${adminId}`,
    };
    axios
      .get(`${API_BASE_URL}/admin/users`, { headers })
      .then((response) => {
        if (Array.isArray(response.data.users)) {
          setUserData(response.data.users);
          setFilteredUsers(response.data.users);
        } else {
          console.error("Unexpected response format", response.data);
          setUserData([]);
          setFilteredUsers([]);
        }
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.log("Error fetching users", error);
        setUserData([]); // Ensure state is an empty array in case of error
        setFilteredUsers([]);
        setLoading(false); // Set loading to false in case of error
      });
  };

  const handleDelete = (userId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const adminId = localStorage.getItem("adminId");
        const headers = {
          Authorization: `Bearer ${adminId}`,
        };
        axios
          .delete(`${API_BASE_URL}/admin/user/${userId}`, { headers })
          .then(() => {
            setUserData((prevData) =>
              prevData.filter((user) => user._id !== userId)
            );
            setFilteredUsers((prevData) =>
              prevData.filter((user) => user._id !== userId)
            );
            Swal.fire(
              'Deleted!',
              'User has been deleted.',
              'success'
            );
          })
          .catch((error) => {
            console.log("Error deleting user:", error);
            Swal.fire(
              'Error!',
              'There was a problem deleting the user.',
              'error'
            );
          });
      }
    });
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = userData.filter((user) =>
      user.name.toLowerCase().includes(value) ||
      user.email.toLowerCase().includes(value) ||
      user.mobileno.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  return (
    <div className="container">


      <div className="mt-5">

      <div className=" ">
      <div className="d-flex mt-3 mb-4">
          <h4 className="mt-4"> Users List 
            {/* <FaUsers /> */}
             </h4>

            <div
              className="ms-auto d-flex h-25 rounded-2"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
            >
              <input
                type="text"
                className="form-control w-100 text-start border border-0"
                placeholder="Search..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FaSearch className="fs-3 mt-2 m-2" />
            </div>
          </div>
        {loading ? (
         <div className="d-flex justify-content-center mt-5">
         {/* <Spinner animation="border" /> */}<Lottie
     style={{ width: "100vw", height: "50vh" }}
     animationData={Preloader}
   />
       </div>

        ) : (
          <table className="table table-striped " style={{ border: "1px solid #60656b" }}>
            <thead className="thead-dark">
              <tr style={{ fontSize: "smaller" }} className="text-center">
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile No</th>
                <th scope="col">Email</th>
                <th scope="col" className="nowrap">Street</th>
                <th scope="col" className="nowrap">District</th>
                <th scope="col" className="nowrap">State</th>
                <th scope="col" className="nowrap">Country</th>
                <th scope="col" className="nowrap">Pincode</th>
                <th scope="col" className="nowrap">Actions</th>
              </tr>
            </thead>
            {filteredUsers.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan="10" className="text-center text-danger">
                    No Users
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.mobileno}</td>
                    <td>{user.email}</td>
                    <td className="nowrap">
                      {user.address && user.address.length > 0 ? (
                        user.address.map((address, idx) => (
                          <div key={idx}>{address.street}</div>
                        ))
                      ) : (
                        <div>No address information available</div>
                      )}
                    </td>
                    <td className="nowrap">
                      {user.address && user.address.length > 0 ? (
                        user.address.map((address, idx) => (
                          <div key={idx}>{address.district}</div>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </td>
                    <td className="nowrap">
                      {user.address && user.address.length > 0 ? (
                        user.address.map((address, idx) => (
                          <div key={idx}>{address.state}</div>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </td>
                    <td className="nowrap">
                      {user.address && user.address.length > 0 ? (
                        user.address.map((address, idx) => (
                          <div key={idx}>{address.country}</div>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </td>
                    <td className="nowrap">
                      {user.address && user.address.length > 0 ? (
                        user.address.map((address, idx) => (
                          <div key={idx}>{address.pincode}</div>
                        ))
                      ) : (
                        <div></div>
                      )}
                    </td>
                    <td className="nowrap">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(user._id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        )}
      </div>
      </div>
    </div>
  );
};

export default Users;
