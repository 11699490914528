import React, { useState } from "react";
import loginLogo from "../../Assets/Images/login-logo.png";
import "../../Assets/Images/loginPage-banner.jpg";
import "../../Pages/LoginPage/LoginPage.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { API_BASE_URL } from "../../utils/index";
import Swal from "sweetalert2";

export default function Login() {
  const [email, setEmail] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [hide, setHide] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${API_BASE_URL}/admin/login`, {
        email: email,
        password: verifyPassword,
      })
      .then((result) => {
        console.log("result Login", result.data);
        // navigate("/profilepage");
        navigate("/");
        Swal.fire({
          icon: "success",
          title: "Login Success",
          showConfirmButton: false,
          timer: 1500,
        });
        const adminId = result.data?.token;

        localStorage.setItem("adminId", adminId);
      })
      .catch((error) => {
        console.log("error", error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  return (
    <div className="login-page  d-flex align-items-center ">
      <div className="container ">
        <div className="row ">
          <div className="col-md-6">
            <div className="login-logor text-center">
              <img src={loginLogo} alt="loginLogo" />
            </div>
          </div>
          <div className="col-md-6">
            <form className="right" onSubmit={handleSubmit}>
              <p className="login-title">Login into your account</p>
              <div className="my-2">
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail Id"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="my-2 password-input">
                <input
                  className="form-control"
                  type={hide ? "text" : "password"}
                  name="password"
                  id="password"
                  value={verifyPassword}
                  placeholder="Password"
                  required
                  onChange={(e) => setVerifyPassword(e.target.value)}
                />

                <button type="button" onClick={() => setHide(!hide)}>
                  {hide ? "Hide" : "Show"}
                </button>
              </div>

              <div className="my-2">
                <button type="submit" className="btn submit-button ">
                  Login
                </button>
              </div>
              {/* <div>
                <input type="checkbox" className="form-check-input" />
                <lable className="form-check-label px-2">
                  Keep me logged in
                </lable>
              </div> */}
              {/* <Link to="/" className="text-light">
                Go to Register
              </Link> */}
            </form>
            {/* <button onClick={check}>Run Fetch</button>  */}
          </div>
        </div>
      </div>
    </div>
  );
}
