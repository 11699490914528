// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { API_BASE_URL } from "../../../../utils/index";
// import Form from "react-bootstrap/Form";

// function EditSubCategory() {
//   const { _id } = useParams();
//   const [newSubCategoryName, setNewSubCategoryName] = useState("");
//   const navigate = useNavigate();
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/subcategory/admin/singlesubcategory/${_id}`)
//       .then((response) => {
//         setNewSubCategoryName(response.data.subcategory.name);
//         console.log(response.data.subcategory.name, "response");
//       })
//       .catch((error) => {
//         console.error("Error fetching category:", error);
//       });

//     axios
//       .get(`${API_BASE_URL}/category/admin/getcategory`)
//       .then((response) => {
//         setCategories(response.data.categories);
//       })
//       .catch((error) => {
//         console.error("Error fetching category data:", error);
//       });
//   }, []);

//   const handleSave = (e) => {
//     e.preventDefault(); // Prevent the default form submission behavior
//     const data = { name: newSubCategoryName, categoryId: selectedCategory };
//     axios
//       .put(`${API_BASE_URL}/subcategory/admin/updatesubcategory/${_id}`, data)
//       .then((response) => {
//         console.log("response :", response);
//         navigate("/subCategory");
//       })
//       .catch((error) => {
//         console.error("Error updating category:", error);
//       });
//   };

//   const handleCategorySelect = (e) => {
//     const selectedCategory = e.target.value;
//     setSelectedCategory(selectedCategory);
//   };

//   return (
//     <div className="p-3">
//       <h2>Edit Sub Category</h2>
//       <form onSubmit={handleSave} className="category">
//         <div className="row category-field">
//           <div className="col-md-6">
//             <label htmlFor="name" className="fs-6">
//               Change Sub Category Name:
//             </label>
//             <br />
//             <input
//               type="text"
//               id="name"
//               value={newSubCategoryName}
//               onChange={(e) => setNewSubCategoryName(e.target.value)}
//             />
//           </div>

//           <div className="col-md-6">
//             <label htmlFor="name" className="fs-6">
//               Change Category Name:
//             </label>
//             <br />
//             <Form.Select
//               id="category"
//               aria-label="Default select example"
//               className="my-2"
//               required
//               value={selectedCategory}
//               onChange={handleCategorySelect}
//             >
//               <option value="">Select a category</option>
//               {categories.map((category) => (
//                 <option key={category._id} value={category.name}>
//                   {category.name}
//                 </option>
//               ))}
//             </Form.Select>
//           </div>
//         </div>
//         <div className="buttons">
//           <button type="submit">Save</button>
//           <button>Cancel</button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default EditSubCategory;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../../../utils/index";
import Form from "react-bootstrap/Form";
import { useAuthHeaders } from "../../../../components/Token";
import Swal from "sweetalert2/dist/sweetalert2.js";

function EditSubCategory() {
  const { _id } = useParams();
  const [newSubCategoryName, setNewSubCategoryName] = useState("");
  const navigate = useNavigate();
  const [categories, setCategories] = useState("");
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/subcategory/admin/singlesubcategory/${_id}`, {
        headers,
      })
      .then((response) => {
        setNewSubCategoryName(response.data?.subcategory?.name);
        setCategories(response.data?.subcategory?.category?.name);
        console.log("response in singlesubcategory", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    const data = { name: newSubCategoryName };
    axios
      .put(`${API_BASE_URL}/subcategory/admin/updatesubcategory/${_id}`, data, {
        headers,
      })
      .then((response) => {
        console.log("response :", response);
        navigate("/subCategory");
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  return (
    <div className="p-3">
      <h2>Edit Sub Category</h2>
      <form onSubmit={handleSave} className="category" style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}>
        <div className="category-field">
          <div className="d-flex">
            <label className="fs-6 fw-bold">Category Name:</label>
            <br />
            <p className="mx-2">{categories}</p>
          </div>

          <label htmlFor="name" className="fs-6 fw-bold">
            Change Sub Category Name:
          </label>
          <br />
          <input
            type="text"
            id="name"
            value={newSubCategoryName}
            onChange={(e) => setNewSubCategoryName(e.target?.value)}
          />
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <button style={{ backgroundColor: "#F04B25", color: "white" }}>Cancel</button>
        </div>
      </form>
    </div>
  );
}

export default EditSubCategory;
