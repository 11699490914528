import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import { API_BASE_URL } from "../../../utils/index";
import BackArrow from "../../../components/MinorComponents/BackArrow";

function FilterViewPage() {
  const { _id } = useParams();
  const [data, setdata] = useState({});
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/singlefilter/${_id}`, { headers })
      .then((response) => {
        setdata(response?.data?.filter);
        // console.log("response in view all",response.data.filter.category)
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="p-4">
      <BackArrow path="/filtersDetials" />
      <h2>Filter Details</h2>
      <h6>Name:</h6>
      <p>{data.name}</p>
      <h6>Categorie:</h6>
      <p>{data.category?.name}</p>
      <h6>createdAt</h6>
      <p>{data.createdAt}</p>
    </div>
  );
}

export default FilterViewPage;
