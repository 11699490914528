import React from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import {API_BASE_URL} from "../../utils/index"

export default function DescriptionNameTypeSubCategorys({
  selectedSubCategory,
  setSelectedSubCategory,
  subCategory,
  setDescriptionNameType,
  setSubmitSubCategory
}) {
  const handleSubCategorySelect = (e) => {
    const selectedSubCategory = e.target?.value;
    setSelectedSubCategory(selectedSubCategory);
    const subCategoryFind = subCategory?.find((findSub) => findSub?._id === selectedSubCategory)

    setSubmitSubCategory(subCategoryFind.name)

    axios
      .get(
        `${API_BASE_URL}/description/admin/descriptionnames/${selectedSubCategory}`
      )
      .then((response) => {
        setDescriptionNameType(response.data.discriptionnamesbysubcategory);
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error);
      });
  };

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Sub Category Name:
      </label>
      <br />
      <Form.Select
        aria-label="Default select example"
        className="my-2"
        value={selectedSubCategory}
        onChange={handleSubCategorySelect}
      >
        <option value="">Select a Sub category</option>
        {Array.isArray(subCategory) ? (
          subCategory.map((subcategory) => (
            <option key={subcategory._id} value={subcategory._id}>
              {subcategory?.name}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select>
    </div>
  );
}
