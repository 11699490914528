import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../utils";
import { useParams } from "react-router-dom";
import { useAuthHeaders } from "../../components/Token";
import BackArrow from "../../components/MinorComponents/BackArrow";

function DetialCoupen() {
  const [data, setData] = useState([]);
  const { _id } = useParams();
  const headers = useAuthHeaders();

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/getsinglecoupon/${_id}`, { headers })
      .then((res) => {
        setData(res.data?.Coupon);
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
  return (
    <div className="m-4">
      <BackArrow path="/editCoupen" />
      <h2>Coupon Detials</h2>
      <h6>Coupon Code :</h6>
      <p> {data?.Couponcode} </p>
      <h6>Coupon Type :</h6>
      <p> {data?.Coupontype} </p>
      <h6>Coupon Amount :</h6>
      <p> {data?.Couponamount} </p>
      <h6>Date :</h6>
      <p> {data?.expiresAt} </p>
    </div>
  );
}

export default DetialCoupen;
