import React from "react";
import brandLogo from "../../../Assets/Images/brand-logo.svg";
import "../Sidebar/Sidebar.scss";
import DashBoardIteams from "../../SidebarItems/DashBoardIteams";
import ProductsItems from "../../SidebarItems/ProductsItems";
import CouponItems from "../../SidebarItems/CouponItems";
import ShippingItems from "../../SidebarItems/ShippingItems"
import MasterItems from "../../SidebarItems/MasterItems"
import { Link } from "react-router-dom";

const Sidebar = ({ open }) => {

  return (
    <div className={open ? "sidebar active" : "sidebar"}>
      <div className="sidebar-logo">
       <Link to={"/"}> <img src={brandLogo} alt="" />
       </Link>
      </div>

      <ul className="sidebar-items">
        {/* DashBoard */}
        <DashBoardIteams />
        {/* DashBoard */}

        {/* Products */}
        <ProductsItems />
        {/* Products */}

        {/* Coupon */}
        <CouponItems />
        {/* Coupon */}

        {/* Shipping */}
        <ShippingItems />
        {/* Shipping */}

        {/* Master */}
        <MasterItems />
        {/* Master */}
      </ul>
    </div>
  );
};

export default Sidebar;
