import React from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";

export default function DescriptionNameTypeNameField({ descriptionNameType, selectedDescriptionNameType, setSelectedDescriptionNameType}) {

  const onSelect = (e) => {
    const selectedDescriptionNameType = e.target.value;
    setSelectedDescriptionNameType(selectedDescriptionNameType);
  }

  return (
    <div className="col-md-4">
      <label htmlFor="name" className="fs-6">
        Description Name :
      </label>
      <br />
      <Form.Select
        aria-label="Default select example"
        className="my-2"
        onChange={onSelect}
      > 
        <option value="">Select a Sub Description Name</option>
        {Array.isArray(descriptionNameType) ? (
          descriptionNameType?.map((descriptionNameType) => (
            <option key={descriptionNameType?._id} value={descriptionNameType?.descriptionName}>
              {descriptionNameType?.descriptionName}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </Form.Select> 
    </div>
  );
}
