import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../utils/index";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthHeaders } from "../../../components/Token";
import Zone from "../../../components/ShippingComponents/Zone";
import Region from "../../../components/ShippingComponents/Region";
import Method from "../../../components/ShippingComponents/Method";
import CancelButton from "../../../components/MinorComponents/CancelButton";

function UpdatedShipping() {
  const { _id } = useParams();
  const [data, setData] = useState([]);
  const [zone, setZone] = useState([]);
  const [submitZone, setSubmitZone] = useState([]);
  const [submitRegion, setSubmitRegion] = useState([]);
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [submitMethod, setSubmitMethod] = useState("");
  const [wigth, setWigth] = useState("");
  const [cost, setCost] = useState("");

  const headers = useAuthHeaders();
  const navicate = useNavigate();

  const handleEditShipping = (e) => {
    e.preventDefault();

    axios
      .put(
        `${API_BASE_URL}/admin/shipping/updateshipping/${_id}`,
        {
          zone: submitZone,
          region: submitRegion,
          districtname: district,
          pincode: pinCode,
          methodname: submitMethod,
          weight: wigth,
          cost: cost,
        },
        { headers }
      )
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: "Updated has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        navicate("/editShipping");
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
        });
      });
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/admin/shipping/getsingleshipping/${_id}`, {
        headers,
      })
      .then((res) => {
        setData(res?.data?.Shipping);
        setSubmitZone(res?.data?.Shipping?.zone?.zonename);
        setSubmitRegion(res?.data?.Shipping?.zoneregion?.regionname);
        setDistrict(res?.data?.Shipping?.districtname);
        setPinCode(res?.data?.Shipping?.pincode);
        setSubmitMethod(res?.data?.Shipping?.shippingmethod?.Methodname);
        setWigth(res?.data?.Shipping?.weight);
        setCost(res?.data?.Shipping?.cost);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  return (
    <div className="m-4">
      <h2>Edit Shipping</h2>

      <form
        onSubmit={handleEditShipping}
        className="category position-relative"
        style={{boxShadow: "0 0 10px 0 rgba(0,0,0,0.45) inset"}}
      >
        <div className="row category-field">
          <h6>Zone :</h6>
          <p>{data.zone?.zonename}</p>

          <h6>Zone Region :</h6>
          <p>{data.zoneregion?.regionname}</p>

          <h6>Method :</h6>
          <p>{data.shippingmethod?.Methodname}</p>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              District Name :
            </label>
            <br />

            <input
              type="text"
              required
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="name" className="fs-6">
              Pin Code :
            </label>
            <br />

            <input
              type="number"
              required
              min={0}
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
            />
          </div>

          {submitMethod === "Flat rate" && (
            <>
              <div className="col-md-4">
                <label htmlFor="name" className="fs-6">
                  Weight :
                </label>
                <br />

                <input
                  type="number"
                  required
                  min={0}
                  value={wigth}
                  onChange={(e) => setWigth(e.target.value)}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="name" className="fs-6">
                  Cost :
                </label>
                <br />

                <input
                  type="number"
                  required
                  min={0}
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                />
              </div>
            </>
          )}
        </div>
        <div className="buttons">
          <button type="submit" style={{ backgroundColor: "#0443C0", color: "white" }}>Save</button>
          <CancelButton path="/editShipping" />
        </div>
      </form>
    </div>
  );
}

export default UpdatedShipping;
